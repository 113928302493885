import React from "react";
import Pricing from "../components/pricing/Pricing";
import { Helmet } from "react-helmet";


export default function MerchantMembership() {

  return (
    <>
      <Helmet>
        <title>The Merchant Membership - Forex Course Plans & Pricing</title>
        <link rel="canonical" href="https://eltaajir.com/membership" />
        <meta property="og:title" content="The Merchant Membership - Forex Course Plans & Pricing" />        
      </Helmet>
      <div className="pricing_container">
        <Pricing />
      </div>
    </>
  );
}
