import Fetcher from "../library/Fetcher";

export const getAwsVideos = async (data) => {
  return Fetcher.get("/getAwsVideos", data);
};

export const getAwsVideosTab = async (data) => {
  return Fetcher.get(
    `/getAwsVideos?page=${data.page}&order=${data.order}&selected_tab=${
      data.selected_tab
    }&is_free=${data.is_free}${
      data.package_id ? `&package_id=${data.package_id}` : ""
    }&record_per_page=20`
  );
};

export const getAwsVideosByIdApi = async (id) => {
  return Fetcher.get(`/getAwsVideosById/${id}`);
};

export const isVideoCompleted = async (data) => {
  return Fetcher.post("/addCourseCompletedLevel", data);
};

export const getCourseCompletedLevelApi = async (data) => {
  return Fetcher.get("/getCourseCompletedLevel", data);
};

export const setUserTrackApi = async (data) => {
  return Fetcher.post("/setUserTrack", data);
};

export default {
  getAwsVideos,
  getAwsVideosByIdApi,
  isVideoCompleted,
  getCourseCompletedLevelApi,
  setUserTrackApi,
  getAwsVideosTab,
};
