import { toast } from "react-toastify";

const profile_field = [
  "first_name",
  "last_name",
  "email",
  "country",
  "contact",
  "forex_rating",
  "crypto_rating",
];

export const language_defaults = {
  en: "English",
  ar: "Arabic",
};

export const profileCheck = (user) => {
  for (let i = 0; i < profile_field.length; i++) {
    if (
      !user[profile_field[i]] ||
      user[profile_field[i]] == null ||
      user[profile_field[i]] == ""
    ) {
      return false;
    }
  }
  return true;
};

export const successAlert = (message) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  });
};
