import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import ListGroup from "react-bootstrap/ListGroup";
import { Col, Container, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useMutation } from "@tanstack/react-query";
import { tradingResultApi } from "../../api/tradingResultApi";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Finance from "../../assets/svg/Finance";
import Toolbox from "../../assets/svg/Toolbox";
import LiveMeet from "../../assets/svg/LiveMeet";

const options = {
  layout: {
    padding: {
      top: 20,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
    },
    title: {
      display: false,
    },
    datalabels: {
      display: true,
      color: "#00b7e1",
      anchor: "end",
      align: "end",
      offset: 1,
      formatter: function (value) {
        if (value) {
          return `${value} %`;
        } else {
          return "";
        }
      },
    },
  },
};

export default function ExploreCourse() {
  const navigate = useNavigate();
  const [chartData, setChartData] = useState(null);
  const [fullChartData, setFullChartData] = useState([]);
  const [chartView, setChartView] = useState("weekly");

  const myNavigation = (e, path) => {
    e.preventDefault();
    navigate(path);
  };

  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Legend);

  useEffect(() => {
    tradingResult.mutate();
  }, []);

  const tradingResult = useMutation(tradingResultApi, {
    onSuccess: (data) => {
      if (data?.data) {
        const charData = data.data;
        setFullChartData(charData);
        createData(chartView, charData);
      }
    },
    onError: (error) => {
      if (error?.response?.data?.errors) {
        return;
      }
    },
  });

  const createData = (type, data) => {
    if (!data) {
      data = fullChartData;
    }

    var dataCh = data[type];
    setChartView(type);

    const amount = [];
    const labels = [];
    for (let i = 0; i < dataCh.length; i++) {
      const indDataKey = Object.keys(dataCh[i]);
      for (let x = 0; x < indDataKey.length; x++) {
        if (indDataKey[x] === "amount") {
          amount.push(dataCh[i].amount ? parseFloat(dataCh[i].amount) : 0);
        } else {
          labels.push(dataCh[i][indDataKey[x]]);
        }
      }
    }

    const dataset = {
      labels,
      datasets: [
        {
          label: `${type} (percentage)`,
          data: amount,
          backgroundColor: "#293965",
        },
      ],
    };

    setChartData(dataset);
  };

  return (
    <>
      {chartData && (
        <section className="explore-course">
          <Container>
            <div className="explore-course-wrap">
              <h3>One website with the courses and tools you need</h3>
              <Row>
                <Col className="col-12">
                  <div className="explore-course-left">
                    <p>
                      All you need as a trader whether you just starting in the
                      market, or you already have experience
                    </p>
                    <ListGroup as="ul">
                      <ListGroup.Item as="li">
                        <div className="card-box">
                          <div className="icon">
                            <div className="icon-box">
                              <Finance />
                            </div>
                          </div>
                          <div className="explore-ctn">
                            <p>
                              All videos you need to allow you to understand
                              what the forex market is and how to become a
                              trader
                            </p>
                          </div>
                        </div>
                      </ListGroup.Item>
                      <ListGroup.Item as="li">
                        <div className="card-box">
                          <div className="icon">
                            <div className="icon-box">
                              <Toolbox />
                            </div>
                          </div>
                          <div className="explore-ctn">
                            <p>
                              Trading Toolbox: Risk/Position size calculator,
                              Tradingview, Brokers, economic calendar and other
                              tools.
                            </p>
                          </div>
                        </div>
                      </ListGroup.Item>
                      <ListGroup.Item as="li">
                        <div className="card-box">
                          <div className="icon">
                            <div className="icon-box">
                              <LiveMeet />
                            </div>
                          </div>
                          <div className="explore-ctn">
                            <p>
                              Live sessions where we meet to analyze the market,
                              answer your questions, and take trades together if
                              they appear.
                            </p>
                          </div>
                        </div>
                      </ListGroup.Item>
                    </ListGroup>
                    <Link
                      className="btn text-center explore-course-btn"
                      onClick={(e) => myNavigation(e, "/product-service")}
                    >
                      Explore our courses
                    </Link>
                  </div>
                </Col>
                <Col className="col-12">
                  <div className="trading-result-wrap">
                    <h3>Trading Result</h3>

                    <div className="trading-result-chart">
                      <div className="my-chart">
                        <Bar
                          options={options}
                          data={chartData}
                          plugins={[ChartDataLabels]}
                        />
                        <p className="future-perf">
                          Past performance does not guarantee future results.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="trading-btn-sec d-flex">
                    <Button
                      className={`btn trading-btn ${
                        chartView === "weekly" ? "active" : ""
                      }`}
                      onClick={() => createData("weekly")}
                    >
                      Weekly
                    </Button>
                    <Button
                      className={`btn trading-btn ${
                        chartView === "monthly" ? "active" : ""
                      }`}
                      onClick={() => createData("monthly")}
                    >
                      Monthly
                    </Button>
                    <Button
                      className={`btn trading-btn ${
                        chartView === "quarterly" ? "active" : ""
                      }`}
                      onClick={() => createData("quarterly")}
                    >
                      Quarterly
                    </Button>
                    <Button
                      className={`btn trading-btn me-0 ${
                        chartView === "yearly" ? "active" : ""
                      }`}
                      onClick={() => createData("yearly")}
                    >
                      Yearly
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </section>
      )}
    </>
  );
}
