import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../components/loader/loader";

const AuthGuard = ({ component }) => {
  const navigate = useNavigate();
  const havetoken = sessionStorage.getItem("tm-token");

  useEffect(() => {
    if (!havetoken) {
      navigate("/login");
    }
  }, []);

  return <React.Fragment>{havetoken ? component : <Loader />}</React.Fragment>;
};

export default AuthGuard;
