import React, { useEffect, useRef, useState } from "react";
import { Button, CloseButton, Modal, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useMutation } from "@tanstack/react-query";
import {
  getAwsVideosByIdApi,
  getCourseCompletedLevelApi,
  isVideoCompleted,
  setUserTrackApi,
} from "../api/videoDetailsApi";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CheckGreen } from "../assets/svg/CheckGreen";
import BackBtn from "../assets/svg/BackBtn";
import { courseDetailApi } from "../api/coursesApi";
import Loader from "../components/loader/loader";
import NextVidIco from "../assets/svg/NextVidIco";
import PrevVidIcon from "../assets/svg/PrevVidIcon";
import { useSelector } from "react-redux";
import { forexcourseEligibility } from "../config/constants";

function VideoList() {
  const moment = require("moment");
  const navigate = useNavigate();
  const videoRef = useRef();
  const isWatchRadio = useRef(null);
  const isNotWatchRadio = useRef(null);

  const [searchParams] = useSearchParams();
  const [completedList, setCompletedList] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [courseVideoList, setCourseVideoList] = useState([]);
  const [currentCourse, setCurrentCourse] = useState([]);
  const [displayVideoList, setDisplayVideoList] = useState([]);
  const [videoCurrent, setVideoCurrent] = useState([]);
  const [currentLevel, setCurrentLevel] = useState(1);
  const [isWatched, setIsWatched] = useState(false);
  const [levelConfig, setLevelConfig] = useState({
    previous: false,
    next: false,
  });
  const [NextVid, setNextVid] = useState({});
  const [loading, setLoading] = useState(false);
  const [openComVid, setOpenComVid] = useState(false);
  const redirect = searchParams.get("tp");
  const { plans } = useSelector((state) => state.authUser);
  const token = sessionStorage.getItem("tm-token");

  useEffect(() => {
    const direct = searchParams.get("d");
    const directCourse = searchParams.get("c");

    if (!token) {
      navigate("/login");
    }
    if (direct) {
      getAwsVideo.mutate(direct);
    } else if (directCourse) {
      courseDetailMutation.mutate(directCourse);
    }
  }, []);

  useEffect(() => {
    if (currentCourse.course_is_sequential && courseVideoList.length > 0) {
      const details = { ...levelConfig };
      details["next"] = checknextLevel();
      if (currentLevel == 0) {
        details["previous"] = false;
      } else {
        details["previous"] = true;
      }
      setLevelConfig(details);
    }
  }, [currentLevel, courseVideoList, currentCourse]);

  const checknextLevel = () => {
    let crvideoList = courseVideoList.filter(
      (vdlt) => vdlt.level_no === currentLevel + 1
    );
    if (crvideoList.length > 0) {
      return true;
    }
    return false;
  };

  const nextLevelHandler = () => {
    let crvideoList = courseVideoList
      .filter((vdlt) => vdlt.level_no === currentLevel + 1)
      .sort((a, b) => a.sort_order_level_no - b.sort_order_level_no);
    setDisplayVideoList(crvideoList);
    setCurrentLevel(currentLevel + 1);
  };

  const previousLevelHandler = () => {
    let crvideoList = courseVideoList
      .filter((vdlt) => vdlt.level_no === currentLevel - 1)
      .sort((a, b) => a.sort_order_level_no - b.sort_order_level_no);
    setDisplayVideoList(crvideoList);
    setCurrentLevel(currentLevel - 1);
  };

  const getAwsVideo = useMutation(getAwsVideosByIdApi, {
    onSuccess: (data) => {
      if (data.data.status) {
        const currentVid = data?.data?.data[0];

        setVideoCurrent({
          title: currentVid.title,
          video_url: currentVid.video_url,
          id: currentVid.id,
          language: currentVid.language,
          isCompleted: false,
          level_no: currentVid.level_no,
        });
      } else {
        navigate("/");
      }
      return;
    },
    onError: (error) => {
      console.log(error?.response.data.errors);
      return;
    },
  });

  useEffect(() => {
    videoRef.current?.load();
    setIsPlaying(false);
    if (isWatchRadio.current && isNotWatchRadio.current && videoCurrent) {
      if (videoCurrent.isCompleted) {
        isWatchRadio.current.checked = true;
      } else {
        isNotWatchRadio.current.checked = true;
      }
    }
  }, [videoCurrent?.video_url]);

  const courseDetailMutation = useMutation(courseDetailApi, {
    onSuccess: async (data) => {
      setLoading(false);
      let eligibility = false;
      if (data?.data?.data?.course_free) {
        eligibility = true;
      } else {
        for (let plan in plans) {
          if (forexcourseEligibility.includes(plans[plan].package_id)) {
            eligibility = true;
            break;
          }
        }
      }

      if (eligibility) {
        let { course_video, ...coursedata } = data?.data?.data;
        if (coursedata.course_is_sequential == 1) {
          handleSequencialCourse(course_video, coursedata);
        } else {
          handleNonSequencialCourse(course_video, coursedata);
        }
      } else {
        navigate("/");
      }
      return;
    },
    onError: (error) => {
      setLoading(false);
      if (error?.response?.data?.errors) {
        return;
      }
    },
  });

  const handleSequencialCourse = async (course_video, coursedata) => {
    const completVid = await getCourseCompletedLevel();
    let displayvideoList = course_video
      .filter((vdlt) => vdlt.level_no === currentLevel)
      .sort((a, b) => a.sort_order_level_no - b.sort_order_level_no);
    if (displayvideoList.length) {
      const vidDetails = {
        title: displayvideoList[0].title,
        video_url: displayvideoList[0].video_url,
        id: displayvideoList[0].lecture_id,
        language: coursedata.language,
        isCompleted: completVid.includes(
          Number(displayvideoList[0].lecture_id)
        ),
        level_no: displayvideoList[0].level_no,
        sort_order_level_no: displayvideoList[0].sort_order_level_no,
      };
      setVideoCurrent(vidDetails);
      getNextVideo(
        vidDetails,
        course_video,
        displayvideoList,
        coursedata,
        completVid
      );
      setIsWatched(completVid.includes(Number(displayvideoList[0].lecture_id)));
      setDisplayVideoList(displayvideoList);
    }

    setCompletedList(completVid);
    setCurrentCourse(coursedata);
    setCourseVideoList(course_video);
  };

  const handleNonSequencialCourse = async (course_video, coursedata) => {
    if (course_video.length) {
      const vidDetails = {
        title: course_video[0].title,
        video_url: course_video[0].video_url,
        id: course_video[0].lecture_id,
        language: coursedata.language,
        isCompleted: false,
        level_no: course_video[0].level_no,
        sort_order_level_no: course_video[0].sort_order_level_no,
      };
      setVideoCurrent(vidDetails);
      setDisplayVideoList(course_video);
    }
    setCurrentCourse(coursedata);
  };

  const isVideoCompletedMutation = useMutation(isVideoCompleted, {
    onSuccess: async (data) => {
      if (data) {
        setCompletedList(await getCourseCompletedLevel());
      }
      return;
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const setUserTrackApiMutation = useMutation(setUserTrackApi, {
    onSuccess: async (data) => {
      return;
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const nextButtonHandler = () => {
    setVideoCurrent(NextVid);
    setIsWatched(NextVid.isCompleted);
    if (NextVid.level_no !== videoCurrent.level_no) {
      let crvideoList = courseVideoList
        .filter((vdlt) => vdlt.level_no === NextVid?.level_no)
        .sort((a, b) => a.sort_order_level_no - b.sort_order_level_no);
      setDisplayVideoList(crvideoList);
      setCurrentLevel(NextVid?.level_no);
    }
    getNextVideo(
      NextVid,
      courseVideoList,
      displayVideoList,
      currentCourse,
      completedList
    );
  };

  const changeVideoStatus = async () => {
    let videoDetails = {
      lecture_id: videoCurrent?.id,
      level_no: videoCurrent?.level_no,
      course_id: currentCourse.id,
      is_status: videoCurrent.isCompleted ? 1 : 0,
    };
    await isVideoCompletedMutation.mutate(videoDetails);
    if (videoCurrent.isCompleted == 1) {
      setOpenComVid(true);
    }
    setIsWatched(videoCurrent.isCompleted);
  };

  const getNextVideo = (vcn, cplist, disList, cdata, coplist) => {
    let nextVid = null;
    const currentPlayIndex = disList.findIndex(
      (vid) => vid.lecture_id === vcn.id
    );
    if (currentPlayIndex < disList.length - 1) {
      nextVid = {
        title: disList[currentPlayIndex + 1]?.title,
        video_url: disList[currentPlayIndex + 1]?.video_url,
        id: disList[currentPlayIndex + 1]?.lecture_id,
        language: cdata?.language,
        isCompleted: coplist.includes(
          Number(disList[currentPlayIndex + 1]?.lecture_id)
        ),
        level_no: disList[currentPlayIndex + 1]?.level_no,
        sort_order_level_no: disList[currentPlayIndex + 1]?.sort_order_level_no,
      };
    } else {
      let crvideoList = cplist
        .filter((vdlt) => vdlt.level_no === vcn.level_no + 1)
        .sort((a, b) => a.sort_order_level_no - b.sort_order_level_no);
      if (crvideoList.length > 0) {
        nextVid = {
          title: crvideoList[0]?.title,
          video_url: crvideoList[0]?.video_url,
          id: crvideoList[0]?.lecture_id,
          language: cdata?.language,
          isCompleted: coplist.includes(Number(crvideoList[0]?.lecture_id)),
          level_no: crvideoList[0]?.level_no,
          sort_order_level_no: crvideoList[0]?.sort_order_level_no,
        };
      }
    }
    setNextVid(nextVid);
  };

  function playListVideoHandler(e, videoId) {
    e.stopPropagation();
    const playListedVideo = displayVideoList?.filter(
      (lesson) => lesson.lecture_id === videoId
    )[0];
    if (playListedVideo) {
      const details = {
        title: playListedVideo.title,
        video_url: playListedVideo.video_url,
        id: playListedVideo.lecture_id,
        language: currentCourse.language,
        isCompleted: completedList.includes(playListedVideo.lecture_id),
        level_no: playListedVideo.level_no,
        sort_order_level_no: playListedVideo?.sort_order_level_no,
      };
      setVideoCurrent(details);
      if (currentCourse.course_is_sequential === 1) {
        setIsWatched(completedList.includes(playListedVideo.lecture_id));
        getNextVideo(
          details,
          courseVideoList,
          displayVideoList,
          currentCourse,
          completedList
        );
      }
    }
  }

  const getCourseCompletedLevel = async () => {
    return await getCourseCompletedLevelApi().then((data) => {
      if (data) {
        return data.data.data.map((cplv) => cplv.lecture_id);
      }
      return;
    });
  };

  const handleVideoWatched = (check) => {
    setVideoCurrent({
      ...videoCurrent,
      isCompleted: check ? true : false,
    });
  };

  const handleVideoPlay = () => {
    let count = 0;
    if (isPlaying) return;

    setIsPlaying(true);
    const myInterval = setInterval(() => {
      if (videoRef.current) {
        const currentTime = videoRef.current.currentTime;
        if (currentTime > 10 && count >= 10) {
          const direct = searchParams.get("d");
          const directCourse = searchParams.get("c");
          if (direct) {
            let videoDetails = {
              aws_video_id: videoCurrent?.id,
              aws_video_level_no: videoCurrent?.level_no,
            };
            setUserTrackApiMutation.mutate(videoDetails);
          } else if (directCourse) {
            let videoDetails = {
              course_id: currentCourse.id,
              course_lecture_id: videoCurrent.id,
              course_level_no: videoCurrent.level_no,
            };
            setUserTrackApiMutation.mutate(videoDetails);
          }
          clearInterval(myInterval);
        }
      }
      count++;
    }, 1000);
  };

  return (
    <>
      {loading && <Loader />}
      <Helmet>
        <title>The Merchant - Video Guide</title>
        <link rel="canonical" href="https://eltaajir.com/watch" />
        <meta property="og:title" content="The Merchant - Video Guide" />
      </Helmet>
      <section className="vid-list-sec">
        {
          <div className="container-fluid">
            <div className="btn-wrapper">
              <button
                className="back-tab"
                onClick={() =>
                  navigate("/myplan", { state: { active: redirect } })
                }
              >
                <BackBtn /> Back
              </button>
            </div>
            <Row>
              <div className="vid-sec-wrapper d-flex">
                <div className="vid-sec-left">
                  <div className="vid-guid-left">
                    <div className="ratio ratio-16x9">
                      <video
                        ref={videoRef}
                        width="100%"
                        key={videoCurrent?.video_url}
                        controls
                        autoPlay
                        controlsList="nodownload"
                        onPlay={handleVideoPlay}
                      >
                        <source
                          src={videoCurrent?.video_url}
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                    <div className="main-video-details">
                      <div className="main-video-details-left">
                        <h3 className="video-title">{videoCurrent?.title}</h3>

                        <span className="video-language">
                          (
                          {videoCurrent && videoCurrent?.language === "en"
                            ? "English"
                            : "Arabic"}
                          )
                        </span>
                      </div>
                    </div>
                    {currentCourse.course_is_sequential === 1 && (
                      <div className="video-is-completed">
                        <h4>video complete ?</h4>
                        <form className="d-flex">
                          <div className="ans-box">
                            <div className="radio-box">
                              <input
                                ref={isWatchRadio}
                                type="radio"
                                name="video"
                                id="completed"
                                defaultChecked={videoCurrent.isCompleted}
                                onClick={() => handleVideoWatched(1)}
                              />
                              <label htmlFor="completed">Yes</label>
                            </div>
                            <div className="radio-box">
                              <input
                                ref={isNotWatchRadio}
                                type="radio"
                                name="video"
                                id="not-complete"
                                defaultChecked={!videoCurrent.isCompleted}
                                onClick={() => handleVideoWatched(0)}
                              />
                              <label htmlFor="not-complete">No</label>
                            </div>
                          </div>

                          <div className="next-vid-sec">
                            {videoCurrent.isCompleted !== isWatched && (
                              <Button onClick={() => changeVideoStatus()}>
                                Submit
                              </Button>
                            )}
                            {NextVid !== null && (
                              <div className="next-vid-sec">
                                <Button onClick={() => nextButtonHandler()}>
                                  Next Video
                                </Button>
                              </div>
                            )}
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </div>

                {displayVideoList.length > 0 && (
                  <div className="vid-sec-right">
                    <div className="video-right-list-title">
                      {currentCourse.course_is_sequential === 1 ? (
                        <div className="d-flex video-nav-sec">
                          {levelConfig.previous === true ? (
                            <button
                              onClick={previousLevelHandler}
                              className="prev-btn"
                            >
                              <PrevVidIcon />
                            </button>
                          ) : (
                            <span></span>
                          )}
                          <h4>Level {currentLevel}</h4>{" "}
                          {levelConfig.next === true ? (
                            <button
                              onClick={nextLevelHandler}
                              className="next-btn"
                            >
                              <NextVidIco />
                            </button>
                          ) : (
                            <span></span>
                          )}
                        </div>
                      ) : (
                        <h4>{currentCourse.course_title}</h4>
                      )}
                    </div>
                    <div className="video-right-list">
                      {displayVideoList.map((list, index) => {
                        return (
                          <div
                            className="list-item d-flex"
                            key={index}
                            onClick={(e) =>
                              playListVideoHandler(e, list.lecture_id)
                            }
                          >
                            <div className="list-thumbnail">
                              <div
                                className={`thumbnail-details${
                                  completedList.includes(
                                    Number(list.lecture_id)
                                  )
                                    ? " completed"
                                    : ""
                                }`}
                              >
                                {completedList.includes(
                                  Number(list.lecture_id)
                                ) && (
                                  <span className="completed-icon">
                                    <CheckGreen />
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="list-ctn">
                              <h3>{list.title}</h3>
                              <span>
                                Updated on:{" "}
                                {moment(list.updated_at).format("DD/MM/YYYY")}
                              </span>
                              <span>
                                Language:
                                {list.language === "en"
                                  ? " English"
                                  : " Arabic"}
                              </span>
                              <span>{}</span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </Row>
            <Modal
              show={openComVid}
              centered
              className="thank-you-modal completed-modal"
            >
              <Modal.Body>
                <span className="completed-icon">
                  <CheckGreen />
                </span>
                <div className="ty-body">
                  <p>Completed !</p>
                </div>
              </Modal.Body>
              <CloseButton
                className="close-btn"
                onClick={() => setOpenComVid(false)}
              ></CloseButton>
            </Modal>
          </div>
        }
      </section>
    </>
  );
}

export default VideoList;
