import Fetcher from "../library/Fetcher";
import axios from "axios";

export const register = async (data) => {
  return Fetcher.post("/register", data);
};

export const login = async (data) => {
  return Fetcher.post("/login", data);
};

export const logout = async (data) => {
  return Fetcher.post("/logout", data);
};

export const sendOtp = async (data) => {
  return Fetcher.post("/verifyOtp", data);
};

export const resendOtpApi = async (data) => {
  return Fetcher.post("/resendOtp", data);
};

export const forgotPasswordApi = async (data) => {
  return Fetcher.post("/forgetPassword", data);
};

export const resetPasswordApi = async (data) => {
  return Fetcher.post("/resetPassword", data);
};

export const telegramEmail = async (data) => {
  return Fetcher.post("/telegram-email", data);
};

export const socialLogin = async (data) => {
  return Fetcher.get(`/login/${data}`);
};

export const socialSignUp = async (data) => {
  return Fetcher.get(`/login/${data}`);
};

export const promoApi = async (data) => {
  return Fetcher.post(`/promo/code`, data);
};
export const checkoutApi = async (packageId) => {
  return Fetcher.post(`/checkout/${packageId}`);
};

export const supportForm = async (data) => {
  return Fetcher.post(`/support/email`, data);
};

export const telegramAccess = async (data) => {
  return Fetcher.post(`/telegram-channel-access/${data}`);
};
export const addPaymentDetails = async (data) => {
  return Fetcher.post(`/getCheckoutPaymentGateway`, data);
};
export const cityApi = async (data) => {
  return axios.get(`https://api.brightdata.com/cities?country=${data}`, {
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
  });
};

export default {
  login,
  register,
  logout,
  resendOtpApi,
  sendOtp,
  forgotPasswordApi,
  resetPasswordApi,
  telegramEmail,
  socialLogin,
  socialSignUp,
  promoApi,
  supportForm,
  telegramAccess,
  addPaymentDetails,
};
