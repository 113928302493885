import React, { useEffect, useState } from "react";
import { Col, Image, Row, Accordion, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { getAllEbookApi } from "../../api/eBookApi";
import ReactPaginate from "react-paginate";
import ChevronRight from "../../assets/svg/ChevronRight";
import ChevronLeft from "../../assets/svg/ChevronLeft";
import Loader from "../../components/loader/loader";
import { Helmet } from "react-helmet";

export default function EBook({ gridView, gridOption, activeTab }) {
  const navigate = useNavigate();
  const [display, setDisplay] = useState([]);
  const [loading, setLoading] = useState(false);
  const [eBookData, setEBookData] = useState({
    page: 1,
    order: "desc",
    is_free: 0,
  });

  useEffect(() => {
    setLoading(true);
    getAwsEbookApi.mutate(eBookData);
  }, [eBookData]);

  const getAwsEbookApi = useMutation(getAllEbookApi, {
    onSuccess: (data) => {
      setLoading(false);
      if (data?.data) {
        setDisplay(data?.data?.data);
      }
      return;
    },
    onError: (error) => {
      setLoading(false);
    },
  });

  const handleDetailButton = async (e, value) => {
    e.preventDefault();
    const token = sessionStorage.getItem("tm-token");

    if (!token) {
      navigate("/signup");
    } else {
      navigate(`/read?d=${value.id}&tp=fft`);
    }
  };

  const handleFilterOption = () => {
    setEBookData({
      ...eBookData,
      is_free: eBookData.is_free === 0 ? 1 : 0,
      page: 1,
    });
  };

  const handlePageClick = (event) => {
    setEBookData({
      ...eBookData,
      page: event.selected + 1,
    });
  };

  const handleOrderChange = (e) => {
    setEBookData({
      ...eBookData,
      order: e.target.value,
    });
  };

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Hesham Elsaid - EBook" />
        <link rel="canonical" href="https://eltaajir.com/about-me" />
      </Helmet>
      {loading && <Loader />}
      <Row>
        <Col sm={12} lg={4} xl={3}>
          <div className="myplan-wrap-left">
            <div className="option-wrap filter-wrapper">
              <Accordion defaultActiveKey="0" className="whiteCommen-box">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Filter option</Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>
                        <div className="field-wrap">
                          <input
                            type="checkbox"
                            id="free_lession3"
                            checked={eBookData.is_free === 1}
                            onChange={handleFilterOption}
                          />
                          <label htmlFor="free_lession3">Free eBook</label>
                        </div>
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </Col>
        <Col sm={12} lg={8} xl={9}>
          <div className="our-course">
            {display?.data?.length > 0 && (
              <div className="blog-sort-wrap">
                <Form.Select
                  aria-label="label"
                  defaultValue={eBookData.order}
                  onChange={(e) => handleOrderChange(e)}
                >
                  <option value="desc">New to old</option>
                  <option value="asc">Old to new</option>
                </Form.Select>
              </div>
            )}
            <div className="our-course-wrap">
              <div className="our-course-ctn">
                <Row>
                  {display?.data?.length ? (
                    display?.data.map((pc, index) => (
                      <Col
                        key={index}
                        sm={gridView ? "6" : 12}
                        md={gridView ? "6" : 12}
                        lg={gridView ? "6" : 12}
                        xl={gridView ? "4" : 12}
                        className="our-course-col"
                      >
                        <div className="our-course-card ebook-card d-flex">
                          <div className="course-card-img">
                            <Link
                              onClick={(e) => handleDetailButton(e, pc)}
                              className="course-img-link"
                            >
                              <Image
                                src={`https://eltaajir.com/admin//storage/app/public/${pc.e_image}`}
                                className="w-100"
                              />
                            </Link>
                          </div>
                          <div className="course-card-ctn d-flex flex-column">
                            <div className="course-card-body">
                              <h3>{pc.e_title}</h3>
                              <div
                                className="ebook-disc"
                                dangerouslySetInnerHTML={{
                                  __html: pc.e_description,
                                }}
                              ></div>
                            </div>

                            <div className="course-card-footer">
                              <Link
                                onClick={(e) => handleDetailButton(e, pc)}
                                className="course-details"
                              >
                                Read Now
                              </Link>
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))
                  ) : (
                    <Col>
                      <div className="no-ctn-found whiteCommen-box">
                        No Ebook Found
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
              {display?.last_page > 1 && (
                <div>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={<ChevronRight />}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={display?.per_page}
                    pageCount={display?.last_page}
                    previousLabel={<ChevronLeft />}
                    renderOnZeroPageCount={null}
                    forcePage={eBookData.page - 1}
                    containerClassName="pagination justify-content-center"
                    pageLinkClassName="page-link"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    activeLinkClassName="active"
                    pageClassName="page-item"
                    nextClassName="next page-item"
                    previousClassName="previous page-item"
                    breakClassName="break page-item"
                    breakLinkClassName="page-link"
                  />
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
