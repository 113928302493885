import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { blogDetail } from "../../api/blogApi";
import { LinkedInIconShare } from "../../assets/svg/LinkedInIconShare";
import { FacebookIconShare } from "../../assets/svg/FacebookIconShare";
import { TwitterIconShare } from "../../assets/svg/TwitterIconShare";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import { Container } from "react-bootstrap";
import { successAlert } from "../../lib/utils";
import Loader from "../../components/loader/loader";
import BlogCode from "./BlogCode";

function InnerBlogPage() {
  const [blog, setBlog] = useState(null);
  const location = useLocation();
  const urlParam = new URLSearchParams(location.search);
  const currUrl = `https://blog.eltaajir.com/blog?bid=${urlParam.get("bid")}`;

  const [loading, setLoading] = useState(true);
  let { id } = useParams();

  const blogDetailMutation = useMutation(blogDetail, {
    onSuccess: (data) => {
      if (data?.data) {
        setBlog(data?.data?.data[0]);
        successAlert(data?.data.message);
      }
      setLoading(false);
    },
  });

  useEffect(() => {
    blogDetailMutation.mutate(urlParam.get("bid"));
  }, []);

  return (
    <>
      <section className="single-blog-page">
        {loading && <Loader /> }
        <Container>
          <div className="single-blog-wrap">
            {blog && (
              <>
                <BlogCode blog={blog} />
                <div className="share-blog">
                  <h3>Share this blog</h3>
                  <div className="share-btn-wrap">
                    <LinkedinShareButton url={currUrl}>
                      <LinkedInIconShare />
                    </LinkedinShareButton>
                    <FacebookShareButton url={currUrl}>
                      <FacebookIconShare />
                    </FacebookShareButton>
                    <TwitterShareButton url={currUrl}>
                      <TwitterIconShare />
                    </TwitterShareButton>
                  </div>
                </div>
              </>
            )}
          </div>
        </Container>
      </section>
    </>
  );
}

export default InnerBlogPage;
