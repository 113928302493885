import React from "react";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import { Link, useNavigate } from "react-router-dom";

function AboutMerchant() {
  const navigate = useNavigate();

  const myNavigation = (e, path) => {
    e.preventDefault();
    navigate(path);
  };

  return (
    <section className="about-merchant">
      <Container>
        <div className="about-merchant-wrap">
          <div className="about-merchant-title">
            <h3>Why choose us?</h3>
          </div>
          <Row className="align-items-center about-merchant-ctn">
            <Col md={6}>
              <div className="about-merchant-left">
                <Image
                  src={require("../../assets/images/about-merchant-left.png")}
                  className="w-100"
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="about-merchant-right">
                <h2>
                  Join a Community of Conscious Traders: Reimagine Your Approach
                  to Forex
                </h2>
                <p>
                  Join our community of conscious traders who know what they're
                  doing in the market and why. We provide educational lessons
                  that go deeper than the rest, with a big focus on trader
                  mindset and risk management. Our customised trading plans are
                  tailored to your lifestyle, and our weekly meetings ensure
                  you're always on track. Don't settle for average. Choose us
                  and become a smarter, more successful trader.
                </p>
                <Link
                  onClick={(e) => myNavigation(e, "/courses")}
                  className="btn btn-primary"
                >
                  Learn More
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
}
export default AboutMerchant;
