import React, { useEffect, useState } from "react";
import { Col, Image, Row, Accordion, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { language_defaults } from "../../lib/utils";
import Default_video_image from "../../assets/images/default_video_image.png";
import { marketAnalysisEligibility } from "../../config/constants";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import ChevronLeft from "../../assets/svg/ChevronLeft";
import ChevronRight from "../../assets/svg/ChevronRight";
import { useMutation } from "@tanstack/react-query";
import { getAwsVideosTab } from "../../api/videoDetailsApi";
import Loader from "../../components/loader/loader";
import { Helmet } from "react-helmet";

export default function MarketAnalysis({ gridView, gridOption, activeTab }) {
  const navigate = useNavigate();
  const planPricing = useSelector((state) => state.merchant.planPricing) || [];
  const [display, setDisplay] = useState([]);
  const [loading, setLoading] = useState(false);
  const [marketData, setMarketData] = useState({
    page: 1,
    order: "desc",
    selected_tab: "marketanalysis",
    is_free: 0,
    package_id: [],
  });

  useEffect(() => {
    if (activeTab === "thirdTab") {
      if (display.length === 0) {
        gridOption(false);
      } else {
        gridOption(true);
      }
    }
  }, [display]);

  useEffect(() => {
    setLoading(true);
    getAwsVideosCryptoList.mutate(marketData);
  }, [marketData]);

  const handleDetailButton = (e, id) => {
    e.preventDefault();
    const token = sessionStorage.getItem("tm-token");

    if (!token) {
      navigate("/signup");
    } else {
      navigate(`/watch?d=${id}&tp=tht`);
    }
  };

  const handleFilterOption = (type, value) => {
    const details = {};
    if (type === "price") {
      const new_price = [...marketData.package_id];
      const arrayIndex = new_price.indexOf(value);

      if (arrayIndex >= 0) {
        new_price.splice(arrayIndex, 1);
      } else {
        new_price.push(value);
      }

      details["package_id"] = new_price;
    } else if (type === "free") {
      details["is_free"] = value === 0 ? 1 : 0;
    }
    details["page"] = 1;
    setMarketData({
      ...marketData,
      ...details,
    });
  };

  const handlePageClick = (event) => {
    setMarketData({
      ...marketData,
      page: event.selected + 1,
    });
  };

  const handleOrderChange = (e) => {
    setMarketData({
      ...marketData,
      order: e.target.value,
    });
  };

  const getAwsVideosCryptoList = useMutation(getAwsVideosTab, {
    onSuccess: (data) => {
      setLoading(false);
      if (data?.data) {
        setDisplay(data?.data?.data);
      }
      return;
    },
    onError: (error) => {
      setLoading(false);
    },
  });

  const handleImgLoadingError = (e) => {
    e.target.src = Default_video_image;
  };

  return (
    <>
      <Helmet>
        <title>Hesham Elsaid - Market Analysis</title>
        <meta property="og:title" content="Hesham Elsaid - Market Analysis" />
        <link rel="canonical" href="https://eltaajir.com/about-me" />
      </Helmet>
      {loading && <Loader />}
      <Row>
        <Col sm={12} lg={4} xl={3}>
          <div className="myplan-wrap-left">
            <div className="option-wrap filter-wrapper">
              <Accordion defaultActiveKey="0" className="whiteCommen-box">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Filter option</Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>
                        <div className="field-wrap">
                          <input
                            type="checkbox"
                            id="free_lession1"
                            checked={marketData.is_free === 1}
                            onChange={() =>
                              handleFilterOption("free", marketData.is_free)
                            }
                          />
                          <label htmlFor="free_lession1">Free Lesson</label>
                        </div>
                      </li>
                      {planPricing.length > 0 &&
                        planPricing.map((dp, index) => {
                          return (
                            marketAnalysisEligibility.includes(
                              Number(dp.id)
                            ) && (
                              <li key={index}>
                                <div className="field-wrap">
                                  <input
                                    type="checkbox"
                                    id={dp?.package_name + "_m"}
                                    checked={marketData.package_id.includes(
                                      dp.id
                                    )}
                                    onChange={() =>
                                      handleFilterOption("price", dp.id)
                                    }
                                  />
                                  <label htmlFor={dp?.package_name + "_m"}>
                                    {dp.package_name}
                                  </label>
                                </div>
                              </li>
                            )
                          );
                        })}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </Col>
        <Col sm={12} lg={8} xl={9}>
          <div className="our-course">
            {display?.data?.length > 0 && (
              <div className="blog-sort-wrap">
                <Form.Select
                  aria-label="label"
                  defaultValue={marketData.order}
                  onChange={(e) => handleOrderChange(e)}
                >
                  <option value="desc">New to old</option>
                  <option value="asc">Old to new</option>
                </Form.Select>
              </div>
            )}
            <div className="our-course-wrap">
              <div className="our-course-ctn">
                <Row>
                  {display?.data?.length > 0 ? (
                    display?.data.map((pc, index) => (
                      <Col
                        key={index}
                        sm={gridView ? "6" : 12}
                        md={gridView ? "6" : 12}
                        lg={gridView ? "6" : 12}
                        xl={gridView ? "4" : 12}
                        className="our-course-col"
                      >
                        <div className="our-course-card d-flex">
                          <div className="course-card-img">
                            <Link
                              onClick={(e) => handleDetailButton(e, pc.id)}
                              className="course-img-link"
                            >
                              <Image
                                src={
                                  pc.s3_url ? pc.s3_url : Default_video_image
                                }
                                className="w-100"
                                onError={(e) => handleImgLoadingError(e)}
                              />
                            </Link>
                          </div>
                          <div className="course-card-ctn d-flex flex-column">
                            <div className="course-card-body">
                              <h4>
                                <Image
                                  className="img-blook-icon"
                                  src={require("../../assets/images/right-vector.png")}
                                  onClick={(e) => handleDetailButton(e, pc.id)}
                                />{" "}
                                The Merchant
                              </h4>
                              <h3>
                                {pc.title} ({language_defaults[pc.language]})
                              </h3>
                            </div>

                            <div className="course-card-footer">
                              <Link
                                onClick={(e) => handleDetailButton(e, pc.id)}
                                className="course-details"
                              >
                                Watch Now
                              </Link>
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))
                  ) : (
                    <Col>
                      <div className="no-ctn-found whiteCommen-box">
                        No Course Found
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
            </div>
            {display?.last_page > 1 && (
              <div>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={<ChevronRight />}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={display?.per_page}
                  pageCount={display?.last_page}
                  forcePage={marketData.page - 1}
                  previousLabel={<ChevronLeft />}
                  renderOnZeroPageCount={null}
                  containerClassName="pagination justify-content-center"
                  pageLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  nextLinkClassName="page-link"
                  activeLinkClassName="active"
                  pageClassName="page-item"
                  nextClassName="next page-item"
                  previousClassName="previous page-item"
                  breakClassName="break page-item"
                  breakLinkClassName="page-link"
                />
              </div>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
}
