import Fetcher from "../library/Fetcher";

export const getPrevPaymentDetails = async (data) => {
  return Fetcher.get(`/getPreviousPaymentCycle`);
};
export const getUpcomingPaymentDetails = async (data) => {
  return Fetcher.get(
    `/getUpcomingPayment?package_id=${data.package_id}&type=${data.type}`
  );
};
export const getCancelPaymentDetails = async (data) => {
  return Fetcher.get(
    `/cancelPaymentcycle?package_id=${data.package_id}&type=${data.type}`
  );
};

export default {
  getPrevPaymentDetails,
  getUpcomingPaymentDetails,
  getCancelPaymentDetails,
};
