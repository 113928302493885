import React from "react";

const BgPatternLines = () => {
  return (
    <svg
      width="185"
      height="185"
      viewBox="0 0 185 185"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_61_402)">
        <mask
          id="mask0_61_402"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="185"
          height="185"
        >
          <path d="M185 0H0V185H185V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_61_402)">
          <path d="M5.32 0H7.43L0 7.38V5.26L5.32 0Z" fill="#00B7E1" />
          <path d="M18.09 0H20.17L0 20.12V18.07L18.09 0Z" fill="#00B7E1" />
          <path
            d="M30.95 0H33.06C22.14 10.9467 11.26 21.8233 0.42 32.63C0.293333 32.75 0.153333 32.7067 0 32.5V30.94L30.95 0Z"
            fill="#00B7E1"
          />
          <path d="M43.71 0H45.85L0 45.83V43.7L43.71 0Z" fill="#00B7E1" />
          <path d="M56.5 0H58.64L0 58.61V56.49L56.5 0Z" fill="#00B7E1" />
          <path d="M69.3 0H71.47L0 71.45V69.29L69.3 0Z" fill="#00B7E1" />
          <path
            d="M82.08 0H83.84C83.94 0.22 83.9633 0.356667 83.91 0.41C56.0767 28.2433 28.2433 56.08 0.41 83.92C0.323333 84.0133 0.186667 83.9333 0 83.68V82.07L82.08 0Z"
            fill="#00B7E1"
          />
          <path
            d="M94.94 0H97.07C64.8167 32.2733 32.6 64.4833 0.42 96.63C0.3 96.7567 0.16 96.7133 0 96.5V94.93L94.94 0Z"
            fill="#00B7E1"
          />
          <path
            d="M107.7 0H109.89L0.4 109.52C0.353333 109.567 0.296667 109.577 0.23 109.55C0.17 109.523 0.0933333 109.483 0 109.43V107.7L107.7 0Z"
            fill="#00B7E1"
          />
          <path
            d="M120.49 0H122.67L0.35 122.35C0.31 122.39 0.263333 122.393 0.21 122.36C0.136667 122.313 0.0666667 122.263 0 122.21V120.49L120.49 0Z"
            fill="#00B7E1"
          />
          <path d="M133.31 0H135.47L0 135.46V133.29L133.31 0Z" fill="#00B7E1" />
          <path
            d="M146.09 0H147.84C147.94 0.22 147.963 0.356667 147.91 0.41C98.7433 49.5767 49.58 98.75 0.42 147.93C0.326667 148.017 0.186667 147.933 0 147.68V146.08L146.09 0Z"
            fill="#00B7E1"
          />
          <path
            d="M158.94 0H161.07C107.483 53.6067 53.9333 107.15 0.42 160.63C0.3 160.757 0.16 160.713 0 160.5V158.94L158.94 0Z"
            fill="#00B7E1"
          />
          <path
            d="M171.7 0H173.89L0.39 173.52C0.343333 173.567 0.29 173.577 0.23 173.55C0.17 173.53 0.0933333 173.49 0 173.43V171.69L171.7 0Z"
            fill="#00B7E1"
          />
          <path
            d="M184.48 0H185V1.7L1.7 185H0V184.25C0.0333333 184.25 0.07 184.25 0.11 184.25C0.176667 184.25 0.236667 184.223 0.29 184.17L184.48 0Z"
            fill="#00B7E1"
          />
          <path
            d="M185 12.3799V14.4999L14.49 185H12.36L185 12.3799Z"
            fill="#00B7E1"
          />
          <path
            d="M185 25.56V27.3L27.29 185H25.12L184.62 25.46C184.66 25.4267 184.707 25.4167 184.76 25.43C184.82 25.45 184.9 25.4933 185 25.56Z"
            fill="#00B7E1"
          />
          <path
            d="M185 38.3701V40.0701L40.0599 185H37.9399L185 38.3701Z"
            fill="#00B7E1"
          />
          <path
            d="M185 50.71V52.93L52.92 185H51.22C51.08 184.76 51.04 184.61 51.1 184.55C95.66 140.003 140.293 95.39 185 50.71Z"
            fill="#00B7E1"
          />
          <path
            d="M185 63.54V65.71L65.69 185H63.53L185 63.54Z"
            fill="#00B7E1"
          />
          <path
            d="M185 76.3701V78.5001L78.49 185H76.37L185 76.3701Z"
            fill="#00B7E1"
          />
          <path
            d="M185 89.1599V91.3099L91.29 185H89.16L185 89.1599Z"
            fill="#00B7E1"
          />
          <path
            d="M185 101.99V104.08L104.06 185H101.97L185 101.99Z"
            fill="#00B7E1"
          />
          <path
            d="M185 114.72V116.93L116.92 185H115.22C115.08 184.767 115.043 184.617 115.11 184.55C138.33 161.337 161.627 138.06 185 114.72Z"
            fill="#00B7E1"
          />
          <path
            d="M185 127.55V129.71L129.7 185H127.53L185 127.55Z"
            fill="#00B7E1"
          />
          <path
            d="M185 140.4V142.5L142.49 185H140.37L185 140.4Z"
            fill="#00B7E1"
          />
          <path
            d="M185 153.18V155.29L155.27 185H153.17L185 153.18Z"
            fill="#00B7E1"
          />
          <path
            d="M185 166.05V168.07L168.03 185H166.02L185 166.05Z"
            fill="#00B7E1"
          />
          <path
            d="M185 178.89V180.97L180.91 185H178.92L185 178.89Z"
            fill="#00B7E1"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_61_402">
          <rect width="185" height="185" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BgPatternLines;
