import React, { useEffect, useState } from "react";
import {
  Col,
  Image,
  Row,
  Accordion,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  CloseButton,
  Form,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { language_defaults } from "../../lib/utils";
import Default_video_image from "../../assets/images/default_video_image.png";
import {
  forexcourseEligibility,
  planIdConfig,
  public_image_url,
} from "../../config/constants";
import { courseListApi } from "../../api/coursesApi";
import Loader from "../../components/loader/loader";
import { Upgrade } from "../../assets/svg/Upgrade";
import { Logout } from "../../assets/svg/Logout";
import { EyeSlash } from "../../assets/svg/EyeSlash";
import logo from "../../assets/images/logo.svg";
import { logout } from "../../api/authApi";
import { toast } from "react-toastify";
import { handlePlan, handleUser } from "../../reducers/userSlice";
import {
  handleActivePlanTab,
  handleNextPlanPayments,
  handlePlanPayments,
} from "../../reducers/paymentSlice";
import ReactPaginate from "react-paginate";
import ChevronRight from "../../assets/svg/ChevronRight";
import ChevronLeft from "../../assets/svg/ChevronLeft";
import { Helmet } from "react-helmet";

export default function ForexLesson({ gridView, gridOption, activeTab }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const plans = useSelector((state) => state.authUser).plans;
  const planPricing = useSelector((state) => state.merchant.planPricing) || [];
  const [loading, setLoading] = useState(false);
  const [openSubModal, setOpenSubModal] = useState(false);
  const [display, setDisplay] = useState([]);
  const [plansName, setPlansName] = useState(false);
  const [forexData, setForexData] = useState({
    page: 1,
    lang: "",
    is_free: 0,
    order: "desc",
  });

  useEffect(() => {
    setLoading(true);
    AllCoursesMutation.mutate(forexData);
  }, [forexData]);

  useEffect(() => {
    if (plans.length > 0) {
      var plansid = plans.map((val) => val.package_id);
      setPlansName(
        planPricing
          .filter((pl) => plansid.includes(pl.id))
          .map((val) => val.package_name)
      );
    }
  }, []);

  useEffect(() => {
    if (activeTab === "secondTab") {
      if (display.length === 0) {
        gridOption(false);
      } else {
        gridOption(true);
      }
    }
  }, [display]);

  const AllCoursesMutation = useMutation(courseListApi, {
    onSuccess: (data) => {
      setLoading(false);
      if (data?.data) {
        setDisplay(data.data.courses);
      }
      return;
    },
    onError: (error) => {
      setLoading(false);
    },
  });

  const handleDetailButton = (e, videoDetail) => {
    e.preventDefault();
    const token = sessionStorage.getItem("tm-token");
    if (!token) {
      navigate("/signup");
    } else {
      let isPass = false;
      if (videoDetail.free == 1) {
        isPass = true;
      } else {
        for (let plan in plans) {
          if (forexcourseEligibility.includes(plans[plan].package_id)) {
            isPass = true;
            break;
          }
        }
      }

      if (isPass) {
        navigate(`/watch?c=${videoDetail.id}&tp=set`);
      } else {
        setOpenSubModal(true);
      }
    }
  };

  const handleFilterOption = (type, value) => {
    const details = {};
    if (type === "price") {
      details["is_free"] = value === 0 ? 1 : 0;
    } else if (type === "language") {
      let new_val = "";
      if (forexData.lang !== value) {
        new_val = value;
      }
      details["lang"] = new_val;
    }
    details["page"] = 1;

    setForexData({
      ...forexData,
      ...details,
    });
  };

  const checkelegibility = (vid_details) => {
    if (vid_details.free > 0) {
      return true;
    } else {
      for (let plan in plans) {
        if (forexcourseEligibility.includes(plans[plan].package_id)) {
          return true;
        }
      }
    }
    return false;
  };

  const handleSelectPlan = (event) => {
    event.preventDefault();
    const token = sessionStorage.getItem("tm-token");
    if (token) {
      navigate("/payment", {
        state: {
          packageId: planIdConfig.forex,
        },
      });
    } else {
      navigate("/login");
    }
  };

  const logoutHandler = () => {
    logoutMutation.mutate();
  };

  const logoutMutation = useMutation(logout, {
    onSuccess: (data) => {
      if (data?.data) {
        toast.success(data?.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }

      dispatch(handleUser(null));
      dispatch(handlePlan([]));
      dispatch(handlePlanPayments([]));
      dispatch(handleNextPlanPayments([]));
      dispatch(handleActivePlanTab("first"));
      sessionStorage.removeItem("tm-token");
      navigate("/login");
      return;
    },
    onError: (error) => {
      if (error?.response?.data?.errors) {
        return;
      }
    },
  });

  const handlePageClick = (event) => {
    setForexData({
      ...forexData,
      page: event.selected + 1,
    });
  };

  const handleOrderChange = (e) => {
    setForexData({
      ...forexData,
      order: e.target.value,
    });
  };

  const handleImgLoadingError = (e) => {
    e.target.src = Default_video_image;
  };

  return (
    <>
      <Helmet>
        <title>Hesham Elsaid - Forex Lesson</title>
        <meta property="og:title" content="Hesham Elsaid - Forex Lesson" />
        <link rel="canonical" href="https://eltaajir.com/about-me" />
      </Helmet>
      {loading && <Loader />}
      <Row>
        <Col sm={12} lg={4} xl={3}>
          <div className="myplan-wrap-left">
            <div className="filter-wrapper whiteCommen-box">
              <h3>Language</h3>
              <ul className="language-ul">
                {Object.keys(language_defaults).map((lang, index) => {
                  return (
                    <li key={index}>
                      <div className="field-wrap">
                        <input
                          type="checkbox"
                          id={lang}
                          checked={forexData.lang === lang}
                          onChange={() => handleFilterOption("language", lang)}
                        />
                        <label htmlFor={lang}>{language_defaults[lang]}</label>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="option-wrap filter-wrapper">
              <Accordion defaultActiveKey="0" className="whiteCommen-box">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Filter option</Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>
                        <div className="field-wrap">
                          <input
                            type="checkbox"
                            id="free_lession"
                            checked={forexData.is_free === 1}
                            onChange={() =>
                              handleFilterOption("price", forexData.is_free)
                            }
                          />
                          <label htmlFor="free_lession">Free Lesson</label>
                        </div>
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </Col>
        <Col sm={12} lg={8} xl={9}>
          <div className="our-course">
            {display?.data?.length > 0 && (
              <div className="blog-sort-wrap">
                <Form.Select
                  aria-label="label"
                  defaultValue={forexData.order}
                  onChange={(e) => handleOrderChange(e)}
                >
                  <option value="desc">New to old</option>
                  <option value="asc">Old to new</option>
                </Form.Select>
              </div>
            )}
            <div className="our-course-wrap">
              <div className="our-course-ctn">
                <Row>
                  {display?.data?.length > 0 ? (
                    display?.data.map((pc, index) => (
                      <Col
                        key={index}
                        sm={gridView ? "6" : 12}
                        md={gridView ? "6" : 12}
                        lg={gridView ? "6" : 12}
                        xl={gridView ? "4" : 12}
                        className="our-course-col"
                      >
                        <div
                          className={`our-course-card d-flex${
                            !checkelegibility(pc) ? " in_active" : ""
                          }`}
                        >
                          <div className="course-card-img">
                            <Link
                              onClick={(e) => handleDetailButton(e, pc)}
                              className="course-img-link"
                            >
                              <Image
                                src={
                                  pc.course_image
                                    ? public_image_url + "/" + pc.course_image
                                    : Default_video_image
                                }
                                className="w-100"
                                onError={(e) => handleImgLoadingError(e)}
                              />
                            </Link>
                          </div>
                          <div className="course-card-ctn d-flex flex-column">
                            <div className="course-card-body">
                              <h4>
                                <Image
                                  className="img-blook-icon"
                                  src={require("../../assets/images/right-vector.png")}
                                />
                                The Merchant
                              </h4>
                              <h3>
                                {pc.course_title} (
                                {language_defaults[pc.language]})
                              </h3>
                            </div>

                            <div className="course-card-footer">
                              <Link
                                onClick={(e) => handleDetailButton(e, pc)}
                                className="course-details"
                              >
                                Watch Now
                              </Link>
                            </div>
                          </div>
                          <div className="card-overlay">
                            <div className="overlay-wrap">
                              <div className="get-course d-flex align-items-center">
                                <EyeSlash />
                                <p className="mb-0">Get the courses</p>
                              </div>
                              <Button onClick={() => setOpenSubModal(true)}>
                                Subscribe now
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))
                  ) : (
                    <Col>
                      <div className="no-ctn-found whiteCommen-box">
                        No Course Found
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
              {display?.last_page > 1 && (
                <div>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={<ChevronRight />}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={display?.per_page}
                    pageCount={display?.last_page}
                    previousLabel={<ChevronLeft />}
                    renderOnZeroPageCount={null}
                    forcePage={forexData.page - 1}
                    containerClassName="pagination justify-content-center"
                    pageLinkClassName="page-link"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    activeLinkClassName="active"
                    pageClassName="page-item"
                    nextClassName="next page-item"
                    previousClassName="previous page-item"
                    breakClassName="break page-item"
                    breakLinkClassName="page-link"
                  />
                </div>
              )}
            </div>
          </div>
          <Modal show={openSubModal} className="subscribe-modal">
            <ModalHeader>
              <img src={logo} alt="logo" className="w-100" />
              <h3>Good Morning, User.</h3>
              <p>Welcome to THE MERCHANT</p>
            </ModalHeader>
            <ModalBody>
              <p>Now Your membership status: </p>
              <div className="d-flex capsuleCourse">
                {plansName.length > 0 ? (
                  plansName.map((pl, idx) => <span key={idx}>{pl}</span>)
                ) : (
                  <span>FREE</span>
                )}
              </div>

              <Button className="upgrade" onClick={(e) => handleSelectPlan(e)}>
                Upgrade Your Membership
                <Upgrade />
              </Button>
              <Button className="sign-out" onClick={() => logoutHandler()}>
                Sign Out
                <Logout />
              </Button>
            </ModalBody>
            <CloseButton
              className="close-btn"
              onClick={() => setOpenSubModal(false)}
            ></CloseButton>
          </Modal>
        </Col>
      </Row>
    </>
  );
}
