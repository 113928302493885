import React from "react";

export const FacebookIconShare = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <path
        d="M24 4C12.954 4 4 12.954 4 24C4 35.046 12.954 44 24 44C35.046 44 44 35.046 44 24C44 12.954 35.046 4 24 4Z"
        fill="url(#paint0_linear_336_97)"
      />
      <path
        d="M26.7066 29.3009H31.8826L32.6956 24.0429H26.7066V21.1689C26.7066 18.9849 27.4206 17.0479 29.4636 17.0479H32.7466V12.4599C32.1696 12.3819 30.9496 12.2119 28.6446 12.2119C23.8306 12.2119 21.0086 14.7539 21.0086 20.5459V24.0439H16.0596V29.3019H21.0076V43.7539C21.9876 43.8999 22.9806 43.9999 23.9996 43.9999C24.9206 43.9999 25.8196 43.9159 26.7066 43.7959V29.3009Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_336_97"
          x1="9.993"
          y1="9.993"
          x2="40.615"
          y2="40.615"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2AA4F4" />
          <stop offset="1" stopColor="#007AD9" />
        </linearGradient>
      </defs>
    </svg>
  );
};
