import React, { useEffect, useState } from "react";
import Logo from "../assets/images/logo_new.svg";
import Slider from "react-slick";
import {
  Button,
  CloseButton,
  Col,
  Container,
  Form,
  Image,
  Modal,
  ModalHeader,
  Row,
} from "react-bootstrap";
import thankYou from "../assets/images/thank-you.png";
import TopBanner from "../components/banner/TopBanner";
import { useMutation } from "@tanstack/react-query";
import { countryList } from "../api/pricingApi";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import { Link, useNavigate } from "react-router-dom";
import { register } from "../api/authApi";
import { getWebinars } from "../api/webinarApi";
import moment from "moment";
import { phones_validation } from "../config/constants";
import Loader from "../components/loader/loader";
import { Helmet } from "react-helmet";

export default function Webinar() {
  const navigate = useNavigate();
  const [openReg, setOpenReg] = useState(false);
  const [openThankReg, setOpenThankReg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [webinarList, setWebinarList] = useState([]);
  const [countrycode, setCountryCode] = useState("");
  
  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
  };
  const [signData, setSignData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    confirm_email: "",
    contact: "",
    country: "",
    webinar_id: "",
  });
  const [validation, setValidation] = useState({
    first_name: "",
    last_name: "",
    email: "",
    confirm_email: "",
    contact: "",
    country: "",
  });
  const resetSignData = () => {
    setSignData({
      ...signData,
      first_name: "",
      last_name: "",
      email: "",
      confirm_email: "",
      contact: "",
      country: "",
      webinar_id: "",
    });
  };

  useEffect(() => {
    countryListAction.mutate();
    webinarMutate();
  }, []);

  const { status: webinarStatus, mutate: webinarMutate } = useMutation(
    getWebinars,
    {
      onSuccess: (data) => {
        setWebinarList(data?.data.data);
        return;
      },
      onError: (error) => {
        console.log(error?.response.data.errors);
        return;
      },
    }
  );

  const countryListAction = useMutation(countryList, {
    onSuccess: (data) => {
      setLoading(false);
      if (data?.data) {
        setCountries(data.data);
      }
      return;
    },
    onError: (error) => {
      setLoading(false);
      toast.error(error.response.data.error, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    },
  });

  const handleChangeInput = (event) => {
    let { name, value } = event.target;

    setSignData({
      ...signData,
      [name]: value,
    });

    setValidation({
      ...validation,
      [name]: "",
    });
  };

  const handlePhoneChange = (event, country_code, name) => {
    setSignData({
      ...signData,
      [name]: "+" + event,
    });

    setCountryCode(country_code);
    setValidation({
      ...validation,
      [name]: "",
    });
  };

  const registerUserMutation = useMutation(register, {
    onSuccess: (data) => {
      setLoading(false);
      if (data?.data) {
        toast.success(data.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }
      setOpenReg(false);
      setOpenThankReg(true);
      resetSignData();
      return;
    },
    onError: (error) => {
      setLoading(false);
      if (error?.response?.data?.errors) {
        
        return;
      }
    },
  });

  const onSignup = async () => {
    if (validateForm()) {
      setLoading(true);
      registerUserMutation.mutate(signData);
    }
  };

  const isSlideresiger = (item) => {
    setSignData({
      ...signData,
      webinar_id: item.id,
    });
    setOpenReg(true);
  };

  const validateForm = () => {
    const formKeys = Object.keys(signData);
    let valid = true;
    let grt_validation = {};
    for (let i = 0; i < formKeys.length; i++) {
      const c_validate = validate(formKeys[i], signData[formKeys[i]]);
      if (c_validate !== true) {
        valid = false;
        grt_validation[formKeys[i]] = c_validate;
      } else {
        grt_validation[formKeys[i]] = "";
      }
    }
    if (!valid) {
      setValidation(grt_validation);
    }
    return valid;
  };

  const validate = (name, value) => {
    if (name === "first_name") {
      if (!value) {
        return "Please enter first name";
      }
    } else if (name === "last_name") {
      if (!value) {
        return "Please enter last name";
      }
    } else if (name === "email") {
      var pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (value) {
        if (!pattern.test(value)) {
          return "Please enter valid email address";
        }
      } else {
        return "Please enter your email address";
      }
    } else if (name === "confirm_email") {
      if (!value) {
        return "Please confirm your email";
      } else if (value !== signData.email) {
        return "Confirm email does not matched";
      }
    } else if (name === "contact") {
      if (!value) {
        return "Please enter mobile number";
      } else {
        var phoneregax =
          phones_validation[countrycode.countryCode.toUpperCase()];
        if (!phoneregax.test(value)) {
          return "Invalid mobile number";
        }
      }
    } else if (name === "country") {
      if (!value) {
        return "Please select your country";
      }
    }
    return true;
  };
  return ( 
    <>
     <Helmet>
        <title>The Merchant - Webinar</title>
        <link rel="canonical" href="https://eltaajir.com/watch" />
        <meta property="og:title" content="The Merchant - Webinar" />  
      </Helmet>
      <TopBanner title="Webinar" />
      {webinarStatus !== "success" ? (
        <Loader />
      ) : (
        <section className="webinar-section">
          <Container>
            <div className="webinar-inner">
              {webinarList.length > 0 ? (
                <div className="slider-wrapper">
                  <Slider {...settings} className="webinar-slider-main">
                    {webinarList.map((item, idx) => {
                      return (
                        <div className="web-slide" key={idx}>
                          <div className="slide-body-ctn">
                            <h4 className="webinar-title">{item.title}</h4>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.description,
                              }}
                            ></div>
                          </div>

                          <div className="slide-btm d-flex justify-content-between align-items-center">
                            <div className="date-box d-flex position-relative gap-4">
                              <span className="date">
                                Date:{" "}
                                {moment(item.held_date_time).format(
                                  "DD/MM/YYYY"
                                )}
                              </span>
                              <span className="time">
                                Time:{" "}
                                {moment(item.held_date_time).format("h:mm A")}
                              </span>
                            </div>
                            <div>
                              <Button onClick={() => isSlideresiger(item)}>
                                Register
                              </Button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              ) : (
                <div className="web-message-sec">
                  <p>
                    Currently, there are no upcoming webinars scheduled. Please
                    check back later for updates. To be among the first to know
                    about our future webinars, ensure you're subscribed to our
                    newsletter. Stay ahead and never miss an opportunity to
                    learn and grow with us!
                  </p>
                </div>
              )}
            </div>
            {webinarList.length > 0 && (
              <>
                <Modal show={openReg} centered className="registration-modal">
                  <Modal.Body>
                    <div className="login-form-one">
                      <div className="logo-login">
                        <Image src={Logo} onClick={() => navigate("/")} />
                      </div>
                      <p className="signup-msg">Sign up into your account</p>
                      <Form className="log-in-input">
                        <Row>
                          <Col md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupFirstName"
                            >
                              <Form.Label className="">First name</Form.Label>
                              <Form.Control
                                className={`input-box ${
                                  validation.first_name ? "error-add" : ""
                                }`}
                                type="text"
                                placeholder="Enter Your First Name"
                                name="first_name"
                                value={signData.first_name}
                                onChange={(e) => handleChangeInput(e)}
                              />
                              <span className="error-new">
                                {validation.first_name}
                              </span>
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupLastName"
                            >
                              <Form.Label className="form-new-lable">
                                Last name
                              </Form.Label>
                              <Form.Control
                                className={`input-box ${
                                  validation.last_name ? "error-add" : ""
                                }`}
                                type="text"
                                placeholder="Enter Your Last name"
                                name="last_name"
                                value={signData.last_name}
                                onChange={(e) => handleChangeInput(e)}
                              />
                              <span className="error-new">
                                {validation.last_name}
                              </span>
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label className="form-new-lable">
                                Email
                              </Form.Label>
                              <Form.Control
                                className={`input-box ${
                                  validation.email ? "error-add" : ""
                                }`}
                                type="email"
                                placeholder="info@gmail.com"
                                name="email"
                                value={signData.email}
                                onChange={(e) => handleChangeInput(e)}
                              />
                              <span className="error-new">
                                {validation.email}
                              </span>
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupConfEmail"
                            >
                              <Form.Label className="form-new-lable">
                                Confirm Email{" "}
                              </Form.Label>
                              <Form.Control
                                className={`input-box ${
                                  validation.confirm_email ? "error-add" : ""
                                }`}
                                type="email"
                                placeholder="info@gmail.com"
                                name="confirm_email"
                                value={signData.confirm_email}
                                onChange={(e) => handleChangeInput(e)}
                              />
                              <span className="error-new">
                                {validation.confirm_email}
                              </span>
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupMobile"
                            >
                              <PhoneInput
                                specialLabel="Mobile number"
                                country={"pl"}
                                inputProps={{
                                  name: "contact",
                                }}
                                placeholder="+48 1234567890"
                                value={signData.contact}
                                onChange={(e, country_code) =>
                                  handlePhoneChange(e, country_code, "contact")
                                }
                                inputClass={`input-box${
                                  validation.contact ? " error-add" : ""
                                }`}
                              />
                              <span className="error-new">
                                {validation.contact}
                              </span>
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3 re-country">
                              <label className="form-new-lable">Country</label>
                              <div className="coutry_select">
                                <div className="custome-input">
                                  <Form.Select
                                    name={"country"}
                                    value={signData.country}
                                    onChange={(e) => handleChangeInput(e)}
                                    className={`form-control w-100 input-box${
                                      validation.country ? " error-add" : ""
                                    }`}
                                  >
                                    <option value="--">Select Country</option>
                                    {countries.map((country, index) => (
                                      <option
                                        key={index}
                                        id={country.code}
                                        value={country.code}
                                      >
                                        {country.name}
                                      </option>
                                    ))}
                                  </Form.Select>
                                  <span className="error-new">
                                    {validation.country}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <p className="tnc-fca-agr">
                            *By clicking 'Register' you automatically agree to
                            our{" "}
                            <Link to={"/terms-and-conditions"} target="_blank">
                              Term and Conditions
                            </Link>
                            ,{" "}
                            <Link to={"/privacy-policy"} target="_blank">
                              Privacy Policy
                            </Link>{" "}
                            and{" "}
                            <Link to={"/fca-disclaimer"} target="_blank">
                              FCA Disclaimer
                            </Link>
                            .
                          </p>
                        </Row>

                        <Button
                          className="login-btn"
                          variant="primary"
                          disabled={loading}
                          onClick={() => onSignup()}
                        >
                          Register
                        </Button>
                      </Form>
                    </div>
                  </Modal.Body>

                  <CloseButton
                    className="close-btn"
                    onClick={() => setOpenReg(false)}
                  ></CloseButton>
                </Modal>
                <Modal show={openThankReg} centered className="thank-you-modal">
                  <ModalHeader>
                    <div className="ty-logo">
                      <Image src={Logo} onClick={() => navigate("/")} />
                    </div>
                  </ModalHeader>
                  <Modal.Body>
                    <div className="ty-box-inner">
                      <div className="ty-body">
                        <Image src={thankYou} />
                      </div>
                    </div>
                  </Modal.Body>

                  <CloseButton
                    className="close-btn"
                    onClick={() => setOpenThankReg(false)}
                  ></CloseButton>
                </Modal>
              </>
            )}
          </Container>
        </section>
      )}
    </>
  );
}
