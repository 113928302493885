import Fetcher from "../library/Fetcher";

export const getAllEbookApi = (data) => {
  return Fetcher.get(
    `/getEbooks?page=${data.page}&order=${data.order}&is_free=${data.is_free}&record_per_page=20`
  );
};

export const getEbookById = (id) => {
  return Fetcher.get(`/ebookDetails/${id}`);
};
export const getfilterEbookApi = async (id) => {
  return Fetcher.get(`/getEbooks?is_free=${id}`);
};
export default { getAllEbookApi, getfilterEbookApi };
