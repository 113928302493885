import { createPortal } from "react-dom";
import React from "react";
import Logo from "../../assets/images/merchant-logo.svg";
import { Image } from "react-bootstrap";

export default function Loader() {
  return createPortal(
    <div className="spinner-wrap">
      <section>
        <span className="merchant-loader"><Image src={Logo} className="loader_images" /></span>
      </section>
    </div>,
    document.body
  );
}
