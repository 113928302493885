import React from "react";

import { Helmet } from "react-helmet";
import Banner from "./Banner";
import Benifit from "./Benifit";
import Pricing from "../../components/pricing/Pricing";
import TestiMonials from "./TestiMonials";
import ExploreCourse from "./ExploreCourse";

export default function HomePage() {
  
  return (
    <>
      <Helmet>
        <title>The Merchant - Forex Trader & Mentor</title>
        <meta property="og:title" content="The Merchant - Forex Trader & Mentor" />        
        <link rel="canonical" href="https://eltaajir.com/" />
      </Helmet>
      <Banner />
      <Benifit />
      <Pricing />
      <TestiMonials />
      <ExploreCourse />
    </>
  );
}
