import React, { useEffect, useState } from "react";
import { Col, Container, Row, Button, Nav } from "react-bootstrap";
import { useMutation } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import Tab from "react-bootstrap/Tab";
import Grid from "../../assets/svg/Grid";
import List from "../../assets/svg/List";
import CurrentPlan from "./CurrentPlan";
import ForexLesson from "./ForexLesson";
import MarketAnalysis from "./MarketAnalysis";
import Crypto from "./Crypto";
import EBook from "./EBook";
import { handleActivePlanTab } from "../../reducers/paymentSlice";
import { handleAllCourseLesson } from "../../reducers/planSlice";
import { getAwsVideos } from "../../api/videoDetailsApi";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const actTabs = {
  fpt: "first",
  set: "second",
  tht: "third",
  frt: "fourth",
  fft: "fifth",
};

function MyPlan() {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { course_lesson } = useSelector((state) => state.courseLesson);
  const [openFilterOption, setOpenFilterOption] = useState(
    state ? true : false
  );
  const [gridView, setGridView] = useState(true);
  const [activeTab, setActiveTab] = useState("");
  const user = useSelector((state) => state.authUser).user;

  useEffect(() => {
    if (state) {
      setActiveTab(actTabs[state.active]);
    } else {
      setActiveTab("first");
    }

    if (course_lesson.length === 0) {
      getAwsVideosList.mutate();
    }
  }, []);

  const getAwsVideosList = useMutation(getAwsVideos, {
    onSuccess: (data) => {
      dispatch(handleAllCourseLesson(data?.data.data));
    },
  });

  const tabClick = (e) => {
    dispatch(handleActivePlanTab(e.target.dataset.rrUiEventKey));
    setActiveTab(e.target.name);
    if (e.target.name === "first") {
      setOpenFilterOption(false);
    } else {
      setOpenFilterOption(true);
    }
  };

  return (
    <section className="my-plan-pg">
      <Helmet>
        <title>Hesham Elsaid - Forex & Crypto trader</title>
        <meta
          property="og:title"
          content="The Merchant - My Plan"
        />
        <link rel="canonical" href="https://eltaajir.com/about-me" />
      </Helmet>
      <div className="tabs-bg-layer">
        <Container>
          <div>
            <h3 className="tabs-bg-layer-title">
              {user && "Welcome, " + user?.first_name + " " + user?.last_name}
            </h3>
          </div>
        </Container>
      </div>
      <Container className="myplan-container">
        <div className="myplan-wrapper">
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={state ? actTabs[state.active] : "first"}
          >
            <div className="tabs-container-wrapper">
              <div className="tabs-header d-flex">
                <div className="tab-pills-wrapper whiteCommen-box">
                  <Nav variant="pills">
                    <Nav.Item>
                      <Nav.Link
                        name="first"
                        eventKey="first"
                        onClick={(e) => tabClick(e)}
                      >
                        My Plan
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="second"
                        name="second"
                        onClick={(e) => tabClick(e)}
                      >
                        Forex Course Lesson
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="third"
                        name="third"
                        onClick={(e) => tabClick(e)}
                      >
                        Market Analysis
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="fourth"
                        name="fourth"
                        onClick={(e) => tabClick(e)}
                      >
                        Crypto
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="fifth"
                        name="fifth"
                        onClick={(e) => tabClick(e)}
                      >
                        eBook
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                {openFilterOption && (
                  <div className="tab-view-define whiteCommen-box">
                    <Button
                      className={`${gridView ? "active-view" : ""}`}
                      onClick={() => setGridView(true)}
                    >
                      <Grid />
                    </Button>
                    <Button
                      className={`${!gridView ? "active-view" : ""}`}
                      onClick={() => setGridView(false)}
                    >
                      <List />
                    </Button>
                  </div>
                )}
              </div>
              <Row>
                <Col sm={12}>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <CurrentPlan />
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="second"
                      className={!gridView ? "listView" : ""}
                    >
                      <ForexLesson
                        gridView={gridView}
                        gridOption={setOpenFilterOption}
                        activeTab={activeTab}
                      />
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="third"
                      className={!gridView ? "listView" : ""}
                    >
                      <MarketAnalysis
                        gridView={gridView}
                        gridOption={setOpenFilterOption}
                        activeTab={activeTab}
                      />
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="fourth"
                      className={!gridView ? "listView" : ""}
                    >
                      <Crypto
                        gridView={gridView}
                        gridOption={setOpenFilterOption}
                        activeTab={activeTab}
                      />
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="fifth"
                      className={!gridView ? "listView" : ""}
                    >
                      <EBook
                        gridView={gridView}
                        gridOption={setOpenFilterOption}
                        activeTab={activeTab}
                      />
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </div>
          </Tab.Container>
        </div>
      </Container>
    </section>
  );
}

export default MyPlan;
