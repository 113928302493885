import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  auth: false,
  user: null,
  plans: [],
};

export const authUserSlice = createSlice({
  name: "authUser",
  initialState,
  reducers: {
    handleUser: (state, action) => {
      state.user = action.payload;
    },
    handlePlan: (state, action) => {
      state.plans = action.payload;
    },
  },
});

export const { handleUser, handlePlan } = authUserSlice.actions;

export default authUserSlice.reducer;
