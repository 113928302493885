import React, { useEffect, useState } from "react";
import { Col, Image, Row, Accordion, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { language_defaults } from "../../lib/utils";
import Default_video_image from "../../assets/images/default_video_image.png";
import { useMutation } from "@tanstack/react-query";
import { getAwsVideosTab } from "../../api/videoDetailsApi";
import ReactPaginate from "react-paginate";
import ChevronRight from "../../assets/svg/ChevronRight";
import ChevronLeft from "../../assets/svg/ChevronLeft";
import Loader from "../../components/loader/loader";
import { Helmet } from "react-helmet";

export default function Crypto({ gridView, gridOption, activeTab }) {
  const navigate = useNavigate();
  const [display, setDisplay] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cryptoData, setCryptoData] = useState({
    page: 1,
    order: "desc",
    selected_tab: "crypto",
    is_free: 0,
  });

  useEffect(() => {
    setLoading(false);
    getAwsVideosCryptoList.mutate(cryptoData);
  }, [cryptoData]);

  useEffect(() => {
    if (activeTab === "fourthTab") {
      if (display?.data?.length === 0) {
        gridOption(false);
      } else {
        gridOption(true);
      }
    }
  }, [display]);

  const handleDetailButton = (e, id) => {
    e.preventDefault();
    const token = sessionStorage.getItem("tm-token");

    if (!token) {
      navigate("/signup");
    } else {
      navigate(`/watch?d=${id}&tp=frt`);
    }
  };

  const handleFilterOption = () => {
    setCryptoData({
      ...cryptoData,
      is_free: cryptoData.is_free === 0 ? 1 : 0,
      page: 1,
    });
  };

  const handlePageClick = (event) => {
    setCryptoData({
      ...cryptoData,
      page: event.selected + 1,
    });
  };

  const handleOrderChange = (e) => {
    setCryptoData({
      ...cryptoData,
      order: e.target.value,
    });
  };

  const getAwsVideosCryptoList = useMutation(getAwsVideosTab, {
    onSuccess: (data) => {
      setLoading(false);
      if (data?.data) {
        setDisplay(data?.data?.data);
      }
      return;
    },
    onError: (error) => {
      setLoading(false);
    },
  });

  return (
    <>
      <Helmet>
        <title>Hesham Elsaid - Crypto trader</title>
        <meta property="og:title" content="Hesham Elsaid - Crypto trader" />
        <link rel="canonical" href="https://eltaajir.com/about-me" />
      </Helmet>
      {loading && <Loader />}
      <Row>
        <Col sm={12} lg={4} xl={3}>
          <div className="myplan-wrap-left">
            <div className="option-wrap filter-wrapper">
              <Accordion defaultActiveKey="0" className="whiteCommen-box">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Filter option</Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>
                        <div className="field-wrap">
                          <input
                            type="checkbox"
                            id="free_lession2"
                            checked={cryptoData.is_free === 1}
                            onChange={handleFilterOption}
                          />
                          <label htmlFor="free_lession2">Free Lesson</label>
                        </div>
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </Col>
        <Col sm={12} lg={8} xl={9}>
          <div className="our-course">
            {display?.data?.length > 0 && (
              <div className="blog-sort-wrap">
                <Form.Select
                  aria-label="label"
                  defaultValue={cryptoData.order}
                  onChange={(e) => handleOrderChange(e)}
                >
                  <option value="desc">New to old</option>
                  <option value="asc">Old to new</option>
                </Form.Select>
              </div>
            )}
            <div className="our-course-wrap">
              <div className="our-course-ctn">
                <Row>
                  {display?.data?.length > 0 ? (
                    display.data.map((pc, index) => (
                      <Col
                        key={index}
                        sm={gridView ? "6" : 12}
                        md={gridView ? "6" : 12}
                        lg={gridView ? "6" : 12}
                        xl={gridView ? "4" : 12}
                        className="our-course-col"
                      >
                        <div className="our-course-card d-flex">
                          <div className="course-card-img">
                            <Link
                              onClick={(e) => handleDetailButton(e, pc.id)}
                              className="course-img-link"
                            >
                              <Image
                                src={Default_video_image}
                                className="w-100"
                              />
                            </Link>
                          </div>
                          <div className="course-card-ctn d-flex flex-column">
                            <div className="course-card-body">
                              <h4>
                                <Image
                                  className="img-blook-icon"
                                  src={require("../../assets/images/right-vector.png")}
                                  onClick={(e) => handleDetailButton(e, pc.id)}
                                />{" "}
                                The Merchant
                              </h4>
                              <h3>
                                {pc.title} ({language_defaults[pc.language]})
                              </h3>
                            </div>

                            <div className="course-card-footer">
                              <Link
                                onClick={(e) => handleDetailButton(e, pc.id)}
                                className="course-details"
                              >
                                Watch Now
                              </Link>
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))
                  ) : (
                    <Col>
                      <div className="no-ctn-found whiteCommen-box">
                        No Course Found
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
              {display?.last_page > 1 && (
                <div>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={<ChevronRight />}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={display?.per_page}
                    pageCount={display?.last_page}
                    previousLabel={<ChevronLeft />}
                    renderOnZeroPageCount={null}
                    forcePage={cryptoData.page - 1}
                    containerClassName="pagination justify-content-center"
                    pageLinkClassName="page-link"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    activeLinkClassName="active"
                    pageClassName="page-item"
                    nextClassName="next page-item"
                    previousClassName="previous page-item"
                    breakClassName="break page-item"
                    breakLinkClassName="page-link"
                  />
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
