import React from "react";
import { Col, Row } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import Logo from "../assets/images/logo_new.svg";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { forgotPasswordApi } from "../api/authApi";
import { toast } from "react-toastify";
import Loader from "../components/loader/loader";
import { Helmet } from "react-helmet";
import { useCookies } from "react-cookie";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [cookies, setCookies] = useCookies(["email"]);
  
  const [loading, setLoading] = useState(false);
  const [forgotData, setForgotData] = useState({
    email: "",
  });
  const [validation, setValidation] = useState({
    email: "",
  });

  const handleChangeInput = (event) => {
    let { name, value } = event.target;

    setForgotData({
      ...forgotData,
      [name]: value,
    });

    setValidation({
      ...validation,
      [name]: "",
    });
  };

  const forgotPasswordMutation = useMutation(forgotPasswordApi, {
    onSuccess: (data) => {
      setLoading(false);
      if (data?.data) {
        setCookies("email", forgotData.email, { path: "/" });
        toast.success(data?.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }
      return;
    },
    onError: (error) => {
      setLoading(false);
      if (error?.response?.data?.errors) {
        
        toast.error("Invalidate Email Id", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        return;
      }
    },
  });

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onEmailSubmitHandler();
    }
  };

  const onEmailSubmitHandler = () => {
    if (validateForm()) {
      setLoading(true);
      forgotPasswordMutation.mutate(forgotData);
    }
  };

  const validateForm = () => {
    const formKeys = Object.keys(forgotData);
    let valid = true;
    let grt_validation = {};
    for (let i = 0; i < formKeys.length; i++) {
      const c_validate = validate(formKeys[i], forgotData[formKeys[i]]);
      if (c_validate !== true) {
        valid = false;
        grt_validation[formKeys[i]] = c_validate;
      } else {
        grt_validation[formKeys[i]] = "";
      }
    }
    if (!valid) {
      setValidation(grt_validation);
    }
    return valid;
  };

  const validate = (name, value) => {
    if (name === "email") {
      var pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (value) {
        if (!pattern.test(value)) {
          return "Please enter valid email address";
        }
      } else {
        return "Please enter your email address";
      }
    }
    return true;
  };

  return (
    <>
      <Helmet>
        <title>The Merchant | Forgot Password</title>
        <link rel="canonical" href="https://eltaajir.com/forgot-password" />
        <meta property="og:title" content="The Merchant | Forgot Password" />
      </Helmet>
      <section className="login-page">
        {loading && <Loader />}
        <div className="logoin-box-shadow">
          <Row className="p-0 m-0 align-items-center">
            <Col md={6} lg={8} className="p-0 m-0">
              <div className="login-img">
                <Image
                  src={require("../assets/images/add-logoin-img.png")}
                  className="w-100"
                />
              </div>
            </Col>
            <Col md={6} lg={4} className="p-0 m-0">
              <div className="login-form">
                <div className="logo-login">
                  <Image src={Logo} onClick={() => navigate("/")} />
                </div>
                <p>Login into your account</p>
                <Form className="log-in-input">
                  <Form.Group className="mb-3" controlId="formGroupEmail">
                    <Form.Label className="form-new-lable">Email Id</Form.Label>
                    <Form.Control
                      className="input-box"
                      type="email"
                      placeholder="Enter Your Email"
                      name="email"
                      onChange={(e) => handleChangeInput(e)}
                      onKeyDown={handleKeyDown}
                    />
                    <span className="error-new">{validation.email}</span>
                  </Form.Group>
                  <Button
                    className="login-btn"
                    variant="primary"
                    onClick={() => onEmailSubmitHandler()}
                  >
                    Submit
                  </Button>
                </Form>

                <div className="separator"></div>
                <Button
                  className="sign-up-btn"
                  variant="primary"
                  onClick={() => navigate("/signup")}
                >
                  Sign Up
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}
