import { configureStore } from "@reduxjs/toolkit";
import infoSlice from "../reducers/homeSlice";
import authUserSlice from "../reducers/userSlice";
import courseLessonSlice from "../reducers/planSlice";
import paymentSlice from "../reducers/paymentSlice";

export const store = configureStore({
  reducer: {
    merchant: infoSlice,
    authUser: authUserSlice,
    courseLesson: courseLessonSlice,
    payments: paymentSlice
  },
});
