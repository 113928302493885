import Fetcher from "../library/Fetcher";

export const freeCourseApi = async (id) => {
  const response = Fetcher.get(`/free-courses`);
  return response;
};

export const courseListApi = async (data) => {
  return Fetcher.get(
    `/courses?page=${data.page}${
      data.lang ? `&lang=${data.lang}` : ""
    }&is_free=${data.is_free}&order=${data.order}&record_per_page=20`
  );
};

export const courseDetailApi = async (id) => {
  return Fetcher.get(`/courseDetail/${id}`);
};

export default { freeCourseApi, courseListApi, courseDetailApi };
