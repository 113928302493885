import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import Logo from "../../assets/images/logo_new.svg";
import { Link, useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();

  const myNavigation = (e, path) => {
    e.preventDefault();
    navigate(path);
  };

  return (
    <Navbar collapseOnSelect expand="lg" className="header-navbar">
      <Container>
        <Navbar.Brand onClick={(e) => myNavigation(e, "/")}>
          <Image src={Logo} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="merchant-navbar-nav" />
        <Navbar.Collapse id="merchant-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link onClick={(e) => myNavigation(e, "/")}>Home</Nav.Link>
            <Nav.Link onClick={(e) => myNavigation(e, "/about-me")}>
              About Us
            </Nav.Link>
            <Nav.Link onClick={(e) => myNavigation(e, "/membership")}>
              Membership
            </Nav.Link>
            <Nav.Link onClick={(e) => myNavigation(e, "/my-notes")}>
              My Blog
            </Nav.Link>
            <Nav.Link onClick={(e) => myNavigation(e, "/webinar")}>
              Webinars
            </Nav.Link>
            <Nav.Link onClick={(e) => myNavigation(e, "/telegram-qr")}>
              Telegram
            </Nav.Link>
          </Nav>
          <Stack direction="horizontal" className="header-seacrh-lang-signup">
            <div className="header-search">
              <div className="head-seacrh-icon d-flex justify-content-center align-items-center">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.58342 18.125C4.87508 18.125 1.04175 14.2917 1.04175 9.58334C1.04175 4.87501 4.87508 1.04167 9.58342 1.04167C14.2917 1.04167 18.1251 4.87501 18.1251 9.58334C18.1251 14.2917 14.2917 18.125 9.58342 18.125ZM9.58342 2.29167C5.55841 2.29167 2.29175 5.56667 2.29175 9.58334C2.29175 13.6 5.55841 16.875 9.58342 16.875C13.6084 16.875 16.8751 13.6 16.8751 9.58334C16.8751 5.56667 13.6084 2.29167 9.58342 2.29167Z"
                    fill="#292D32"
                  />
                  <path
                    d="M18.3333 18.9583C18.175 18.9583 18.0166 18.9 17.8916 18.775L16.225 17.1083C15.9833 16.8667 15.9833 16.4667 16.225 16.225C16.4666 15.9833 16.8666 15.9833 17.1083 16.225L18.775 17.8917C19.0166 18.1333 19.0166 18.5333 18.775 18.775C18.65 18.9 18.4916 18.9583 18.3333 18.9583Z"
                    fill="#292D32"
                  />
                </svg>
              </div>
              <Form className="header-search-form position-absolute d-none">
                <Form.Control
                  type="search"
                  placeholder="Search"
                  className="me-2"
                  aria-label="Search"
                />
                <Button>Search</Button>
              </Form>
            </div>
            <div className="signup-btn-div">
              <Link
                onClick={(e) => myNavigation(e, "/signup")}
                className="btn btn-primary text-center"
              >
                Sign Up
              </Link>
            </div>
          </Stack>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
export default Header;
