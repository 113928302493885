import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import heshamImg from "../../assets/images/hesham-about-left.png";
import flyArrow from "../../assets/images/flying-arrow.png";
import logoCircle from "../../assets/images/logo-circle.png";
import BgPatternLines from "../../assets/svg/BgPatternLines";

export default function Banner() {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [token, setToken] = useState("");

  useEffect(() => {
    const havetoken = sessionStorage.getItem("tm-token");
    if (havetoken) {
      setToken(havetoken);
    }
    if (videoRef.current) {
      videoRef.current.onloadeddata = () => {
        videoRef.current.play();
      };
    }
  }, []);

  const myNavigation = (e, path) => {
    e.preventDefault();
    navigate(path);
  };

  return (
    <>
      <section className="home-hero-banner-top">
        <Container>
          <div className="banner-top-wrapper">
            <Row className="align-items-center">
              <Col xs={12} xl={9} lg={9} md={9} className="banner-top-left-col">
                <div className="banner-top-ctn position-relative">
                  <div>
                    <h2>What does it mean eltaajir?</h2>
                    <p>
                      “Eltaajir" is an Arabic word that translates to "The
                      Merchant" or "The Trader" in English. It typically refers
                      to someone engaged in business or trade, often in the
                      context of buying and selling goods or financial
                      instruments. In our context, it represents our role or
                      identity as a traders or businessperson in the financial
                      markets.
                    </p>
                  </div>
                  <div className="flying-arrow">
                    <img src={flyArrow} alt="arrow" />
                  </div>
                </div>
              </Col>
              <Col xs={4} xl={3} lg={3} md={3} className="banner-top-right-col">
                <div className="banner-top-img">
                  <img src={heshamImg} alt="Hesham" className="w-100" />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <section className="home-hero-banner">
        <Container>
          <div className="home-hero-banner-wrap">
            <div className="title-wrap d-flex">
              <div className="title-img">
                <img src={logoCircle} alt="Logo" />
              </div>
              <div className="title-ctn">
                <h3 className="banner-btm-title">Forex Trader & Mentor</h3>
                <h4 className="banner-btn-desc">Trader since 2012</h4>
              </div>
            </div>
            <div className="ctn-wrap">
              <p>
                Hello, I'm Hesham Elsaid, guiding you from initial curiosity to
                the mastery of Forex. Since embarking on my journey in 2012, not
                only as a trader but as a seeker, I've uncovered the market's
                hard truths and its transformative potential. My purpose is
                clear: to light the way for others through the challenges I once
                faced alone. Forex trading, when approached with the right
                mindset, opens doors to financial independence, resilience, and
                strategic thinking. I'm here to mentor you, ensuring you not
                only understand but also master Forex, thereby transforming your
                financial outlook and life.
              </p>
              <p>How I Guide You to Financial Freedom:</p>
              <ul>
                <li>
                  <p>
                    <strong>Personalized Strategies:</strong> Crafting tailored
                    approaches that align with your unique financial goals.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Risk Management Mastery:</strong> Equipping you with
                    the skills and foresight needed to navigate market
                    volatility.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>A Thriving Community:</strong> Joining a network
                    where shared knowledge and successes fuel our collective
                    growth.
                  </p>
                </li>
              </ul>
              <p>
                Eltaajir is more than just trading; it's about developing a
                perspective that views opportunity, risk, and success through a
                new lens. Join me on this journey to master Forex trading and
                redefine your financial destiny.
              </p>
              <div className="home-hero-btn">
                {!token && (
                  <Link
                    className="btn text-center join-free"
                    onClick={(e) => myNavigation(e, "/signup")}
                  >
                    Join for Free
                  </Link>
                )}
                <Link
                  className="btn text-center try-course"
                  onClick={(e) => myNavigation(e, "/product-service")}
                >
                  Try our Course
                </Link>
              </div>
              <div className="box-befor">
                <BgPatternLines />
              </div>
              <div className="box-after">
                <BgPatternLines />
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}
