import React from "react";

const BackBtn = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.35938 13.4997L10.2594 16.3997C10.4427 16.5831 10.5344 16.8164 10.5344 17.0997C10.5344 17.3831 10.4427 17.6164 10.2594 17.7997C10.076 17.9831 9.84271 18.0747 9.55938 18.0747C9.27604 18.0747 9.04271 17.9831 8.85938 17.7997L4.25938 13.1997C4.15938 13.0997 4.08854 12.9914 4.04688 12.8747C4.00521 12.7581 3.98438 12.6331 3.98438 12.4997C3.98438 12.3664 4.00521 12.2414 4.04688 12.1247C4.08854 12.0081 4.15938 11.8997 4.25938 11.7997L8.85938 7.19974C9.04271 7.01641 9.27604 6.92474 9.55938 6.92474C9.84271 6.92474 10.076 7.01641 10.2594 7.19974C10.4427 7.38308 10.5344 7.61641 10.5344 7.89974C10.5344 8.18308 10.4427 8.41641 10.2594 8.59974L7.35938 11.4997H20.5594C20.8427 11.4997 21.0802 11.5956 21.2719 11.7872C21.4635 11.9789 21.5594 12.2164 21.5594 12.4997C21.5594 12.7831 21.4635 13.0206 21.2719 13.2122C21.0802 13.4039 20.8427 13.4997 20.5594 13.4997H7.35938Z"
        fill="#293A66"
      />
    </svg>
  );
};

export default BackBtn;
