import React, { useEffect, useRef } from "react";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import {  useNavigate } from "react-router-dom";

function HeroSection() {
  const videoRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.onloadeddata = () => {
        videoRef.current.play();
      };
    }
  }, []);

  const myNavigation = (e, path) => {
    e.preventDefault();
    navigate(path);
  };

  return (
    <section className="hero-banner">
      <Container>
        <div className="banner-wrap">
          <Row>
            <Col className="hero-banner-left">
              <Image
                src={require("../../assets/images/hero-banner-left.png")}
                className="w-100"
              />
            </Col>

            <Col className="hero-banner-title">
              <h1
                className="mb-3"
                dangerouslySetInnerHTML={{
                  __html:
                    "Get Started your<br /><span>Learning</span>&<br />Enrich your<span>Dream</span>",
                }}
              ></h1>
              <p>
                At The Merchant - Eltaajir, we believe that knowledge is power
                and everyone deserves the opportunity to achieve financial
                success. That's why our comprehensive forex trading education
                equips you with the tools and strategies you need to navigate
                the dynamic world of forex trading with confidence. Join our
                community of successful traders today and start your journey
                towards financial freedom
              </p>
              <Button
                className="d-block mx-auto mb-3"
                onClick={(e) => myNavigation(e, "/signup")}
              >
                Get Started its free to registrer
              </Button>
            </Col>
            <div className="hero-banner-right">
              <Image
                src={require("../../assets/images/hero-banner-right.png")}
                className="w-100"
              />
              {videoRef ? (
                <div className="video-div">
                  <video
                    ref={videoRef}
                    muted
                    controls
                    controlsList="nodownload"
                    toPlay
                    playsInline
                  >
                    <source src={"https://eltaajir-general.s3.eu-central-1.amazonaws.com/about-me-final.mp4"} type="video/mp4" />
                  </video>
                </div>
              ) : (
                ""
              )}
            </div>
          </Row>
        </div>
      </Container>
    </section>
  );
}
export default HeroSection;
