import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  freeCoures: [],
  mainCource:[],
  planPricing: [],
  testimonial: [],
  bolgnotes:[],
};

export const merchantSlice = createSlice({
  name: "merchnat",
  initialState,
  reducers: {
    homeInfo: (state, action) => {
    const data = action.payload
      if (data.type === "testimonial") {
        state.testimonial = data.payload;
      } else if (data.type === "freeCoures") {
        state.freeCoures = data.payload;
      } else if (data.type === "planPricing") {
        state.planPricing = data.payload;
      } else if (data.type === "bolgNotes") {
        state.bolgnotes = data.payload;
      } else if (data.type === "mainCourses") {
        state.mainCource = data.payload;
      }
    },
  },
});

export const { homeInfo } = merchantSlice.actions;

export default merchantSlice.reducer;
