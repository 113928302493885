import { useMutation } from "@tanstack/react-query";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { supportForm } from "../api/authApi";
import TopBanner from "../components/banner/TopBanner";
import { Button, Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Loader from "../components/loader/loader";
import { Helmet } from "react-helmet";

function SupportPage() {
  const [supprotData, setSupprotData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    comment: "",
  });
  const [validation, setValidation] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    comment: "",
  });
  const [loading, setLoading] = useState(false);
  const formSubmitMutation = useMutation(supportForm, {
    onSuccess: (data) => {
      setLoading(false);
      if (data?.data) {
        setSupprotData({
          first_name: "",
          last_name: "",
          email: "",
          phone_number: "",
          comment: "",
        });
        toast.success(data?.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }
      return;
    },
    onError: (error) => {
      setLoading(false);
      if (error?.response?.data?.errors) {
        return;
      }
    },
  });

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      formSubmitMutation.mutate(supprotData);
    }
  };

  const handleChangeInput = (event) => {
    let { name, value } = event.target;

    setSupprotData({
      ...supprotData,
      [name]: value,
    });

    setValidation({
      ...validation,
      [name]: "",
    });
  };

  const validateForm = () => {
    const formKeys = Object.keys(supprotData);
    let valid = true;
    let grt_validation = {};
    for (let i = 0; i < formKeys.length; i++) {
      const c_validate = validate(formKeys[i], supprotData[formKeys[i]]);
      if (c_validate !== true) {
        valid = false;
        grt_validation[formKeys[i]] = c_validate;
      } else {
        grt_validation[formKeys[i]] = "";
      }
    }
    if (!valid) {
      setValidation(grt_validation);
    }
    return valid;
  };

  const validate = (name, value) => {
    if (name === "first_name") {
      if (!value) {
        return "Please enter first name";
      }
    } else if (name === "last_name") {
      if (!value) {
        return "Please enter last name";
      }
    } else if (name === "email") {
      // olg regex /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      var pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (value) {
        if (!pattern.test(value)) {
          return "Please enter valid email address";
        }
      } else {
        return "Please enter your email address";
      }
    }
    // else if (name === "phone_number") {
    //   if (!value) {
    //     return "Please enter mobile number";
    //   }
    // }
    else if (name === "comment") {
      if (!value) {
        return "Comment is require";
      }
    }
    return true;
  };

  return (
    <>
      <Helmet>
        <title>The Merchant - Support</title>
        <link rel="canonical" href="https://eltaajir.com/support" />
        <meta property="og:title" content="The Merchant - Support" />
      </Helmet>
      {loading && <Loader />}
      <TopBanner title="Support" />
      <section className="py-5 support-page">
        <Container>
          <div className="row">
            <div className="col-lg-12 mb-3">
              <h3>Contact Us</h3>
            </div>
          </div>
          <Form className="contact-us-form">
            <div className="support-form-bg">
              <Row>
                <Col md={6} sm={12}>
                  <Form.Group className="mb-3" controlId="formGroupEmail">
                    <Form.Label className="">
                      First name<span className="error-new">*</span>
                    </Form.Label>
                    <Form.Control
                      className="input-box"
                      type="text"
                      placeholder="Enter Your First Name"
                      name="first_name"
                      value={supprotData.first_name}
                      onChange={(e) => handleChangeInput(e)}
                    />
                    <span className="error-new">{validation.first_name}</span>
                  </Form.Group>
                </Col>

                <Col md={6} sm={12}>
                  <Form.Group className="mb-3" controlId="formGroupPassword">
                    <Form.Label className="form-new-lable">
                      Last name<span className="error-new">*</span>
                    </Form.Label>
                    <Form.Control
                      className="input-box"
                      type="text"
                      placeholder="Enter Your Last name"
                      name="last_name"
                      value={supprotData.last_name}
                      onChange={(e) => handleChangeInput(e)}
                    />
                    <span className="error-new">{validation.last_name}</span>
                  </Form.Group>
                </Col>

                <Col md={6} sm={12}>
                  <Form.Group className="mb-3" controlId="formGroupPassword">
                    <Form.Label className="form-new-lable">
                      Email<span className="error-new">*</span>
                    </Form.Label>
                    <Form.Control
                      className="input-box"
                      type="email"
                      placeholder="info@gmail.com"
                      name="email"
                      value={supprotData.email}
                      onChange={(e) => handleChangeInput(e)}
                    />
                    <span className="error-new">{validation.email}</span>
                  </Form.Group>
                </Col>

                <Col md={6} sm={12}>
                  <Form.Group className="mb-3" controlId="formGroupPassword">
                    <Form.Label className="form-new-lable">
                      Mobile number{" "}
                    </Form.Label>
                    <Form.Control
                      className="input-box"
                      type="number"
                      placeholder="+48 1234567890"
                      name="phone_number"
                      value={supprotData.phone_number}
                      onChange={(e) => handleChangeInput(e)}
                    />
                    <span className="error-new">{validation.phone_number}</span>
                  </Form.Group>
                </Col>
                <Col md={12} sm={12}>
                  <Form.Group className="mb-3" controlId="formGroupPassword">
                    <Form.Label className="form-new-lable">
                      Comment<span className="error-new">*</span>
                    </Form.Label>
                    <Form.Control
                      className="input-box"
                      as="textarea"
                      placeholder="Enter your comment"
                      name="comment"
                      value={supprotData.comment}
                      onChange={(e) => handleChangeInput(e)}
                      rows="5"
                    />
                    <span className="error-new">{validation.comment}</span>
                  </Form.Group>
                </Col>
              </Row>
            </div>
            <div className="nav-signup-btn d-flex">
              <Button className="mx-1" onClick={(e) => onSubmitHandler(e)}>
                Submit
              </Button>
            </div>
          </Form>
        </Container>
      </section>
    </>
  );
}

export default SupportPage;
