import React from "react";

const NextVidIco = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.0380859" y="0.169922" width="24" height="24" />

      <path
        d="M14.5127 12.1699L7.16271 4.81992C6.91271 4.56992 6.79187 4.27409 6.80021 3.93242C6.80854 3.59076 6.93771 3.29492 7.18771 3.04492C7.43771 2.79492 7.73354 2.66992 8.07521 2.66992C8.41687 2.66992 8.71271 2.79492 8.96271 3.04492L16.6377 10.7449C16.8377 10.9449 16.9877 11.1699 17.0877 11.4199C17.1877 11.6699 17.2377 11.9199 17.2377 12.1699C17.2377 12.4199 17.1877 12.6699 17.0877 12.9199C16.9877 13.1699 16.8377 13.3949 16.6377 13.5949L8.93771 21.2949C8.68771 21.5449 8.39604 21.6658 8.06271 21.6574C7.72937 21.6491 7.43771 21.5199 7.18771 21.2699C6.93771 21.0199 6.81271 20.7241 6.81271 20.3824C6.81271 20.0408 6.93771 19.7449 7.18771 19.4949L14.5127 12.1699Z"
        fill="#fff"
      />
    </svg>
  );
};

export default NextVidIco;
