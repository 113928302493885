import React from "react";
import TopBanner from "../components/banner/TopBanner";
import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";


function PrivacyPolicy() {
  return (
    <>
      <Helmet>
        <title>The Merchant - Privacy Policy</title>
        <link rel="canonical" href="https://eltaajir.com/privacy-policy" />
        <meta property="og:title" content="The Merchant - Privacy Policy" />        
      </Helmet>
      <TopBanner title="Privacy Policy" />
      <section className="mt-policy-sec">
        <Container>
          <div className="main-policy">
            <Row>        
              <div className="mt-policy-ctn col-lg-12 my-xl-5 my-lg-5 my-4">
                <p>
                  Thank you for choosing to be part of our community at Eltaajir
                  ("website", "we", "us", "our"). We are committed to protecting
                  your personal information and your right to privacy. If you have
                  any questions or concerns about our policy, or our practices
                  with regards to your personal information, please contact us at
                  <Link to="mailto:info@eltaajir.com"> info@eltaajir.com</Link>
                </p>
                <p>
                  When you visit our website www.eltaajir.com and use our
                  services, you trust us with your personal information. We take
                  your privacy very seriously. In this privacy notice, we describe
                  our privacy policy. We seek to explain to you in the clearest
                  way possible what information we collect, how we use it and what
                  rights you have in relation to it. We hope you take some time to
                  read through it carefully, as it is important. If there are any
                  terms in this privacy policy that you do not agree with, please
                  discontinue use of our site and services.
                </p>
                <p>
                  This privacy policy applies to all information collected through
                  our website (such as{" "}
                  <Link to="https://eltaajir.com/" target="_blank">
                    www.eltaajir.com
                  </Link>
                  ) and/or any related services, sales, marketing or events (we
                  refer to them collectively in this privacy policy as the
                  "Site").
                </p>
                <p className="mb-4">
                  <strong>
                    Please read this privacy policy carefully as it will help you
                    make informed decisions about sharing your personal
                    information with us.
                  </strong>
                </p>

                <h2>TABLE OF CONTENTS</h2>
                <ol>
                  <li className="mb-2">
                    <Link className="privacy-table-content" to="#what-information">
                      WHAT INFORMATION DO WE COLLECT?
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link className="privacy-table-content" to="#how-do-we">
                      HOW DO WE USE YOUR INFORMATION?
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link
                      className="privacy-table-content"
                      to="#will-your-information"
                    >
                      WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link className="privacy-table-content" to="#who-will-your">
                      WHO WILL YOUR INFORMATION BE SHARED WITH?
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link 
                      className="privacy-table-content"
                      to="#do-we-use-cookies"
                    >
                      DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link
                      className="privacy-table-content"
                      to="#handle-social-logins"
                    >
                      HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link className="privacy-table-content" to="#stance">
                      WHAT IS OUR STANCE ON THIRD-PARTY WEBSITE?
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link
                      className="privacy-table-content"
                      to="#keep-your-information"
                    >
                      HOW LONG DO WE KEEP YOUR INFORMATION?
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link className="privacy-table-content" to="#information-safe">
                      HOW DO WE KEEP YOUR INFORMATION SAFE?
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link
                      className="privacy-table-content"
                      to="#information-from-minors"
                    >
                      DO WE COLLECT INFORMATION FROM MINORS?
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link className="privacy-table-content" to="#privacy-rights">
                      WHAT ARE YOUR PRIVACY RIGHTS?
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link className="privacy-table-content" to="#do-not-track">
                      CONTROLS FOR DO-NOT-TRACK FEATURES
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link className="privacy-table-content" to="#update-notice">
                      DO WE MAKE UPDATES TO THIS NOTICE?
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link
                      className="privacy-table-content"
                      to="#contact-about-notice"
                    >
                      HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link className="privacy-table-content" to="#review-update">
                      HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                      FROM YOU?
                    </Link>
                  </li>
                </ol>
                <h2 className="mt-4 policy-ctn-inner-title" id="what-information">
                  1. WHAT INFORMATION DO WE COLLECT? Personal information you
                  disclose to us
                </h2>
                <p>
                  <i>
                    In Short: We collect personal information that you provide to
                    us such as name, address, contact information, passwords and
                    security data, payment information, and social media login
                    data.
                  </i>
                </p>
                <p>
                  We collect personal information that you voluntarily provide to
                  us when registering at the Site expressing an interest in
                  obtaining information about us or our products and services,
                  when participating in activities on the Site or otherwise
                  contacting us.
                </p>
                <p>
                  The personal information that we collect depends on the context
                  of your interactions with us and the Site and the choices you
                  make and the products and features you use. The personal
                  information we collect may include the following:
                </p>
                <p>
                  <strong>Name and Contact Data.</strong> We collect your first
                  and last name, email address, postal address, phone number, and
                  other similar contact data.
                </p>
                <p>
                  <strong>Credentials.</strong> We collect passwords, password
                  hints, and similar security information used for authentication
                  and account access.
                </p>
                <p>
                  <strong>Payment Data.</strong> We collect data necessary to
                  process your payment if you make purchases, such as your payment
                  instrument number (such as a credit card number), and the
                  security code associated with your payment instrument. All
                  payment data is stored by our payment processor and you should
                  review its privacy policies and contact the payment processor
                  directly to respond to your questions.
                </p>
                <p>
                  <strong>Social Media Login Data.</strong> We provide you with
                  the option to register using social media account details, like
                  your Facebook, Twitter or other social media account. If you
                  choose to register in this way, we will collect the Information
                  described in the section called "
                  <Link to="#handle-social-logins">
                    HOW DO WE HANDLE YOUR SOCIAL LOGINS
                  </Link>
                  " below.
                </p>
                <p>
                  All personal information that you provide to us must be true,
                  complete and accurate, and you must notify us of any changes to
                  such personal information.
                </p>
                <h3>Information automatically collected</h3>
                <p>
                  <i>
                    <strong>In Short:</strong> Some information — such as IP
                    address and/or browser and device characteristics — is
                    collected automatically when you visit our Site.
                  </i>
                </p>
                <p>
                  We automatically collect certain information when you visit, use
                  or navigate the Site. This information does not reveal your
                  specific identity (like your name or contact information) but
                  may include device and usage information, such as your IP
                  address, browser and device characteristics, operating system,
                  language preferences, referring URLs, device name, country,
                  location, information about how and when you use our Site and
                  other technical information. This information is primarily
                  needed to maintain the security and operation of our Site, and
                  for our internal analytics and reporting purposes.
                </p>
                <p>
                  Like many businesses, we also collect information through
                  cookies and similar technologies.
                </p>
                <h3>Information collected from other sources</h3>
                <p>
                  <i>
                    <strong>In Short:</strong> We may collect limited data from
                    public databases, marketing partners, social media platforms,
                    and other outside sources.
                  </i>
                </p>
                <p>
                  We may obtain information about you from other sources, such as
                  public databases, joint marketing partners, social media
                  platforms (such as Facebook), as well as from other third
                  parties. Examples of the information we receive from other
                  sources include: social media profile information (your name,
                  gender, birthday, email, current city, state and country, user
                  identification numbers for your contacts, profile picture URL
                  and any other information that you choose to make public);
                  marketing leads and search results and links, including paid
                  listings (such as sponsored links).
                </p>
                <h2 className="mt-4 policy-ctn-inner-title" id="how-do-we">
                  2. HOW DO WE USE YOUR INFORMATION?{" "}
                </h2>
                <p>
                  <i>
                    <strong>In Short:</strong> We process your information for
                    purposes based on legitimate business interests, the
                    fulfillment of our contract with you, compliance with our
                    legal obligations, and/or your consent.
                  </i>
                </p>
                <p>
                  We use personal information collected via our Site for a variety
                  of business purposes described below. We process your personal
                  information for these purposes in reliance on our legitimate
                  business interests ("Business Purposes"), in order to enter into
                  or perform a contract with you ("Contractual"), with your
                  consent ("Consent"), and/or for compliance with our legal
                  obligations ("Legal Reasons"). We indicate the specific
                  processing grounds we rely on next to each purpose listed below.
                </p>
                <p>We use the information we collect or receive: </p>
                <p>
                  <strong>
                    To facilitate account creation and logon process.
                  </strong>{" "}
                  If you choose to link your account with us to a third party
                  account (such as your Google or Facebook account), we use the
                  information you allowed us to collect from those third parties
                  to facilitate account creation and logon process. See the
                  section below headed "
                  <Link to="#handle-social-logins">
                    HOW DO WE HANDLE YOUR SOCIAL LOGINS
                  </Link>
                  " for further information.
                </p>
                <p>
                  <strong>
                    To send you marketing and promotional communications.
                  </strong>
                  We and/or our third party marketing partners may use the
                  personal information you send to us for our marketing purposes,
                  if this is in accordance with your marketing preferences. You
                  can opt-out of our marketing emails at any time (see the ""
                  <Link to="#privacy-rights">WHAT ARE YOUR PRIVACY RIGHTS</Link>
                  below).
                </p>
                <p>
                  <strong>To send administrative information to you.</strong> We
                  may use your personal information to send you product, service
                  and new feature information and/or information about changes to
                  our terms, conditions, and policies.
                </p>
                <p>
                  <strong>Fulfill and manage your orders.</strong> We may use your
                  information to fulfill and manage your orders, payments,
                  returns, and exchanges made through the Site.
                </p>
                <p>
                  <strong>To post testimonials.</strong> We post testimonials on
                  our Site that may contain personal information. Prior to posting
                  a testimonial, we will obtain your consent to use your name and
                  testimonial. If you wish to update, or delete your testimonial,
                  please contact us at
                  <Link to="mailto:info@eltaajir.com">info@eltaajir.com</Link> and be
                  sure to include your name, testimonial location, and contact
                  information.
                </p>
                <p>
                  <strong>Deliver targeted advertising to you.</strong> We may use
                  your information to develop and display content and advertising
                  (and work with third parties who do so) tailored to your
                  interests and/or location and to measure its effectiveness.
                </p>
                <p>
                  <strong>Administer prize draws and competitions.</strong> We may
                  use your information to administer prize draws and competitions
                  when you elect to participate in our competitions.
                </p>
                <p>
                  <strong>Request Feedback.</strong> We may use your information
                  to request feedback and to contact you about your use of our
                  Site.
                </p>
                <p>
                  <strong>To protect our Site.</strong> We may use your
                  information as part of our efforts to keep our Site safe and
                  secure (for example, for fraud monitoring and prevention).
                </p>
                <p>
                  <strong>To enable user-to-user communications.</strong> We may
                  use your information in order to enable user-to-user
                  communications with each user's consent.
                </p>
                <p>
                  <strong>To enforce our terms, conditions and policies.</strong>
                </p>
                <p>
                  <strong>To respond to legal requests and prevent harm.</strong>{" "}
                  If we receive a subpoena or other legal
                </p>
                <p>
                  request, we may need to inspect the data we hold to determine
                  how to respond.
                </p>
                <p>
                  <strong>For other Business Purposes.</strong> We may use your
                  information for other Business Purposes, such as data analysis,
                  identifying usage trends, determining the effectiveness of our
                  promotional campaigns and to evaluate and improve our Site,
                  products, services, marketing and your experience.
                </p>
                <h2 className="mt-4 policy-ctn-inner-title" id="will-your-information">
                  3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                </h2>
                <p>
                  <strong>In Short:</strong> We only share information with your
                  consent, to comply with laws, to provide you with services, to
                  protect your rights, or to fulfill business obligations.
                </p>
                <p>
                  We may process or share your data that we hold based on the
                  following legal basis:
                </p>
                <p>
                  <strong>Consent:</strong> We may process your data if you have
                  given us specific consent to use your personal information for a
                  specific purpose.
                </p>
                <p>
                  <strong>Legitimate Interests:</strong> We may process your data
                  when it is reasonably necessary to achieve our legitimate
                  business interests.
                </p>
                <p>
                  <strong>Performance of a Contract:</strong> Where we have
                  entered into a contract with you, we may process your personal
                  information to fulfill the terms of our contract.
                </p>
                <p>
                  <strong>Legal Obligations:</strong> We may disclose your
                  information where we are legally required to do so in order to
                  comply with applicable law, governmental requests, a judicial
                  proceeding, court order, or legal process, such as in response
                  to a court order or a subpoena (including in response to public
                  authorities to meet national security or law enforcement
                  requirements).
                </p>
                <p>
                  <strong>Vital Interests:</strong> We may disclose your
                  information where we believe it is necessary to investigate,
                  prevent, or take action regarding potential violations of our
                  policies, suspected fraud, situations involving potential
                  threats to the safety of any person and illegal activities, or
                  as evidence in litigation in which we are involved.
                </p>
                <p>
                  More specifically, we may need to process your data or share
                  your personal information in the following situations:
                </p>
                <p>
                  <strong>Business Transfers.</strong> We may share or transfer
                  your information in connection with, or during negotiations of,
                  any merger, sale of company assets, financing, or acquisition of
                  all or a portion of our business to another company.
                </p>
                <p>
                  <strong>
                    Vendors, Consultants and Other Third-Party Service Providers.
                  </strong>
                  We may share your data with third party vendors, service
                  providers, contractors or agents who perform services for us or
                  on our behalf and require access to such information to do that
                  work. Examples include: payment processing, data analysis, email
                  delivery, hosting services, customer service and marketing
                  efforts. We may allow selected third parties to use tracking
                  technology on the Site, which will enable them to collect data
                  about how you interact with the Site over time. This information
                  may be used to, among other things, analyze and track data,
                  determine the popularity of certain content and better
                  understand online activity. Unless described in this Policy, we
                  do not share, sell, rent or trade any of your information with
                  third parties for their promotional purposes.
                </p>
                <p>
                  <strong>Third-Party Advertisers.</strong> We may use third-party
                  advertising companies to serve ads when you visit the Site.
                  These companies may use information about your visits to our
                  Website(s) and other website that are contained in web cookies
                  and other tracking technologies in order to provide
                  advertisements about goods and services of interest to you.
                </p>
                <p>
                  <strong>Affiliates.</strong> We may share your information with
                  our affiliates, in which case we will require those affiliates
                  to honor this privacy policy. Affiliates include our parent
                  company and any subsidiaries, joint venture partners or other
                  companies that we control or that are under common control with
                  us.
                </p>
                <h2 className="mt-4 policy-ctn-inner-title" id="who-will-your">
                  4. WHO WILL YOUR INFORMATION BE SHARED WITH?{" "}
                </h2>
                <p>
                  <i>
                    <strong>In Short:</strong> We only share information with the
                    following third parties.
                  </i>
                </p>
                <p>
                  We only share and disclose your information with the following
                  third parties. We have categorised each party so that you may be
                  easily understand the purpose of our data collection and
                  processing practices. If we have processed your data based on
                  your consent and you wish to revoke your consent, please contact
                  us.{" "}
                </p>
                <p>
                  <strong>
                    Advertising, Direct Marketing, and Lead Generation
                  </strong>{" "}
                </p>
                <p>Google AdSense </p>
                <p>
                  <strong>Content Optimisation </strong>
                </p>
                <p>
                  Google Site Search, YouTube video embed, Vimeo video, Instagram
                  embed, MailChimp widget plugin and Google Fonts{" "}
                </p>
                <p>
                  <strong>Functionality and Infrastructure Optimisation </strong>
                </p>
                <p>Hostinger </p>
                <p>
                  <strong>Invoice and Billing </strong>
                </p>
                <p>PayPal</p>
                <p>
                  <strong>Retargeting Platforms </strong>
                </p>
                <p>
                  Facebook Custom Audience, Facebook Remarketing, Google Analytics
                  Remarketing, Google Ads Remarketing , Twitter Remarketing and
                  Twitter Tailored Audiences{" "}
                </p>
                <p>
                  <strong>Social Media Sharing and Advertising </strong>
                </p>
                <p>
                  Coinbase social plugins, Facebook advertising, Facebook social
                  plugins, Instagram advertising, LinkedIn advertising, Twitter
                  advertising, Twitter social plugins, TikTok and YouTube social
                  plugins{" "}
                </p>
                <p>
                  <strong>User Account Registration and Authentication</strong>{" "}
                </p>
                <p>Node.js</p>
                <p>
                  <strong>Web and Mobile Analytics </strong>
                </p>
                <p>
                  Facebook Ads conversion tracking, Facebook Analytics, Google
                  Ads, Google Analytics, Google Tag Manager and Twitter Ads
                  conversion tracking{" "}
                </p>
                <p>
                  <strong>Website Hosting </strong>
                </p>
                <p>Hostinger</p>
                <p>
                  <strong>Website Testing </strong>
                </p>
                <p>Hostinger</p>
                <h2 className="mt-4 policy-ctn-inner-title" id="do-we-use-cookies">
                  5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                </h2>
                <p>
                  <i>
                    <strong>In Short: </strong>We may use cookies and other
                    tracking technologies to collect and store your information.{" "}
                  </i>
                </p>
                <p>
                  We may use cookies and similar tracking technologies (like web
                  beacons and pixels) to access or store information. Specific
                  information about how we use such technologies and how you can
                  refuse certain cookies is set out in our Cookie Policy.
                </p>
                <h2 className="mt-4 policy-ctn-inner-title" id="handle-social-logins">
                  6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                </h2>
                <p>
                  <i>
                    <strong>In Short:</strong> If you choose to register or log in
                    to our website using a social media account, we may have
                    access to certain information about you.
                  </i>
                </p>
                <p>
                  Our Site offers you the ability to register and login using your
                  third party social media account details (like your Facebook or
                  Twitter logins). Where you choose to do this, we will receive
                  certain profile information about you from your social media
                  provider. The profile Information we receive may vary depending
                  on the social media provider concerned, but will often include
                  your name, e-mail address, friends list, profile picture as well
                  as other information you choose to make public.{" "}
                </p>
                <p>
                  We will use the information we receive only for the purposes
                  that are described in this privacy policy or that are otherwise
                  made clear to you on the Site. Please note that we do not
                  control, and are not responsible for, other uses of your
                  personal information by your third party social media provider.
                  We recommend that you review their privacy policy to understand
                  how they collect, use and share your personal information, and
                  how you can set your privacy preferences on their site and apps.{" "}
                </p>
                <h2 className="mt-4 policy-ctn-inner-title" id="stance">
                  7. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITE?
                </h2>
                <p>
                  <i>
                    <strong>In Short:</strong> We are not responsible for the
                    safety of any information that you share with third-party
                    providers who advertise, but are not affiliated with, our
                    website.{" "}
                  </i>
                </p>
                <p>
                  The Site may contain advertisements from third parties that are
                  not affiliated with us and which may link to other website,
                  online services or mobile applications. We cannot guarantee the
                  safety and privacy of data you provide to any third parties. Any
                  data collected by third parties is not covered by this privacy
                  policy. We are not responsible for the content or privacy and
                  security practices and policies of any third parties, including
                  other website, services or applications that may be linked to or
                  from the Site. You should review the policies of such third
                  parties and contact them directly to respond to your questions.
                </p>
                <h2 className="mt-4 policy-ctn-inner-title" id="keep-your-information">
                  8. HOW LONG DO WE KEEP YOUR INFORMATION?
                </h2>
                <p>
                  <i>
                    <strong>
                      In Short: We keep your information for as long as necessary
                      to fulfill the purposes outlined in this privacy policy
                      unless otherwise required by law.
                    </strong>
                  </i>
                </p>
                <p>
                  We will only keep your personal information for as long as it is
                  necessary for the purposes set out in this privacy policy,
                  unless a longer retention period is required or permitted by law
                  (such as tax, accounting or other legal requirements). No
                  purpose in this policy will require us keeping your personal
                  information for longer than 2 years past the termination of the
                  user's account.
                </p>
                <p>
                  When we have no ongoing legitimate business need to process your
                  personal information, we will either delete or anonymize it, or,
                  if this is not possible (for example, because your personal
                  information has been stored in backup archives), then we will
                  securely store your personal information and isolate it from any
                  further processing until deletion is possible.{" "}
                </p>
                <h2 className="mt-4 policy-ctn-inner-title" id="information-safe">
                  9. HOW DO WE KEEP YOUR INFORMATION SAFE?
                </h2>
                <p>
                  In Short: We aim to protect your personal information through a
                  system of organisational and technical security measures.{" "}
                </p>
                <p>
                  We have implemented appropriate technical and organisational
                  security measures designed to protect the security of any
                  personal information we process. However, please also remember
                  that we cannot guarantee that the internet itself is 100%
                  secure. Although we will do our best to protect your personal
                  information, transmission of personal information to and from
                  our Site is at your own risk. You should only access the
                  services within a secure environment.{" "}
                </p>
                <h2 className="mt-4 policy-ctn-inner-title" id="information-from-minors">
                  10. DO WE COLLECT INFORMATION FROM MINORS?
                </h2>
                <p>
                  <i>
                    <strong>In Short:</strong> We do not knowingly collect data
                    from or market to children under 18 years of age.
                  </i>
                </p>
                <p>
                  We do not knowingly solicit data from or market to children
                  under 18 years of age. By using the Site, you represent that you
                  are at least 18 or that you are the parent or guardian of such a
                  minor and consent to such minor dependent’s use of the Site. If
                  we learn that personal information from users less than 18 years
                  of age has been collected, we will deactivate the account and
                  take reasonable measures to promptly delete such data from our
                  records. If you become aware of any data we may have collected
                  from children under age 18, please contact us at
                  info@eltaajir.com.{" "}
                </p>
                <h2 className="mt-4 policy-ctn-inner-title" id="privacy-rights">
                  11. WHAT ARE YOUR PRIVACY RIGHTS?
                </h2>
                <p>
                  <i>
                    <strong>In Short:</strong> In some regions, such as the
                    European Economic Area, you have rights that allow you greater
                    access to and control over your personal information. You may
                    review, change, or terminate your account at any time.{" "}
                  </i>
                </p>
                <p>
                  In some regions (like the European Economic Area), you have
                  certain rights under applicable data protection laws. These may
                  include the right (i) to request access and obtain a copy of
                  your personal information, (ii) to request rectification or
                  erasure; (iii) to restrict the processing of your personal
                  information; and (iv) if applicable, to data portability. In
                  certain circumstances, you may also have the right to object to
                  the processing of your personal information. To make such a
                  request, please use the contact details provided below. We will
                  consider and act upon any request in accordance with applicable
                  data protection laws.{" "}
                </p>
                <p>
                  If we are relying on your consent to process your personal
                  information, you have the right to withdraw your consent at any
                  time. Please note however that this will not affect the
                  lawfulness of the processing before its withdrawal.{" "}
                </p>

                <p>
                  your personal information, you also have the right to complain
                  to your local data protection supervisory authority. You can
                  find their contact details here:{" "}
                  <Link
                    to="http://ec.europa.eu/justice/data-
                                  protection/bodies/authorities/index_en.htm"
                  >
                    http://ec.europa.eu/justice/data-
                    protection/bodies/authorities/index_en.htm
                  </Link>
                  .
                </p>
                <h3>Account Information</h3>
                <p>
                  If you would at any time like to review or change the
                  information in your account or terminate your account, you can:{" "}
                </p>
                <p>
                  Log into your account settings and update your user account.
                  Contact us using the contact information provided.{" "}
                </p>
                <p>
                  Upon your request to terminate your account, we will deactivate
                  or delete your account and information from our active
                  databases. However, some information my be retained in our files
                  to prevent fraud, troubleshoot problems, assist with any
                  investigations, enforce our Terms of Use and/or comply with
                  legal requirements.{" "}
                </p>
                <p>
                  <strong>Cookies and similar technologies:</strong> Most Web
                  browsers are set to accept cookies by default. If you prefer,
                  you can usually choose to set your browser to remove cookies and
                  to reject cookies. If you choose to remove cookies or reject
                  cookies, this could affect certain features or services of our
                  Site. To opt-out of interest-based advertising by advertisers on
                  our Site please contact us.
                </p>
                <p>
                  <strong>Opting out of email marketing:</strong> You can
                  unsubscribe from our marketing email list at any time by
                  clicking on the unsubscribe link in the emails that we send or
                  by contacting us using the details provided below. You will then
                  be removed from the marketing email list — however, we will
                  still need to send you service-related emails that are necessary
                  for the administration and use of your account. To otherwise
                  opt-out, you may:{" "}
                </p>
                <p>
                  Access your account settings and update preferences. Contact us
                  using the contact information provided. Note your preferences
                  when you register an account with the site.{" "}
                </p>
                <h2 className="mt-4 policy-ctn-inner-title" id="do-not-track">
                  12. CONTROLS FOR DO-NOT-TRACK FEATURES
                </h2>
                <p>
                  Most web browsers and some mobile operating systems and mobile
                  applications include a Do-Not- Track ("DNT") feature or setting
                  you can activate to signal your privacy preference not to have
                  data about your online browsing activities monitored and
                  collected. No uniform technology standard for recognizing and
                  implementing DNT signals has been finalized. As such, we do not
                  currently respond to DNT browser signals or any other mechanism
                  that automatically communicates your choice not to be tracked
                  online. If a standard for online tracking is adopted that we
                  must follow in the future, we will inform you about that
                  practice in a revised version of this Privacy Policy.{" "}
                </p>
                <h2 className="mt-4 policy-ctn-inner-title" id="update-notice">
                  13. DO WE MAKE UPDATES TO THIS POLICY?
                </h2>
                <p>
                  <i>
                    <strong>In Short:</strong> Yes, we will update this policy as
                    necessary to stay compliant with relevant laws.
                  </i>
                </p>
                <p>
                  We may update this privacy policy from time to time. The updated
                  version will be indicated by an updated "Revised" date and the
                  updated version will be effective as soon as it is accessible.
                  If we make material changes to this privacy policy, we may
                  notify you either by prominently posting a notice of such
                  changes or by directly sending you a notification. We encourage
                  you to review this privacy policy frequently to be informed of
                  how we are protecting your information.{" "}
                </p>
                <h2 className="mt-4 policy-ctn-inner-title" id="contact-about-notice">
                  14. HOW CAN YOU CONTACT US ABOUT THIS POLICY?{" "}
                </h2>
                <p>
                  If you have questions or comments about this policy, you may
                  email us at{" "}
                  <Link to="mailto:info@eltaajir.com">info@eltaajir.com</Link>
                </p>
                <h2 className="mt-4 policy-ctn-inner-title" id="review-update">
                  15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                  FROM YOU?
                </h2>
                <p>
                  Based on the applicable laws of your country, you may have the
                  right to request access to the personal information we collect
                  from you, change that information, or delete it in some
                  circumstances. To request to review, update, or delete your
                  personal information, please visit:
                  <Link to="https://eltaajir.com/">www.eltaajir.com</Link> then use
                  form of contact us or please email us on{" "}
                  <Link to="mailto:info@eltaajir.com">info@eltaajir.com</Link> We will
                  respond to your request within 30 days.
                </p>
              </div>
            </Row>            
          </div>
        </Container>
      </section>
    </>
  );
}

export default PrivacyPolicy;
