import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/merchant-logo.svg";
import { useMutation } from "@tanstack/react-query";
import { getRealTimeData } from "../../api/purchase";
import { Image } from "react-bootstrap";

export default function Notification(props) {
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState(false);
  const [logdata, setLogData] = useState([]);
  const [displayData, setDisplayData] = useState({});
  const [count, setCount] = useState(0);

  useEffect(() => {
    getRealTimeDataApi.mutate();

    setTimeout(() => {
      setCount((ct) => ct + 1);
      const displayInterval = setInterval(() => {
        setCount((ct) => ct + 1);
      }, 30000);

      return () => {
        window.clearInterval(displayInterval);
      };
    }, 5000);
  }, []);

  useEffect(() => {
    if (logdata.length > 0 && count > 0) {
      const random = Math.floor(Math.random() * logdata.length);
      setDisplayData(logdata[random]);
      setShow(true);
      setTimeout(() => {
        setShow((val) => !val);
      }, 15000);
    }
  }, [count]);

  const getRealTimeDataApi = useMutation(getRealTimeData, {
    onSuccess: (data) => {
      if (data?.data) {
        setLogData(data?.data?.data);
      }
      return;
    },
    onError: (error) => {
      if (error?.response?.data?.errors) {
        setErrors(error?.response.data.errors);
        return;
      }
    },
  });

  return (
    <>
      {show && (
        <div className="notification-wrapper d-flex justify-content-center align-items-center">
          <div className="notification-logo">
            <Image
              src={Logo}
              className="img-fluid"
              width="48"
              alt="notify logo"
            />
          </div>
          <div className="notification-content">
            <p className="mb-0">
              Someone {displayData.city || displayData.country ? "from" : ""}{" "}
              {displayData.city && displayData.city}{" "}
              {displayData.country ? displayData.country : ""} has purchased the{" "}
              <strong className="text-dark">{displayData?.package}</strong>
            </p>
            <span>
              {displayData?.days > 0
                ? `${displayData?.days} ${
                    displayData?.days > 1 ? "Days" : "Day"
                  }`
                : `${displayData?.hours} ${
                    displayData?.hours > 1 ? "Hours" : "Hour"
                  }`}{" "}
              ago
            </span>
          </div>
        </div>
      )}
    </>
  );
}
