import React from "react";

export const PlayBtn = () => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5349 10.223C20.155 10.5683 20.155 11.4317 19.5349 11.777L1.39535 21.8785C0.775192 22.2238 -3.13014e-08 21.7921 0 21.1014L9.15567e-07 0.898577C9.46868e-07 0.207882 0.775195 -0.223802 1.39535 0.121546L19.5349 10.223Z"
        fill="white"
      />
    </svg>
  );
};
