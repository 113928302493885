import React, { useEffect, useState } from "react";
import {
  Col,
  Image,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  CloseButton,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import userIcon from "../../assets/images/defaultUser.png";
import { backend_url } from "../../config/constants";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import {
  handlePlanPayments,
  handleNextPlanPayments,
} from "../../reducers/paymentSlice";
import {
  getPrevPaymentDetails,
  getUpcomingPaymentDetails,
  getCancelPaymentDetails,
} from "../../api/paymentDetailsApi";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import Fetcher from "../../library/Fetcher";
import { handlePlan } from "../../reducers/userSlice";
import { Helmet } from "react-helmet";

export default function CurrentPlan() {
  const dispatch = useDispatch();
  const { plan_payments, upcoming_payments } = useSelector(
    (state) => state.payments
  );
  const planPricing = useSelector((state) => state.merchant.planPricing) || [];
  const { plans, user } = useSelector((state) => state.authUser);
  const [openCancelPlanModal, setOpenCancelPlanModal] = useState(false);
  const [openPlanDetails, setOpenPlanDetails] = useState(false);
  const [planState, setPlanState] = useState(null);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [cancelPlan, setCancelPlan] = useState(null);

  useEffect(() => {
    if (plan_payments.length === 0) {
      getPrevPaymentDetailsApi.mutate();
    }
  }, []);

  useEffect(() => {
    if (planPricing.length && user && !planState) {
      const details = {};
      for (let i = 0; i < planPricing.length; i++) {
        details[`plan_${planPricing[i].id}`] = planPricing[i];
      }
      setPlanState(details);
    }
  }, [planPricing, user]);

  useEffect(() => {
    if (upcoming_payments.length === 0 && plans.length) {
      handleUpcomintPayment();
    }
  }, [plans.length]);

  const handleUpcomintPayment = () => {
    const promise_arr = [];
    for (let index = 0; index < plans.length; index++) {
      const data = {
        package_id: plans[index].package_id,
        type: plans[index].checkout?.plan_id,
      };
      promise_arr.push(
        getUpcomingPaymentDetails(data).then((res) => {
          if (res.data.success) {
            return {
              package_id: plans[index].package_id,
              date: res.data.data,
              payment_method: res.data.payment_method,
            };
          } else {
            return false;
          }
        })
      );
    }
    Promise.all(promise_arr).then((res) =>
      dispatch(
        handleNextPlanPayments(res.filter((upc) => (upc ? true : false)))
      )
    );
  };

  const getPrevPaymentDetailsApi = useMutation(getPrevPaymentDetails, {
    onSuccess: (data) => {
      dispatch(handlePlanPayments(data?.data));
      return;
    },
    onError: (error) => {
      console.log(error?.response.data.errors);
      return;
    },
  });

  const getCancelPaymentDetailsApi = useMutation(getCancelPaymentDetails, {
    onSuccess: (data) => {
      toast.success(data?.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
      handleUpcomintPayment();
      getProfile.mutate();
      return;
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleCancelPlan = (data) => {
    setCancelPlan(planState[`plan_${data?.package_id}`]);
    setOpenCancelPlanModal(true);
  };

  const handleCancelPlanFinal = () => {
    const paymentData = {
      package_id: cancelPlan["id"],
      type: cancelPlan["type"],
    };
    getCancelPaymentDetailsApi.mutate(paymentData);
    setOpenCancelPlanModal(false);
  };

  const getProfile = useMutation(() => Fetcher.get(`/getUserData`), {
    onSuccess: (data) => {
      if (data) {
        const { plan, services } = data.data;
        dispatch(handlePlan([...plan, ...services]));
      }
      return;
    },
    onError: (error) => {
      console.log(error, "error");
    },
  });

  const handleViewDetails = (pckg) => {
    setOpenPlanDetails(true);
    let pckgcheckDetails = pckg["checkout"];
    let pckgDetails = planState[`plan_${pckg.package_id}`];
    const details = {
      name: pckgDetails["package_name"],
      description: pckgDetails["description"],
    };

    if (pckgcheckDetails) {
      details["price"] = pckgcheckDetails?.package_price;
    } else {
      details["price"] = pckgDetails?.amount * pckgDetails?.duration_period;
    }

    setCurrentPlan(details);
  };

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Hesham Elsaid - Current trader" />
        <link rel="canonical" href="https://eltaajir.com/about-me" />
      </Helmet>
      <Row>
        <Col xl={8}>
          <div className="whiteCommen-box courses-list">
            <h3 className="pkg-details">Current Plan</h3>
            <div className="myplan-courses-wrapper table-responsive">
              <table className="table table-borderless myplan-courses-ctn">
                <thead>
                  <tr>
                    <th>
                      <h5>Description</h5>
                    </th>
                    <th>
                      <h5>Price</h5>
                    </th>
                    <th>
                      <h5>Language</h5>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                {plans.length ? (
                  <tbody>
                    {plans.map((pld, index) => {
                      const planDetails = planState
                        ? planState[`plan_${pld.package_id}`]
                        : null;
                      return (
                        <tr key={index}>
                          <td>
                            <h5>
                              {planDetails ? planDetails.package_name : "NA"}
                            </h5>
                          </td>
                          <td>
                            <span>
                              {pld["checkout"]
                                ? `${pld["checkout"].package_price} €`
                                : "NA"}
                            </span>
                          </td>
                          <td>
                            <span>
                              {pld.plan_lang === "ar" ? "Arabic" : "English"}
                            </span>
                          </td>
                          <td>
                            <span
                              className="see-more"
                              onClick={() => handleViewDetails(pld)}
                            >
                              View details
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td className="no-course" colSpan={4} align="center">
                        No Active Plan
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>

          <div className="whiteCommen-box courses-list">
            <h3 className="pkg-details d-flex align-items-center justify-content-between">
              Previous Payment
            </h3>
            <div className="table-responsive ">
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th>
                      <h5>Description</h5>
                    </th>
                    <th>
                      <h5>Payment Method</h5>
                    </th>
                    <th>
                      <h5>Date</h5>
                    </th>
                    <th>
                      <h5>Price</h5>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {plan_payments &&
                  plan_payments?.previousPayments?.length > 0 ? (
                    plan_payments?.previousPayments.map((ppd, index) => {
                      const planDetails = planState
                        ? planState[`plan_${ppd.package_id}`]
                        : null;
                      return (
                        <tr key={index}>
                          <td>
                            <h5>
                              {planDetails ? planDetails["package_name"] : "NA"}
                            </h5>
                          </td>
                          <td>
                            <span className="active">
                              {ppd.paypal_payment_id
                                ? "Paypal"
                                : "Credit / Debit"}
                            </span>
                          </td>
                          <td>
                            {!!ppd.created_at ? (
                              <Moment format="DD/MM/YYYY">
                                {ppd.created_at}
                              </Moment>
                            ) : (
                              "NA"
                            )}
                          </td>
                          <td>{ppd.price ? `${ppd.price} €` : "NA"}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={4} align="center" className="no-course">
                        No previous payments
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          {upcoming_payments.length > 0 && (
            <div className="whiteCommen-box courses-list next-payment">
              <h3 className="pkg-details">Next Payment Due Date</h3>
              <div className="table-responsive ">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>Description</th>
                      <th>Payment Method</th>
                      <th>Date</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {upcoming_payments.map((nextPlanData, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <h5>
                              {planState
                                ? planState[`plan_${nextPlanData.package_id}`][
                                    "package_name"
                                  ]
                                : ""}
                            </h5>
                          </td>
                          <td>
                            <span className="active">
                              {nextPlanData?.payment_method == 1
                                ? "Paypal"
                                : "Credit / Debit"}
                            </span>
                          </td>
                          <td>
                            <Moment format="DD/MM/YYYY">
                              {nextPlanData?.date}
                            </Moment>
                          </td>

                          {nextPlanData?.payment_method != 3 ? (
                            <td>
                              <Button
                                style={{
                                  background: "#f81111",
                                  border: "none",
                                }}
                                onClick={() => {
                                  handleCancelPlan(nextPlanData);
                                }}
                              >
                                Cancel Plan
                              </Button>
                            </td>
                          ) : (
                            <td></td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </Col>
        <Col xl={4}>
          <div className="myplan-right">
            <div className="whiteCommen-box">
              <h3 className="pkg-details">User Details</h3>
              <div className="userMain-wrapper">
                <div className="user-details-wrapper d-flex flex-wrap align-items-center mb-3">
                  <div className="user-details-box">
                    <Image
                      src={
                        user?.image ? `${user?.image}` : userIcon
                      }
                      className="card-icon"
                    />
                  </div>
                  <div className="user-details-ctn">
                    <p>{user?.first_name + " " + user?.last_name}</p>
                  </div>
                </div>
                <div className="user-email-wrapper d-flex flex-wrap align-items-center">
                  <label className="user-email"></label>
                  <Link to={"mailto:themerchant@gmail.com"}>{user?.email}</Link>
                </div>
              </div>
            </div>
            <button type="button" className="btn payment-btn d-none">
              Payment method
            </button>
          </div>
        </Col>
      </Row>

      <Modal show={openCancelPlanModal} className="cancel-modal">
        <ModalBody>
          <p>
            We're sorry to see you go and sincerely hope to welcome you back in
            the future. By confirming your decision to cancel your current plan,
            please be aware that you will lose access to all the benefits until
            the end of your current payment cycle.
          </p>
          <Button
            onClick={() => {
              handleCancelPlanFinal(false);
            }}
          >
            Cancel Plan
          </Button>
        </ModalBody>
        <CloseButton
          className="close-btn"
          onClick={() => setOpenCancelPlanModal(false)}
        ></CloseButton>
      </Modal>

      {currentPlan && (
        <Modal show={openPlanDetails} className="plan-details-modal">
          <>
            <ModalHeader>
              <h5>{currentPlan.name}</h5>
              <div className="price-intro">
                <p>&euro; {currentPlan?.price}</p>
              </div>
            </ModalHeader>
            <ModalBody>
              <h3>Plan Description</h3>
              {currentPlan.description ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: currentPlan.description,
                  }}
                ></div>
              ) : (
                <div>No Description Available</div>
              )}
            </ModalBody>
            <CloseButton
              className="close-btn"
              onClick={() => setOpenPlanDetails(false)}
            ></CloseButton>
          </>
        </Modal>
      )}
    </>
  );
}
