import React from "react";

export const Upgrade = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="upgrade">
        <rect id="Bounding box" width="24" height="24" fill="none" />

        <g mask="url(#mask0_114_1105)">
          <path
            id="upgrade_2"
            d="M7 20V18H17V20H7ZM11 16V7.825L8.4 10.4L7 9L12 4L17 9L15.6 10.4L13 7.825V16H11Z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  );
};
