import React from "react";
import Container from "react-bootstrap/Container";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function TopBanner(props) {
  const navigate = useNavigate();
  const user = useSelector((state) => state.authUser).user;

  const handleNavigate = (event, path) => {
    event.preventDefault();
    navigate(path);
  };

  const url = window.location.href.slice(
    window.location.href.lastIndexOf("/"),
    window.location.href.length
  );

  return (
    <section className="commenTop-banner-section paddingY-50 position-relative">
      <Container>
        <div
          className={`topBanner-wrapper text-center w-100${
            url === "/product-service" ? " pns" : ""
          }`}
        >
          {url === "/profile" ? (
            <h1>
              {user && "Welcome, " + user?.first_name + " " + user?.last_name}
            </h1>
          ) : (
            <h1>{props.title}</h1>
          )}
          <Breadcrumb className="innerPgae-breadcrumb d-flex justify-content-center w-100">
            <Breadcrumb.Item onClick={(e) => handleNavigate(e, "/")}>
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{props.title}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </Container>
    </section>
  );
}
