import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  my_lesson: [],
  courses: [],
  course_lesson: [],
  eBook: [],
};

export const courseLessonSlice = createSlice({
  name: "course_lesson",
  initialState,
  reducers: {
    handleCourseLesson: (state, action) => {
      state.my_lesson = action.payload;
    },
    handleAllCourseLesson: (state, action) => {
      state.course_lesson = action.payload;
    },
    handleGetAllCourse: (state, action) => {
      state.courses = action.payload;
    },
    handleEBook: (state, action) => {
      state.eBook = action.payload;
    }
  },
});

export const { handleCourseLesson, handleAllCourseLesson, handleGetAllCourse, handleEBook } =
  courseLessonSlice.actions;

export default courseLessonSlice.reducer;
