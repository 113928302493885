import React, { useState } from "react";
import YouTube from "react-youtube";
import { PlayBtn } from "../../assets/svg/PlayBtn";

function BlogCode({ blog }) {
  const [showVideo, setShowVideo] = useState(false);

  const opts = {
    width: "853",
    height: "480",
    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <div className="blog-desc-wrap">
      <div className="single-blog-title">
        <h2>{blog?.blog_title}</h2>
      </div>

      {blog?.youtube_link && (
        <div className="blog-youtube-video">
          {showVideo ? (
            <YouTube
              videoId={blog?.youtube_link}
              opts={opts}
              iframeClassName="yt-frame"
              className="yt-wrap"
            />
          ) : (
            <div
              className="blog-youtube-video-inner"
              onClick={() => setShowVideo(true)}
            >
              <img
                src={blog?.blog_image}
                alt="Custom Thumbnail"
                style={{ cursor: "pointer" }}
              />
              <div className="play-btn">
                <PlayBtn />
              </div>
            </div>
          )}
        </div>
      )}
      {!blog[0]?.youtube_link && (
        <div className="single-blog-img">
          <img src={blog?.blog_image} alt="" className="w-100" />
        </div>
      )}

      <div
        className="single-blog-desc"
        dangerouslySetInnerHTML={{
          __html: blog?.description,
        }}
      />
    </div>
  );
}

export default BlogCode;
