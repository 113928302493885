import React from "react";

export const EyeSlash = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.7179 22.476C30.1199 19.44 31.9999 16 31.9999 16C31.9999 16 25.9999 5 15.9999 5C14.0791 5.00661 12.18 5.40685 10.4199 6.176L11.9599 7.718C13.2548 7.24631 14.6218 7.00336 15.9999 7C20.2399 7 23.7579 9.336 26.3359 11.914C27.5766 13.1614 28.6889 14.5303 29.6559 16C29.5399 16.174 29.4119 16.366 29.2659 16.576C28.5959 17.536 27.6059 18.816 26.3359 20.086C26.0059 20.416 25.6619 20.742 25.3019 21.058L26.7179 22.476Z"
        fill="#293A66"
      />
      <path
        d="M22.5942 18.3517C23.0405 17.1034 23.1232 15.754 22.8326 14.4606C22.5419 13.1671 21.89 11.9828 20.9526 11.0454C20.0152 10.108 18.8308 9.45601 17.5374 9.16539C16.2439 8.87477 14.8945 8.95742 13.6462 9.40373L15.2922 11.0497C16.0608 10.9397 16.8445 11.0102 17.5812 11.2557C18.3178 11.5011 18.9872 11.9147 19.5362 12.4638C20.0852 13.0128 20.4989 13.6821 20.7443 14.4188C20.9897 15.1554 21.0602 15.9391 20.9502 16.7077L22.5942 18.3517ZM16.7082 20.9497L18.3522 22.5937C17.1039 23.04 15.7545 23.1227 14.4611 22.8321C13.1676 22.5415 11.9833 21.8895 11.0459 20.9521C10.1084 20.0147 9.4565 18.8303 9.16588 17.5369C8.87526 16.2434 8.95791 14.894 9.40422 13.6457L11.0502 15.2917C10.9402 16.0604 11.0107 16.844 11.2561 17.5807C11.5016 18.3173 11.9152 18.9867 12.4642 19.5357C13.0133 20.0847 13.6826 20.4984 14.4193 20.7438C15.1559 20.9892 15.9396 21.0597 16.7082 20.9497Z"
        fill="#293A66"
      />
      <path
        d="M6.7 10.94C6.34 11.26 5.994 11.584 5.664 11.914C4.42329 13.1614 3.31104 14.5302 2.344 16L2.734 16.576C3.404 17.536 4.394 18.816 5.664 20.086C8.242 22.664 11.762 25 16 25C17.432 25 18.78 24.734 20.04 24.28L21.58 25.824C19.8199 26.5931 17.9208 26.9933 16 27C6 27 0 16 0 16C0 16 1.878 12.558 5.282 9.52399L6.698 10.942L6.7 10.94ZM27.292 28.708L3.292 4.70799L4.708 3.29199L28.708 27.292L27.292 28.708Z"
        fill="#293A66"
      />
    </svg>
  );
};
