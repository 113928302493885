import React from "react";

const ChevronLeft = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.385 4.115C28.5014 4.23111 28.5938 4.36905 28.6568 4.52092C28.7198 4.67278 28.7522 4.83558 28.7522 5C28.7522 5.16442 28.7198 5.32722 28.6568 5.47908C28.5938 5.63095 28.5014 5.76889 28.385 5.885L14.2675 20L28.385 34.115C28.6197 34.3497 28.7516 34.6681 28.7516 35C28.7516 35.3319 28.6197 35.6503 28.385 35.885C28.1503 36.1197 27.8319 36.2516 27.5 36.2516C27.1681 36.2516 26.8497 36.1197 26.615 35.885L11.615 20.885C11.4986 20.7689 11.4062 20.6309 11.3432 20.4791C11.2802 20.3272 11.2478 20.1644 11.2478 20C11.2478 19.8356 11.2802 19.6728 11.3432 19.5209C11.4062 19.3691 11.4986 19.2311 11.615 19.115L26.615 4.115C26.7311 3.99859 26.8691 3.90623 27.0209 3.84322C27.1728 3.7802 27.3356 3.74776 27.5 3.74776C27.6644 3.74776 27.8272 3.7802 27.9791 3.84322C28.131 3.90623 28.2689 3.99859 28.385 4.115Z"
        fill="black"
      />
    </svg>
  );
};

export default ChevronLeft;
