import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  plan_payments: [],
  upcoming_payments: [],
  myplan_active_tab:"first",
};

export const paymentSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    handlePlanPayments: (state, action) => {
      state.plan_payments = action.payload;
    },
    handleNextPlanPayments: (state, action) => {
      state.upcoming_payments = action.payload;
    },
    handleActivePlanTab: (state, action) => {
      state.myplan_active_tab = action.payload;
    },
  },
});

export const { handlePlanPayments, handleNextPlanPayments, handleActivePlanTab } =
  paymentSlice.actions;

export default paymentSlice.reducer;
