import Fetcher from "../library/Fetcher";

export const pricingList = async data => {
    return Fetcher.get("/package-list", data);
};

export const buyPackage = async data => {
    return Fetcher.post("/paypal", data);
};

export const countryList = async data => {
    return Fetcher.get("/countries", data);
};


export default { pricingList, buyPackage };
