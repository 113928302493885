import React from "react";

export const CheckGreen = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_22_816)">
        <path
          d="M27.773 12.7841L27.7729 12.7841C27.7706 12.7863 27.7684 12.7888 27.7663 12.7913C27.766 12.7916 27.7658 12.7919 27.7655 12.7923L19.0858 23.8512L18.7376 24.2949L18.3389 23.896L13.1131 18.6677C12.8532 18.4284 12.511 18.2982 12.1575 18.3045C11.8013 18.3108 11.4614 18.4551 11.2095 18.707C10.9576 18.9589 10.8133 19.2988 10.807 19.655C10.8007 20.0085 10.9309 20.3508 11.1703 20.6107L17.7782 27.2211L27.773 12.7841ZM27.773 12.7841L27.777 12.7801M27.773 12.7841L27.777 12.7801M27.777 12.7801C28.0336 12.5258 28.3799 12.3826 28.7412 12.3816C29.1025 12.3806 29.4496 12.5219 29.7076 12.7748C29.9656 13.0277 30.1136 13.372 30.1198 13.7332C30.1259 14.0944 29.9897 14.4435 29.7405 14.7051L29.7256 14.7208M27.777 12.7801L29.7256 14.7208M29.7256 14.7208L29.7121 14.7377M29.7256 14.7208L29.7121 14.7377M29.7121 14.7377L19.7446 27.197C19.6212 27.3264 19.4737 27.4305 19.3103 27.5034C19.1417 27.5787 18.9597 27.6193 18.7751 27.6227C18.5905 27.6262 18.4071 27.5924 18.2358 27.5234C18.0647 27.4544 17.9092 27.3518 17.7786 27.2215L29.7121 14.7377Z"
          fill="#008000"
          stroke="#008000"
        />
      </g>
      <defs>
        <clipPath id="clip0_22_816">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
