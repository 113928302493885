import Fetcher from "../library/Fetcher";

export const blogList = async (data) => {
  return Fetcher.get(
    `/getBlogs?page=${data.page}&record_per_page=${
      data.record_per_page
    }&order=${data.order}${
      data.is_priority ? `&is_priority=${data.is_priority}` : ""
    }`
  );
};

export const blogPriorityApi = async (data) => {
  return Fetcher.get("/getBlogs?is_priority=1", data);
};

export const blogDetail = async (id) => {
  return Fetcher.get(`/blogDetails/${id}`);
};

export default { blogList, blogDetail, blogPriorityApi };
