import React from "react";

const PrevVidIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="24"
        height="24"
        transform="matrix(-1 0 0 1 24.0381 0.169922)"
      />

      <path
        d="M9.56346 12.1699L16.9135 4.81992C17.1635 4.56992 17.2843 4.27409 17.276 3.93242C17.2676 3.59076 17.1385 3.29492 16.8885 3.04492C16.6385 2.79492 16.3426 2.66992 16.001 2.66992C15.6593 2.66992 15.3635 2.79492 15.1135 3.04492L7.43846 10.7449C7.23846 10.9449 7.08846 11.1699 6.98846 11.4199C6.88846 11.6699 6.83846 11.9199 6.83846 12.1699C6.83846 12.4199 6.88846 12.6699 6.98846 12.9199C7.08846 13.1699 7.23846 13.3949 7.43846 13.5949L15.1385 21.2949C15.3885 21.5449 15.6801 21.6658 16.0135 21.6574C16.3468 21.6491 16.6385 21.5199 16.8885 21.2699C17.1385 21.0199 17.2635 20.7241 17.2635 20.3824C17.2635 20.0408 17.1385 19.7449 16.8885 19.4949L9.56346 12.1699Z"
        fill="#fff"
      />
    </svg>
  );
};

export default PrevVidIcon;
