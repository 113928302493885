import React from "react";

export default function Grid() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.33333 21.3333H16C18.9333 21.3333 21.3333 18.9333 21.3333 16V5.33333C21.3333 2.4 18.9333 0 16 0H5.33333C2.4 0 0 2.4 0 5.33333V16C0 18.9333 2.4 21.3333 5.33333 21.3333ZM5.33333 48H16C18.9333 48 21.3333 45.6 21.3333 42.6667V32C21.3333 29.0667 18.9333 26.6667 16 26.6667H5.33333C2.4 26.6667 0 29.0667 0 32V42.6667C0 45.6 2.4 48 5.33333 48ZM26.6667 5.33333V16C26.6667 18.9333 29.0667 21.3333 32 21.3333H42.6667C45.6 21.3333 48 18.9333 48 16V5.33333C48 2.4 45.6 0 42.6667 0H32C29.0667 0 26.6667 2.4 26.6667 5.33333ZM32 48H42.6667C45.6 48 48 45.6 48 42.6667V32C48 29.0667 45.6 26.6667 42.6667 26.6667H32C29.0667 26.6667 26.6667 29.0667 26.6667 32V42.6667C26.6667 45.6 29.0667 48 32 48Z"
        fill="#293864"
        fillOpacity="1"
      />
    </svg>
  );
}
