import Fetcher from "../library/Fetcher";

export const getAllTestimonial = async (data) => {
  return Fetcher.get("/testimonials", data);
};

export const createReviewData = async (data) => {
  return Fetcher.post("testimonial", data);
};

