import React from "react";
import { Helmet } from "react-helmet";
import { Container, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

import Logo1 from "../../assets/images/logo1.png";
import Logo2 from "../../assets/images/logo2.png";
import Logo3 from "../../assets/images/logo3.png";
import Logo4 from "../../assets/images/logo4.png";
import TopBanner from "../../components/banner/TopBanner";

function BrokerPage() {
  return (
    <>
      <Helmet>
        <title>The Merchant | Brokers</title>
        <link rel="canonical" href="https://eltaajir.com/brokers" />
        <meta property="og:title" content="The Merchant - Brokers" />
      </Helmet>
      <TopBanner title="Brokers" />
      <section className="br-client-sec">
        <Container>
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-2 col-sm-4 col-6 mb-3">
              <div className="broker-img text-center">
                <Link to="https://www.cmcmarkets.com/en/" target="_blank">
                  <Image src={Logo1} className="img-fluid shadow " alt="" />
                </Link>
              </div>
            </div>
            <div className="col-lg-2 col-sm-4 col-6 mb-3">
              <div className="broker-img text-center">
                <Link to="https://www.ig.com/ie/pl" target="_blank">
                  <Image src={Logo2} className="img-fluid shadow " alt="" />
                </Link>
              </div>
            </div>
            <div className="col-lg-2 col-sm-4 col-6 mb-3">
              <div className="broker-img text-center">
                <Link to="https://www.icmarkets.com/global/en/" target="_blank">
                  <Image src={Logo3} className="img-fluid shadow " alt="" />
                </Link>
              </div>
            </div>
            <div className="col-lg-2 col-sm-4 col-6 mb-3">
              <div className="broker-img text-center">
                <Link to="https://cfifinancial.com/en-cy" target="_blank">
                  <Image src={Logo4} className="img-fluid shadow " alt="" />
                </Link>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

export default BrokerPage;
