import axios from "axios";
import { toast } from "react-toastify";
import { app_url } from "../config/constants";

class Fetcher {
  constructor() {
    this.axiosSetup = null;
    this.setup();
  }

  setup = async () => {
    this.axiosSetup = axios.create({
      baseURL: app_url,
      timeout: 15000,
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        "Content-type": "multipart/form-data",
      },
    });

    this.axiosSetup.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.response.status === 401 || error.response.status === 429) {
          sessionStorage.removeItem("tm-token");
          if (window.location.pathname !== "/login") {
            window.location.reload();
          }
        } else if (!error.response?.status) {
          toast.error("Oops! Network Error.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
        } else if (error.response.status == 404) {
          toast.error("Oops! 404 Not Found! ", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
        }
        return Promise.reject(error);
      }
    );

    let token = sessionStorage.getItem("tm-token") || null;

    if (token) {
      this.axiosSetup.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
  };

  get = async (route, params, extra) => {
    return this.axiosSetup.get(route, params);
  };

  post = async (route, params, extra) => {
    return this.axiosSetup.post(route, params, extra);
  };

  patch = async (route, params, extra) => {
    return this.axiosSetup.patch(route, params);
  };

  put = async (route, params, extra) => {
    return this.axiosSetup.put(route, params);
  };

  removeToken = () => {
    delete this.axiosSetup.defaults.headers.common.Authorization;
  };
}

export default new Fetcher();
