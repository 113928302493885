import React from "react";

const Finance = () => {
  return (
    <svg
      width="104"
      height="105"
      viewBox="0 0 104 105"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M87.544 16.024C96.248 25.136 100.6 37.512 102.368 50.024C104.272 62.536 103.728 75.184 98.152 85.248C92.576 95.312 82.104 102.656 71.496 104.152C60.752 105.648 50.008 101.296 40.488 96.536C30.832 91.776 22.4 86.608 15.056 78.584C7.57598 70.56 1.04798 59.68 0.639983 47.712C0.231983 35.744 5.94398 22.688 15.736 13.848C25.392 5.00801 39.264 0.248013 52.592 0.792013C65.92 1.20001 78.84 6.91201 87.544 16.024Z"
        fill="url(#paint0_linear_219_92)"
      />
      <path
        d="M67.6194 37.0763C67.2394 37.0657 66.876 37.0591 66.5291 37.0566C66.0516 37.0529 65.7207 37.0119 65.5363 36.9338C64.5407 36.5138 64.2547 35.2435 65.045 34.4701C65.3797 34.1419 65.766 34.0294 66.2235 34.0407C67.901 34.0826 69.5328 34.1201 71.1191 34.1532C71.7903 34.1673 72.32 34.4635 72.5863 35.0898C72.6738 35.296 72.7122 35.5688 72.7016 35.9082C72.6535 37.4963 72.6203 38.9948 72.6022 40.4035C72.5966 40.8616 72.5503 41.1813 72.4635 41.3626C72.05 42.2223 71.0319 42.5513 70.2322 41.9963C69.7541 41.6644 69.5647 41.1798 69.5835 40.5891C69.5966 40.1879 69.6069 39.8129 69.6144 39.4641C69.6157 39.4204 69.601 39.4141 69.5703 39.4454C66.3872 42.7691 63.2038 46.0907 60.02 49.4101C59.4978 49.9548 58.6897 50.0841 58.0625 49.6557C57.9232 49.5607 57.7728 49.4098 57.6116 49.2029C55.9166 47.0341 54.2353 44.8923 52.5678 42.7773C52.5564 42.7629 52.542 42.7513 52.5256 42.7434C52.5092 42.7354 52.4913 42.7313 52.4732 42.7313C50.7069 42.7313 48.9388 42.7319 47.1688 42.7332C46.8013 42.7332 46.4725 42.6244 46.1825 42.4069C41.7594 39.1007 37.63 36.0001 33.7944 33.1051C33.7038 33.0369 33.6132 32.9294 33.5225 32.7826C33.0453 32.0138 33.3772 30.9263 34.2482 30.6094C34.9982 30.3357 35.4707 30.6169 36.035 31.0426C39.7488 33.8407 43.5991 36.7291 47.586 39.7079C47.6078 39.7241 47.6322 39.7323 47.6591 39.7323C49.4291 39.7316 51.2075 39.7319 52.9944 39.7332C53.3582 39.7338 53.6235 39.7657 53.7903 39.8288C54.0541 39.9288 54.2775 40.0954 54.4607 40.3285C55.9938 42.281 57.506 44.206 58.9972 46.1035C58.9995 46.1064 59.0023 46.1088 59.0055 46.1105C59.0088 46.1122 59.0124 46.1131 59.016 46.1133C59.0197 46.1135 59.0233 46.1129 59.0267 46.1115C59.0301 46.1101 59.0331 46.108 59.0357 46.1054L67.6391 37.126C67.6697 37.0941 67.6632 37.0776 67.6194 37.0763Z"
        fill="white"
      />
      <path
        d="M40.6522 46.6898V70.9758C40.6522 71.307 40.587 71.6349 40.4602 71.9409C40.3335 72.2468 40.1477 72.5248 39.9136 72.759C39.6794 72.9932 39.4014 73.179 39.0954 73.3057C38.7894 73.4324 38.4615 73.4977 38.1303 73.4977H34.0241C33.3552 73.4977 32.7138 73.232 32.2408 72.759C31.7679 72.2861 31.5022 71.6446 31.5022 70.9758V46.6898C31.5022 46.0212 31.7678 45.3799 32.2405 44.9069C32.7132 44.434 33.3545 44.1682 34.0231 44.168H38.1294C38.4606 44.1678 38.7887 44.233 39.0948 44.3597C39.4008 44.4863 39.6789 44.6721 39.9132 44.9063C40.1475 45.1405 40.3333 45.4185 40.4601 45.7245C40.5869 46.0306 40.6522 46.3586 40.6522 46.6898Z"
        fill="white"
      />
      <path
        d="M51.9378 52.8524V70.963C51.9378 71.6353 51.6707 72.2801 51.1953 72.7555C50.7199 73.2309 50.0751 73.498 49.4028 73.498H45.3162C44.6439 73.498 43.9991 73.2309 43.5237 72.7555C43.0483 72.2801 42.7812 71.6353 42.7812 70.963V52.8524C42.7812 52.1801 43.0483 51.5353 43.5237 51.0599C43.9991 50.5845 44.6439 50.3174 45.3162 50.3174H49.4028C50.0751 50.3174 50.7199 50.5845 51.1953 51.0599C51.6707 51.5353 51.9378 52.1801 51.9378 52.8524Z"
        fill="white"
      />
      <path
        d="M65.3478 70.9696V52.8458C65.3478 52.1752 65.6142 51.5321 66.0883 51.0579C66.5625 50.5838 67.2056 50.3174 67.8762 50.3174H71.9693C72.6399 50.3174 73.283 50.5838 73.7572 51.0579C74.2314 51.5321 74.4978 52.1752 74.4978 52.8458V70.9696C74.4978 71.6402 74.2314 72.2833 73.7572 72.7574C73.283 73.2316 72.6399 73.498 71.9693 73.498H67.8762C67.2056 73.498 66.5625 73.2316 66.0883 72.7574C65.6142 72.2833 65.3478 71.6402 65.3478 70.9696Z"
        fill="white"
      />
      <path
        d="M60.6884 56.4766H56.5934C55.196 56.4766 54.0631 57.6094 54.0631 59.0069V70.9675C54.0631 72.365 55.196 73.4978 56.5934 73.4978H60.6884C62.0859 73.4978 63.2187 72.365 63.2187 70.9675V59.0069C63.2187 57.6094 62.0859 56.4766 60.6884 56.4766Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_219_92"
          x1="0.619507"
          y1="104.452"
          x2="104.317"
          y2="1.7152"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#293A66" />
          <stop offset="1" stopColor="#00B7E1" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default Finance;
