import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useCookies } from "react-cookie";

function CookieConsent() {
  const [isCookieOpen, setIsCookieOpen] = useState(true);
  const [cookies, setCookies] = useCookies(["cookieConsent"]);
  const giveCookieConsent = () => {
    setCookies("cookieConsent", true, { path: "/" });
  };

  return (
    <>
      <div
        className={`cookie-consent cookies-popup ${isCookieOpen ? "open" : ""}`}
      >
        <div className="ck-title">
          <h3>Cookies Consent</h3>
        </div>
        <div className="ck-ctn">
          <p>
            This website use cookies to help you have a superior and more
            admissible browsing experience on the website.
          </p>
        </div>
        <div className="ck-footer">
          <Button className="accept" onClick={giveCookieConsent}>
            Accept
          </Button>
          <Button className="decline" onClick={() => setIsCookieOpen(false)}>
            Decline
          </Button>
        </div>
      </div>
    </>
  );
}

export default CookieConsent;
