import React, { useState } from "react";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import FooterLogo from "../../assets/images/footer-logo_new.svg";
import ListGroup from "react-bootstrap/ListGroup";
import { Link, useNavigate } from "react-router-dom";
import Instagram from "../../assets/images/instagram.svg";
import Facebook from "../../assets/images/facebook.svg";
import Twitter from "../../assets/images/twitter.svg";
import Linkedin from "../../assets/images/linkedin.svg";
import Tiktok from "../../assets/images/tiktok.svg";
import Youtube from "../../assets/images/youtube.svg";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import Fetcher from "../../library/Fetcher";

function Footer() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});

  const handleChangeInput = (event) => {
    let { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const newsletterMutation = useMutation(
    (uploadedData) => Fetcher.post(`/newsLetterSubscription`, uploadedData),
    {
      onSuccess: (data) => {
        if (data?.data) {
          toast.success(data?.data.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
        }
        return;
      },
      onError: (error) => {
        if (error?.response?.data?.errors) {
          return;
        }
      },
    }
  );

  const onSubscribeHandler = (e) => {
    e.preventDefault();
    newsletterMutation.mutate(formData);
  };

  const myNavigation = (e, path) => {
    e.preventDefault();
    navigate(path);
  };

  return (
    <footer className="site-footer">
      <Container>
        <div className="footer-wrap">
          <Row className="justify-content-between">
            <Col className="footer-logo-col">
              <Link
                onClick={(e) => myNavigation(e, "/")}
                className="text-start d-block"
              >
                <Image src={FooterLogo} className="footer-logo" />
              </Link>
              <p className="footer-logo-label mb-0">
                Decoding The Forex & Crypto Markets
              </p>
            </Col>
            <Col className="footer-company-link">
              <div className="footer-links">
                <Row className="justify-content-center">
                  <Col className="col-auto text-start footer-links-col">
                    <h4>Company</h4>
                    <ListGroup as="ul">
                      <ListGroup as="li">
                        <Link onClick={(e) => myNavigation(e, "/about-me")}>
                          About Me
                        </Link>
                      </ListGroup>
                      <ListGroup as="li">
                        <Link
                          onClick={(e) => myNavigation(e, "/resource-center")}
                        >
                          Resource Center
                        </Link>
                      </ListGroup>
                      <ListGroup as="li">
                        <Link
                          onClick={(e) => myNavigation(e, "/documentation")}
                        >
                          Documentation
                        </Link>
                      </ListGroup>
                    </ListGroup>
                  </Col>
                  <Col className="col-auto text-start footer-links-col">
                    <h4>Resources</h4>
                    <ListGroup as="ul">
                      <ListGroup as="li">
                        <Link
                          onClick={(e) =>
                            myNavigation(e, "/terms-and-conditions")
                          }
                        >
                          Terms & Conditions
                        </Link>
                      </ListGroup>
                      <ListGroup as="li">
                        <Link
                          onClick={(e) => myNavigation(e, "/privacy-policy")}
                        >
                          Privacy Policy
                        </Link>
                      </ListGroup>
                      <ListGroup as="li">
                        <Link
                          onClick={(e) => myNavigation(e, "/fca-disclaimer")}
                        >
                          Disclaimer
                        </Link>
                      </ListGroup>
                    </ListGroup>
                  </Col>
                  <Col className="col-auto text-start footer-links-col">
                    <h4>Company</h4>
                    <ListGroup as="ul">
                      <ListGroup as="li">
                        <Link onClick={(e) => myNavigation(e, "/community")}>
                          Community
                        </Link>
                      </ListGroup>
                      <ListGroup as="li">
                        <Link onClick={(e) => myNavigation(e, "/support")}>
                          Support
                        </Link>
                      </ListGroup>
                      <ListGroup as="li">
                        <Link onClick={(e) => myNavigation(e, "/security")}>
                          Security
                        </Link>
                      </ListGroup>
                    </ListGroup>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col className="footer-subscribe-col">
              <div className="subscribe-us-sec text-start">
                <h4>Subscribe our Offers & News</h4>
                <Form className="row footer-form">
                  <Col>
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="name@example.com"
                        className="subscribe-email"
                        onChange={handleChangeInput}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-auto">
                    <Button
                      type="submit"
                      className="footer-mt-submit"
                      onClick={(e) => onSubscribeHandler(e)}
                    >
                      Submit
                    </Button>
                  </Col>
                </Form>

                <ListGroup
                  as="ul"
                  className="d-flex flex-row footer-social-list"
                >
                  <ListGroup as="li">
                    <Link
                      to="https://instagram.com/eltaajir?igshid=YmMyMTA2M2Y="
                      target="_blank"
                    >
                      <Image src={Instagram} className="footer-social-icon" />
                    </Link>
                  </ListGroup>
                  <ListGroup as="li">
                    <Link
                      to="https://www.facebook.com/eltaajirtrading"
                      target="_blank"
                    >
                      <Image src={Facebook} className="footer-social-icon" />
                    </Link>
                  </ListGroup>
                  <ListGroup as="li">
                    <Link
                      to="https://twitter.com/Hesham_Elsaid_"
                      target="_blank"
                    >
                      <Image src={Twitter} className="footer-social-icon" />
                    </Link>
                  </ListGroup>
                  <ListGroup as="li">
                    <Link
                      to="https://www.linkedin.com/in/hesham-elsaid/"
                      target="_blank"
                    >
                      <Image src={Linkedin} className="footer-social-icon" />
                    </Link>
                  </ListGroup>
                  <ListGroup as="li">
                    <Link
                      to="https://www.tiktok.com/@eltaajir?lang=en"
                      target="_blank"
                    >
                      <Image src={Tiktok} className="footer-social-icon" />
                    </Link>
                  </ListGroup>
                  <ListGroup as="li">
                    <Link
                      to="https://www.youtube.com/channel/UCX64WdWCUK9aesPlNjPLJbQ"
                      target="_blank"
                    >
                      <Image src={Youtube} className="footer-social-icon" />
                    </Link>
                  </ListGroup>
                </ListGroup>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </footer>
  );
}
export default Footer;
