import React, { useEffect, useState } from "react";
import Pricing from "../components/pricing/Pricing";
import TopBanner from "../components/banner/TopBanner";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";

export const useScript = (url, name) => {
  const [lib, setLib] = useState({});

  useEffect(() => {
    const script = document.createElement("script");

    script.src = url;
    script.async = true;
    script.onload = () => setLib({ [name]: window[name] });

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);

  return lib;
};

function TradeView(props) {
  const { TradingView } = useScript(
    "https://s3.tradingview.com/tv.js",
    "TradingView"
  );

  const tradeFunc = () => {
    new TradingView.widget({
      width: "100%",
      height: 850,
      symbol: "CAPITALCOM:DXY",
      timezone: "Etc/UTC",
      theme: "dark",
      style: "1",
      locale: "en",
      toolbar_bg: "#f1f3f6",
      enable_publishing: true,
      withdateranges: true,
      range: "YTD",
      hide_side_toolbar: false,
      allow_symbol_change: true,
      watchlist: [
        "TVC:USOIL",
        "OANDA:XAUUSD",
        "FX:EURUSD",
        "FX:EURGBP",
        "FX:GBPUSD",
        "FX:GBPJPY",
        "FX:EURAUD",
        "FX:EURNZD",
        "FX:USDJPY",
        "FX:AUDUSD",
        "FX:NZDUSD",
        "FX:USDCAD",
        "FX:EURJPY",
        "FX:USDCHF",
        "FX:GBPAUD",
        "FX:AUDCAD",
        "FX:AUDJPY",
        "FX:NZDJPY",
        "FX:NZDCAD",
        "FX:EURCAD",
        "FX:AUDNZD",
        "FX:USDNOK",
        "FX:GBPNZD",
        "FX:EURNOK",
        "FX:CHFJPY",
        "FX:CADJPY",
        "CURRENCYCOM:US30",
      ],
      details: true,
      hotlist: true,
      calendar: true,
      container_id: "tradingview_62f79",
    });
  };

  setTimeout(() => {
    tradeFunc();
  }, 100);

  return (
    <React.Fragment>
      <Helmet>
        <title>The Merchant - Trading View</title>
        <link rel="canonical" href="https://eltaajir.com/trading-view" />
      </Helmet>
      <TopBanner title="Trading View" />
      <Container className="px-md-5 py-5">
        <Row>
          <Col lg={12}>
            <div className="tradingview-widget-container">
              <div id="tradingview_62f79"></div>
            </div>
          </Col>
        </Row>
      </Container>
      <Pricing />
    </React.Fragment>
  );
}

export default TradeView;
