import React from "react";

export const TwitterIconShare = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <path
        d="M44 7.99805C42.44 8.92405 39.31 10.186 37.47 10.55C37.416 10.564 37.372 10.582 37.32 10.596C35.694 8.99205 33.466 7.99805 31 7.99805C26.03 7.99805 22 12.028 22 16.998C22 17.26 21.978 17.742 22 17.998C15.294 17.998 10.19 14.486 6.53 9.99805C6.132 10.998 5.958 12.578 5.958 14.062C5.958 16.864 8.148 19.616 11.558 21.322C10.93 21.484 10.238 21.6 9.518 21.6C8.356 21.6 7.126 21.294 6 20.366C6 20.4 6 20.432 6 20.468C6 24.384 10.156 27.05 13.852 27.792C13.102 28.234 11.59 28.278 10.852 28.278C10.332 28.278 8.492 28.04 8 27.948C9.028 31.158 12.736 32.962 16.27 33.026C13.506 35.194 11.588 35.998 5.928 35.998H4C7.576 38.29 12.13 40 16.694 40C31.554 40 40 28.674 40 17.998C40 17.826 39.996 17.466 39.99 17.104C39.99 17.068 40 17.034 40 16.998C40 16.944 39.984 16.892 39.984 16.838C39.978 16.566 39.972 16.312 39.966 16.18C41.546 15.04 42.916 13.618 44 11.998C42.55 12.642 40.994 13.074 39.36 13.27C41.028 12.27 43.398 9.88605 44 7.99805Z"
        fill="#1DA1F2"
      />
    </svg>
  );
};
