import React from "react";

const YouTubeIcon = () => {
  return (
    
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_958_51829)">
    <path d="M50.3187 12.4937H50.875C56.0125 12.5125 82.0437 12.7 89.0625 14.5875C91.1843 15.1635 93.1178 16.2864 94.6697 17.8438C96.2217 19.4012 97.3377 21.3386 97.9062 23.4625C98.5375 25.8375 98.9813 28.9812 99.2813 32.225L99.3438 32.875L99.4812 34.5L99.5313 35.15C99.9375 40.8625 99.9875 46.2125 99.9937 47.3812V47.85C99.9875 49.0625 99.9312 54.775 99.4812 60.725L99.4313 61.3812L99.375 62.0312C99.0625 65.6062 98.6 69.1562 97.9062 71.7687C97.3394 73.8934 96.224 75.8318 94.6718 77.3895C93.1196 78.9472 91.1852 80.0694 89.0625 80.6437C81.8125 82.5937 54.2562 82.7312 50.4375 82.7375H49.55C47.6187 82.7375 39.6312 82.7 31.2562 82.4125L30.1937 82.375L29.65 82.35L28.5812 82.3062L27.5125 82.2625C20.575 81.9562 13.9688 81.4625 10.925 80.6375C8.80306 80.0637 6.86916 78.9424 5.31703 77.3859C3.7649 75.8294 2.64904 73.8923 2.08125 71.7687C1.3875 69.1625 0.925 65.6062 0.6125 62.0312L0.5625 61.375L0.5125 60.725C0.204047 56.49 0.0331242 52.2461 0 48L0 47.2312C0.0125 45.8875 0.0625 41.2437 0.4 36.1187L0.44375 35.475L0.4625 35.15L0.5125 34.5L0.65 32.875L0.7125 32.225C1.0125 28.9812 1.45625 25.8312 2.0875 23.4625C2.65434 21.3378 3.76976 19.3994 5.32196 17.8418C6.87415 16.2841 8.80856 15.1618 10.9312 14.5875C13.975 13.775 20.5812 13.275 27.5187 12.9625L28.5812 12.9187L29.6562 12.8812L30.1937 12.8625L31.2625 12.8187C37.2107 12.6273 43.1613 12.5211 49.1125 12.5H50.3187V12.4937ZM40 32.5562V62.6687L65.9813 47.6187L40 32.5562Z" fill="#666666"/>
    </g>
    <defs>
    <clipPath id="clip0_958_51829">
    <rect width="100" height="100" fill="white"/>
    </clipPath>
    </defs>
    </svg>

  
  );
};
export default YouTubeIcon;
