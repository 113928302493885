import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./assets/css/custom.css";
import "./assets/css/custom_varient.css";

const loadingMarkup = () => {
  return (
    <div className="py-4 text-center">
      <h2>Loading..</h2>
    </div>
  );
};

const queryClient = new QueryClient();
const mergeApp = (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={loadingMarkup}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Suspense>
    </QueryClientProvider>
  </Provider>
);

const domNode = document.getElementById("root");
const root = ReactDOM.createRoot(domNode);
root.render(mergeApp);
