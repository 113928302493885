import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import Education from "../../assets/images/educate.png";
import Transparency from "../../assets/images/transparency.png";
import Trades from "../../assets/images/trades.png";
import LiveSession from "../../assets/images/live-session.png";
import Community from "../../assets/images/community.png";
import Purpose from "../../assets/images/purpose.png";

export const benefitObj = [
  {
    id: "1",
    title: "Education",
    desc: "Learn how to trade properly and gain a deep understanding of the financial market via the 35+ videos and courses which are exclusively for members.",
    img: Education,
  },
  {
    id: "2",
    title: "Transparency & Honesty",
    desc: "Follow me step by step with each and every trade I execute in the market, I will explain the thought process and the outcomes.",
    img: Transparency,
  },
  {
    id: "3",
    title: "Trades",
    desc: "Receive trade setups on our private telegram channel for members who choose the plan",
    img: Trades,
  },
  {
    id: "4",
    title: "Live sessions",
    desc: "Join us as a community on our live sessions where I analyze the market, answer your questions and take trades together (if they appear).",
    img: LiveSession,
  },
  {
    id: "5",
    title: "Community",
    desc: "Join a large community of individual traders and Investors from everywhere in the world. In this community we focus on the quality of what we do while having the mindset to succeed.",
    img: Community,
  },
  {
    id: "6",
    title: "Purpose",
    desc: "My main goal is to have you as a member for a maximum of one year. During this time, I want you to learn how to trade, how to read the market, to have the right mindset as a trader and to be able to find trades and execute them with confidence.",
    img: Purpose,
  },
];

export default function Benifit() {
  return (
    <>
      <section className="benefit-working">
        <Container>
          <div className="benefit-working-wrap">
            <div className="page-title">
              <Image
                className="img-blook-icon"
                src={require("../../assets/images/right-vector.png")}
              />
              <h3 className="d-inline-block">
                <strong>Benefits</strong> of working with us
              </h3>
            </div>
            <div className="benefit-working-ctn">
              <Row>
                {benefitObj.map((bnf, index) => (
                  <Col xs={6} key={index}>
                    <div className="benefit-working-card d-flex align-items-center">
                      <div className="benefit-card-img d-flex align-items-center justify-content-center">
                        <Image src={bnf.img} className="card-icon" />
                      </div>
                      <div className="benefit-card-ctn">
                        <h4>{bnf.title}</h4>
                        <p>{bnf.desc}</p>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}
