import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { telegramEmail } from "../api/authApi";
import QRImage from "../assets/images/private-telegram.svg";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

function PrivateTelegram() {
  const [showImage, setShowImage] = useState(false);
  const [formData, setFormData] = useState({});
  const [textError, setTextError] = useState(false);
  const user = useSelector((state) => state.authUser).user;

  useEffect(() => {
    if (user) {
      setFormData({
        ...formData,
        ["first_name"]: user.first_name,
        ["last_name"]: user.last_name,
        ["user_email"]: user.email,
      });
    }
  }, [user]);

  const inputchangeHandler = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const TelegramEmailMutation = useMutation(telegramEmail, {
    onSuccess: (data) => {
      if (data?.data) {
        setShowImage(true);
        toast.success(data?.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }

      return;
    },
    onError: (error) => {
      if (error?.response?.data?.errors) {
        return;
      }
    },
  });

  const onSumitHandler = (e) => {
    e.preventDefault();
    if (document.getElementById("balanceInput").value.length > 0) {
      TelegramEmailMutation.mutate(formData);
      setTextError(false);
    } else {
      setTextError(true);
    }
  };

  return (
    <section className="telegram-section">
      <Helmet>
        <title>The Merchant - Telegram</title>
        <link rel="canonical" href="https://eltaajir.com/private-telegram" />
        <meta property="og:title" content="The Merchant - Telegram" />
      </Helmet>
      <Container>
        <div className="private-main">
          <Row className="telegram-row bg-white py-5">
            <Col lg={12}>
              <div className="qr-content">
                <h1 className="mb-5">QR CODE SCAN</h1>
                <ul>
                  <li>Scan the QR code to join the free Telegram channel.</li>
                </ul>
                <p>
                  Declaration before joining the private Telegram channel
                  (Signals group)
                </p>
                <p>
                  By ticking the box below, you declare that you will follow and
                  accept the following points:
                </p>
                <ol className="mb-3">
                  <li>
                    Forex Market is high volatile market, and most traders lose
                    their money due to lack of strategy, risk management,
                    discipline, consistency, and ability to understand the
                    nature of the market,
                  </li>
                  <li>
                    Every single trade I post in the channel is the trade which
                    I personally take with my personal forex account,
                  </li>
                  <li>
                    Forex market results need to be measured in the long term
                    not by day, week, or month. Therefore, I will be focusing
                    mainly on results per quarter and that’s why minimum
                    commitment for signals group which is part of package 2 is
                    3-month,
                  </li>
                  <li>
                    I will be sharing results from my side on the Telegram group
                    or social media per day or week (whenever I can) but results
                    of the services for our community will be measured per
                    quarter,
                  </li>
                  <li>
                    Never ever risk more than 1% per trade from your capital. In
                    case we enter multiple trades at the same time you will need
                    to divide the 1% across the trades. You can use this tool to
                    help you in calculating this Link to the tool
                  </li>
                  <li>
                    As you going to be part of the private Telegram channel then
                    you going to do the following:
                    <ul>
                      <li>
                        strictly following my strategy and my recommendation for
                        every single trade I post or enter, all stop losses (SL)
                        and take profits (SL)
                      </li>
                      <li>
                        you cannot be selective by choosing which trades you
                        enter, and which don’t. if you do, so any results will
                        be your own responsibility
                      </li>
                      <li>
                        for the swing trader you will have 1-2 hours to set up
                        the trade inside your platform
                      </li>
                      <li>
                        you will not be sharing or selling anything from the
                        group or courses with anyone else. In case I will find
                        out that or our bot will notice that you did that we
                        reserve the right to restrict you from the services
                      </li>
                    </ul>
                  </li>
                  <li>
                    you will not be sharing or selling anything from the group
                    or courses with anyone else. In case I will find out that or
                    our bot will notice that you did that we reserve the right
                    to restrict you from the services
                  </li>
                  <li>
                    Forex market need to be treated as business and results need
                    to be treated in the long term. The most important thing
                    which you need to know and learn in this group is
                    discipline, risk management, consistency and the right
                    mindset of a trader, money will come as results.
                  </li>
                  <li>
                    The account balance is requested so I will be able to
                    calculate results across the community.
                  </li>
                </ol>
                <div className="qr-img ">
                  {showImage && (
                    <img className="img-fluid" src={QRImage} alt="" />
                  )}
                </div>
                <p>
                  * By clicking here, I hereby declare that I read and accepted
                  all points mentioned above.
                </p>
                <Form>
                  <div className="form_field">
                    <label
                      htmlFor=""
                      className="telegram-label d-flex flex-column flex-md-row"
                    >
                      Balance of your trading account $
                      <Form.Control
                        type="number"
                        onChange={(e) => inputchangeHandler(e)}
                        required
                        name="balance"
                        id="balanceInput"
                      />{" "}
                    </label>
                    {textError && (
                      <p className="error-message">*Please Enter Balance</p>
                    )}
                  </div>
                  <div className="nav-signup-btn">
                    <Button className="mt-3" onClick={(e) => onSumitHandler(e)}>
                      Submit
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
            <Col lg={6}>
              {showImage && (
                <div className="telegram-link ms-3 d-none">
                  <h5>Link to the private telegram channel:</h5>
                  <p>@EltaajirBot</p>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
}

export default PrivateTelegram;
