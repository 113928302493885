import React, { useEffect, useState } from "react";

import { CloseButton, Col, Container, Modal, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { pricingList } from "../../api/pricingApi";
import { useDispatch, useSelector } from "react-redux";
import { homeInfo } from "../../reducers/homeSlice";

export default function Pricing() {
  const navigate = useNavigate();
  const planPricing = useSelector((state) => state.merchant.planPricing) || [];
  const dispatch = useDispatch();

  const [courseDetailsModal, setCourseDetailsModal] = useState(false);
  const [courseId, setcourseId] = useState();
  const token = sessionStorage.getItem("tm-token");

  useEffect(() => {
    if (planPricing.length === 0) {
      pricingListMutation.mutate();
    }
  }, [planPricing]);

  const pricingListMutation = useMutation(pricingList, {
    onSuccess: (data) => {
      if (data?.data) {
        dispatch(homeInfo({ type: "planPricing", payload: data?.data?.data }));
      }
      return;
    },
    onError: (error) => {},
  });

  const handleSelectPlan = (event, value) => {
    event.preventDefault();
    if (token) {
      navigate("/payment", {
        state: {
          packageId: value.id,
        },
      });
    } else {
      navigate("/login");
    }
  };

  function handleSeeMore(e, dp) {
    setcourseId(dp.id);
    setCourseDetailsModal(true);
  }

  return (
    <>
      {planPricing.length > 0 && (
        <section className="plans-price">
          <Container className="plant-price-container">
            <div className="plans-price-wrap">
              <div className="price-page-title">
                <h3 className="price-title">
                  Choose the membership plan that's right for you. you can
                  cancel anytime!
                </h3>
              </div>
              <div className="plans-price-ctn">
                <Row>
                  {planPricing.map((dp, index) => {
                    return (
                      dp.type === 2 && (
                        <Col key={index} sm="6" md="6" lg="6" xl="3">
                          <div
                            className={`plans-price-card text-center${
                              dp.is_active === 0 ? " disabled" : ""
                            }`}
                          >
                            <div className="card-top">
                              <h4 className="card-course-name">
                                {dp.type === 1
                                  ? dp.duration
                                  : `${dp.duration_period} ${dp.duration}`}
                              </h4>
                              <div className="card-price-wrap">
                                <div className="d-flex">
                                  <span className="dollar">&euro;</span>
                                  <h3 className="card-course-price mb-0">
                                    {dp.amount * dp.duration_period}
                                  </h3>
                                </div>
                                {dp.discount_amount ? (
                                  <span className="discount">
                                    ({dp.discount_amount}% Discount)
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="benifit_list">
                              {dp.description &&
                                dp.description
                                  ?.replaceAll("<p>", "")
                                  .split("</p>")
                                  .slice(0, 3)
                                  .map((item, index) => {
                                    return <p key={index}>{item}</p>;
                                  })}
                            </div>
                            <div className="card-bottom">
                              {dp.description
                                ?.replaceAll("<p>", "")
                                .split("</p>").length >= 3 &&
                                (dp.is_active === 0 ? (
                                  <Link className="see-more" to={"#"}>
                                    See More
                                  </Link>
                                ) : (
                                  <Link
                                    className="see-more"
                                    onClick={(e) => handleSeeMore(e, dp)}
                                  >
                                    See More
                                  </Link>
                                ))}
                              {dp.is_active === 0 ? (
                                <Link
                                  className="btn text-center card-select-plan disabled"
                                  to={"#"}
                                >
                                  Subscribe
                                </Link>
                              ) : (
                                <Link
                                  className="btn text-center card-select-plan"
                                  onClick={(e) => handleSelectPlan(e, dp)}
                                >
                                  Subscribe
                                </Link>
                              )}
                            </div>
                            {courseId === dp.id && (
                              <Modal
                                show={courseDetailsModal}
                                centered
                                className="plan-price-modal"
                              >
                                <Modal.Header>
                                  <Modal.Title>
                                    <div className="d-flex align-items-center">
                                      <h4 className="card-course-name">
                                        {dp.type === 1
                                          ? dp.duration
                                          : `${dp.duration_period} ${dp.duration}`}
                                      </h4>
                                      {dp.discount_amount ? (
                                        <span className="modal-discount">
                                          {dp.discount_amount}% Discount
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="card-price-wrap">
                                      <span className="dollar">&euro;</span>
                                      <h3 className="card-course-price mb-0">
                                        {dp.amount * dp.duration_period}
                                      </h3>
                                    </div>
                                  </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  <div
                                    className="benifit_list"
                                    dangerouslySetInnerHTML={{
                                      __html: dp.description,
                                    }}
                                  ></div>
                                </Modal.Body>
                                <Modal.Footer>
                                  <Link
                                    onClick={(e) => handleSelectPlan(e, dp)}
                                  >
                                    Subscribe
                                  </Link>
                                </Modal.Footer>
                                <CloseButton
                                  className="close-btn"
                                  onClick={() => setCourseDetailsModal(false)}
                                ></CloseButton>
                              </Modal>
                            )}
                          </div>
                        </Col>
                      )
                    );
                  })}
                </Row>
              </div>
            </div>
          </Container>
        </section>
      )}
    </>
  );
}
