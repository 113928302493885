import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../components/loader/loader";

const UnauthGuard = ({ component }) => {
  const navigate = useNavigate();
  const havetoken = sessionStorage.getItem("tm-token");

  useEffect(() => {
    if (havetoken) {
      navigate("/");
    }
  }, []);

  return <React.Fragment>{!havetoken ? component : <Loader />}</React.Fragment>;
};

export default UnauthGuard;
