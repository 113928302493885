import React from "react";

export const LinkedInIconShare = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <path
        d="M42 37C42 39.762 39.762 42 37 42H11C8.239 42 6 39.762 6 37V11C6 8.238 8.239 6 11 6H37C39.762 6 42 8.238 42 11V37Z"
        fill="#0288D1"
      />
      <path
        d="M12 19H17V36H12V19ZM14.485 17H14.457C12.965 17 12 15.888 12 14.499C12 13.08 12.995 12 14.514 12C16.035 12 16.972 13.08 17 14.499C17 15.887 16.035 17 14.485 17ZM36 36H31V26.901C31 24.703 29.775 23.203 27.808 23.203C26.307 23.203 25.495 24.215 25.101 25.193C24.957 25.543 25 26.511 25 27V36H20V19H25V21.616C25.721 20.5 26.85 19 29.738 19C33.316 19 35.999 21.25 35.999 26.274L36 36Z"
        fill="white"
      />
    </svg>
  );
};
