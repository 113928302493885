import React, { useState } from "react";
import { Image } from "react-bootstrap";
import { Message } from "../../assets/svg/Message";
import { LogoChatbot } from "../../assets/svg/LogoChatbot";
import BotChat from "./BotChat";
import { useRef } from "react";
import { useEffect } from "react";

const chatOption = [
  { id: "products-service", name: "Products & services" },
  { id: "subscribe", name: "Subscriptions" },
  { id: "support", name: "Support" },
  { id: "other", name: "Other" },
  { id: "about-me", name: "About me" },
];

const response = {

  "products-service": `Product Service`,
  subscribe: `Subscriptions`,
  support:
    "<p>please send an email to: <a href='mailto:info@eltaajir.com' >support@eltaajir.com </a> with your inquiry and we will get back to you as soon aspossible</p>",
  other:
    "<p>lease send an email to: <a href='mailto:info@eltaajir.com'>info@eltaajir.com</a> with your inquiry and we will get back to you as soon as possible </p>",

  "about-me": `About Me`,
};

export const Chatbot = () => {
  const [openChatBot, SetOpenChatBot] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [messages, setMessages] = useState([]);
  const bottomEl = useRef(null);

  const handleSelectOption = (message) => {
    const details = {
      value: message.name,
      user: "user",
      page: message.id,
    };
    let currentMessage = [...messages, details];
    setMessages(currentMessage);
    const data = responseHandler(message.id);
    currentMessage = [...currentMessage, data];
    setIsTyping(true);
    setTimeout(() => {
      setMessages(currentMessage);
      setIsTyping(false);
    }, 500);
  };

  const responseHandler = (id) => {
    const details = {
      user: "bot",
      value: response[id],
      page: id,
    };
    return details;
  };

  useEffect(() => {
    const scrollEl = bottomEl.current;
    scrollEl?.scroll({
      top: scrollEl?.scrollHeight,
      behavior: "smooth",
    });
  }, [messages]);

  return (
    <section className="chat-boat-section">
      <div className="container">
        <div className="row">
          <div className={`chat-body${openChatBot ? "" : " close-body"}`}>
            <div className={`chat-header${openChatBot ? "" : " close-header"}`}>
              <div
                className={`row align-items-center${
                  openChatBot ? "" : " justify-content-center"
                }`}
              >
                <div className={`col-2${openChatBot ? " d-block" : " d-none"}`}>
                  <div className="parent-img">
                    <Image
                      src={require("../../assets/images/right-vector.png")}
                      alt="logo"
                    />
                  </div>
                </div>
                <div className={`col-8${openChatBot ? " d-block" : " d-none"}`}>
                  <p>Eltaajir Assist</p>
                </div>
                <div className={`${openChatBot ? " col-2" : " col-12"}`}>
                  <div
                    className={`up-down${openChatBot ? "" : " close-down"}`}
                    onClick={() => SetOpenChatBot(!openChatBot)}
                  >
                    {openChatBot ? <LogoChatbot /> : <Message />}
                  </div>
                </div>
              </div>
            </div>

            <div className={`chat-box-body${openChatBot ? " d-flex" : ""}`}>
              <div className="chat-parent" ref={bottomEl}>
                <ul className="chat-list">
                  <li>Hello Trader, how can I help you today?</li>
                  {messages.map((message, index) => {
                    return <BotChat message={message} key={index} />;
                  })}
                  {isTyping ? (
                    <li className="bot isTyping">
                      <div className="typing-dots">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </li>
                  ) : (
                    ""
                  )}
                  {!isTyping && (
                    <div className="select-option">
                      {chatOption.map((option, key) => {
                        return (
                          <button
                            key={key}
                            onClick={() => handleSelectOption(option)}
                          >
                            {option["name"]}
                          </button>
                        );
                      })}
                    </div>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
