import React from "react";
import TopBanner from "../components/banner/TopBanner";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function TermsAndCondition() {
  return (
    <>
      <Helmet>
        <title>
          The Merchant - Terms and Conditions
        </title>
        <link
          rel="canonical"
          href="https://eltaajir.com/terms-and-conditions"
        />
        <meta property="og:title" content="The Merchant - Terms and Conditions" />  
      </Helmet>
      <TopBanner title="Terms & Conditions" />
      <section className="mt-tnc-sec">
        <Container>
          <div className="main-policy">
            <Row>
              <Col lg={12} className="mt-tnc-ctn my-xl-5 my-lg-5 my-4">
                <h3 className="tnc-ctn-inner-title">1. Agreement to Terms </h3>
                <p>
                  1.1 These Terms and Conditions constitute a legally binding
                  agreement between you (whether individual or representing an
                  entity) and The Merchant Hesham Ahmed Elsaid Ahmed
                  Abdelfattah, hereinafter referred to as "The Merchant" and/or
                  "eltaajir," concerning your access to and use of the Eltaajir
                  website (<Link to="www.eltaajir.com">www.eltaajir.com</Link>)
                  and its related services (the Site).
                </p>
                <p>
                  1.2 The Site offers online financial and investment education
                  and services. By accessing the Site and/or Services, you
                  affirm that you have read, understood, and agreed to abide by
                  all these Terms and Conditions.
                </p>
                <p>
                  1.3 If you disagree with any part of these Terms and
                  Conditions, you are prohibited from using the Site and
                  Services and must cease use immediately. We recommend printing
                  a copy of these Terms and Conditions for future reference.
                </p>
                <p>
                  1.4 We reserve the right to modify these Terms and Conditions
                  at any time. The updated version will be identified by a
                  revised date and becomes effective as soon as accessible. It
                  is your responsibility to review these Terms and Conditions
                  periodically. Continued use of the Site implies acceptance of
                  such changes.
                </p>
                <p>
                  1.5 The Site may be updated or modified to reflect changes in
                  products, user needs, or business priorities.
                </p>
                <p>
                  1.6 Our services are directed to residents of the Middle East.
                  Information on the Site is not intended for distribution or
                  use where it violates any law or regulation.
                </p>
                <p>
                  1.7 The Site is intended for users aged 18 and above. If under
                  18, you are not allowed to register or use the Services
                  without parental permission.
                </p>
                <p>
                  1.8 Additional policies applying to Site use include our
                  Privacy Notice, outlining terms for processing personal data.
                  By using the Site, you consent to such processing, affirming
                  the accuracy of provided data.
                </p>

                <h3 className="tnc-ctn-inner-title">2. Acceptable Use </h3>
                <p>
                  2.1 Use of the Site is limited to its intended purpose.
                  Commercial use requires specific endorsement or approval from
                  us.
                </p>
                <p>2.2 As a user, you agree not to:</p>
                <div className="policy-ul-wrap">
                  <ul>
                    <li>
                      Systematically retrieve data without written permission.
                    </li>
                    <li>
                      Make unauthorized use of the Site, including collecting
                      user information for unsolicited emails.
                    </li>
                    <li>
                      Use the Site for advertising or selling goods and
                      services.
                    </li>
                    <li>
                      Interfere with security features, circumvent security
                      measures, or trick and defraud.
                    </li>
                    <li>
                      Trick, defraud, or mislead us and other users, especially
                      in any attempt to learn sensitive account information such
                      as user passwords.
                    </li>
                    <li>
                      Make improper use of our support services or submit false
                      reports of abuse or misconduct Engage in any automated use
                      of the system, such as using scripts to send comments or
                      messages, or using any data mining, robots, or similar
                      data gathering and extraction tools.
                    </li>
                    <li>
                      Interfere with, disrupt, or create an undue burden on the
                      Site or the networks and services connected to the Site.
                    </li>
                    <li>
                      Attempt to impersonate another user or person or use the
                      username of another user.
                    </li>
                    <li>Sell or otherwise transfer your profile.</li>
                    <li>
                      Use a buying agent or purchasing agent to make purchases
                      on the Site.
                    </li>
                    <li>
                      Use any information obtained from the Site to harass,
                      abuse, or harm another person.
                    </li>
                    <li>
                      Use the Site or our content as part of any effort to
                      compete with us or to create a revenue- generating
                      endeavor or commercial enterprise.
                    </li>
                    <li>
                      Decipher, decompile, disassemble, or reverse engineer any
                      of the software comprising or in any way making up a part
                      of the Site.
                    </li>
                    <li>
                      Attempt to access any portions of the Site that you are
                      restricted from accessing.
                    </li>
                    <li>
                      Harass, annoy, intimidate, or threaten any of our
                      employees, agents, or other users.
                    </li>
                    <li>
                      Delete the copyright or other proprietary rights notice
                      from any of the content.
                    </li>
                    <li>
                      Copy or adapt the Site’s software, including but not
                      limited to Flash, PHP, HTML, JavaScript, or other code.
                    </li>
                    <li>
                      Upload or transmit (or attempt to upload or to transmit)
                      viruses, Trojan horses, or other material that interferes
                      with any party’s uninterrupted use and enjoyment of the
                      Site, or any material that acts as a passive or active
                      information collection or transmission mechanism.
                    </li>
                    <li>
                      Use, launch, or engage in any automated use of the system,
                      such as using scripts to send comments or messages,
                      robots, scrapers, offline readers, or similar data
                      gathering and extraction tools.
                    </li>
                    <li>
                      Disparage, tarnish, or otherwise harm, in our opinion, us
                      and/or the Site Use the Site in a manner inconsistent with
                      any applicable laws or regulations.
                    </li>
                    <li>
                      Threaten users with negative feedback or offering services
                      solely to give positive feedback to users Misrepresent
                      experience, skills, or information about a User Advertise
                      products or services not intended by us.
                    </li>
                    <li>
                      Falsely imply a relationship with us or another company
                      with whom you do not have a relationship.
                    </li>
                  </ul>
                </div>
                <h3 className="tnc-ctn-inner-title">
                  3. Information you provide to us
                </h3>
                <p>
                  3.1 You represent and warrant that: (a) all registration
                  information you submit will be true, accurate, current, and
                  complete and relate to you and not a third party; (b) you will
                  maintain the accuracy of such information and promptly update
                  such information as necessary; (c) you will keep your password
                  confidential and will be responsible for all use of your
                  password and account; (d) you have the legal capacity and you
                  agree to comply with these Terms and Conditions; and (e) you
                  are not a minor in the jurisdiction in which you reside, or if
                  a minor, you have received parental permission to use the
                  Site.
                </p>
                <p>
                  If you know or suspect that anyone other than you know your
                  user information (such as an identification code or username)
                  and/or password, you must promptly notify us at
                  info@eltaajir.com.
                </p>
                <p>
                  3.2 If you provide any information that is untrue, inaccurate,
                  not current, or incomplete, we may suspend or terminate your
                  account. We may remove or change a username you select if we
                  determine.
                </p>
                <p>that such username is inappropriate.</p>
                <h3 className="tnc-ctn-inner-title">4. Our content</h3>
                <p>
                  4.1 Unless otherwise indicated, the Site and Services
                  including source code, databases, functionality, software,
                  website designs, audio, video, text, photographs, and graphics
                  on the Site (Our Content) are owned or licensed to us and are
                  protected by copyright and trademark laws.
                </p>
                <p>
                  4.2 Except as expressly provided in these Terms and
                  Conditions, no part of the Site, Services or Our Content may
                  be copied, reproduced, aggregated, republished, uploaded,
                  posted, publicly displayed, encoded, translated, transmitted,
                  distributed, sold, licensed, or otherwise exploited for any
                  commercial purpose whatsoever, without our express prior
                  written permission.
                </p>
                <p>
                  4.3 Provided that you are eligible to use the Site, you are
                  granted a limited license to access and use the Site and Our
                  Content and to download or print a copy of any portion of the
                  Content to which you have properly gained access solely for
                  your personal, non-commercial use.
                </p>
                <p>
                  4.4 You shall not (a) try to gain unauthorized access to the
                  Site, or any networks, servers or computer systems connected
                  to the Site; and/or (b) make for any purpose including error
                  correction, any modifications, adaptions, additions or
                  enhancements to the Site or Our Content, including the
                  modification of the paper or digital copies you may have
                  downloaded.
                </p>
                <p>
                  4.5 We shall (a) prepare the Site and Our Content with
                  reasonable skill and care; and (b) use industry standard virus
                  detection software to try to block the uploading of content to
                  the Site that contains viruses.
                </p>
                <p>
                  4.6 The content on the Site is provided for general
                  information only. It is not intended to amount to advice on
                  which you should rely. You must obtain professional or
                  specialist advice before taking, or refraining from taking,
                  any action based on the content on the Site.
                </p>
                <p>
                  4.7 Although we make reasonable efforts to update the
                  information on our site, we make no representations,
                  warranties or guarantees, whether express or implied, that Our
                  Content on the Site is accurate, complete, or up to date.
                </p>
                <h3 className="tnc-ctn-inner-title">
                  5. Link to third party content
                </h3>
                <p>
                  5.1 The Site may contain links to websites or applications
                  operated by third parties. We do not have any influence or
                  control over any such third-party websites or applications or
                  the third-party operator. We are not responsible for and do
                  not endorse any third-party websites or applications or their
                  availability or content.
                </p>
                <p>
                  5.2 We accept no responsibility for adverts contained within
                  the Site. If you agree to purchase goods and/or services from
                  any third party who advertises in the Site, you do so at your
                  own risk. The advertiser, and not us, is responsible for such
                  goods and/or services and if you have any questions or
                  complaints in relation to them, you should contact the
                  advertiser.
                </p>
                <h3 className="tnc-ctn-inner-title">6. Site Management</h3>
                <p>
                  6.1 We reserve the right at our sole discretion, to (1)
                  monitor the Site for breaches of these Terms and Conditions;
                  (2) take appropriate legal action against anyone in breach of
                  applicable laws or these Terms and Conditions; (3) remove from
                  the Site or otherwise disable all files and content that are
                  excessive in size or are in any way a burden to our systems;
                  and (4) otherwise manage the Site in a manner designed to
                  protect our rights and property and to facilitate the proper
                  functioning of the Site and Services.
                </p>
                <p>
                  6.2 We do not guarantee that the Site will be secure or free
                  from bugs or viruses.
                </p>
                <p>
                  6.3 You are responsible for configuring your information
                  technology, computer programs and platform to access the Site
                  and you should use your own virus protection software.
                </p>

                <h3 className="tnc-ctn-inner-title">
                  7. Modifications to and availability of the Site
                </h3>
                <p>
                  7.1 We reserve the right to change, modify, or remove the
                  contents of the Site at any time or for any reason at our sole
                  discretion without notice. We also reserve the right to modify
                  or discontinue all or part of the Services without notice at
                  any time.
                </p>
                <p>
                  7.2 We cannot guarantee the Site and Services will be
                  available at all times. We may experience hardware, software,
                  or other problems or need to perform maintenance related to
                  the Site, resulting in interruptions, delays, or errors. You
                  agree that we have no liability whatsoever for any loss,
                  damage, or inconvenience caused by your inability to access or
                  use the Site or Services during any downtime or discontinuance
                  of the Site or Services. We are not obliged to maintain and
                  support the Site or Services or to supply any corrections,
                  updates, or releases.
                </p>
                <p>
                  7.3 There may be information on the Site that contains
                  typographical errors, inaccuracies, or omissions that may
                  relate to the Services, including descriptions, pricing,
                  availability, and various other information. We reserve the
                  right to correct any errors, inaccuracies, or omissions and to
                  change or update the information at any time, without prior
                  notice.
                </p>
                <h3 className="tnc-ctn-inner-title">
                  8. Disclaimer/Limitation of Liability
                </h3>
                <p>
                  8.1 The Site and Services are provided on an as-is and
                  as-available basis. You agree that your use of the Site and/or
                  Services will be at your sole risk except as expressly set out
                  in these Terms and Conditions. All warranties, terms,
                  conditions, and undertakings, express or implied (including by
                  statute, custom or usage, a course of dealing, or common law)
                  in connection with the Site and Services and your use thereof
                  including, without limitation, the implied warranties of
                  satisfactory quality, fitness for a particular purpose and
                  non-infringement are excluded to the fullest extent permitted
                  by applicable law.
                </p>
                <p>
                  8.2 We make no warranties or representations about the
                  accuracy or completeness of the Site’s content and are not
                  liable for any (1) errors or omissions in content; (2) any
                  unauthorized access to or use of our servers and/or any and
                  all personal information and/or financial information stored
                  on our server; (3) any interruption or cessation of
                  transmission to or from the site or services; and/or (4) any
                  bugs, viruses, trojan horses, or the like which may be
                  transmitted to or through the site by any third party. We will
                  not be responsible for any delay or failure to comply with our
                  obligations under these Terms and Conditions if such delay or
                  failure is caused by an event beyond our reasonable control.
                </p>
                <p>
                  8.3 Our responsibility for loss or damage suffered by you:
                </p>
                <div className="policy-ul-wrap">
                  <h4>Whether you are a consumer or a business user:</h4>
                  <ul>
                    <li>
                      We do not exclude or limit in any way our liability to you
                      where it would be unlawful to do so. This includes
                      liability for death or personal injury caused by our
                      negligence or the negligence of our employees, agents, or
                      subcontractors and for fraud or fraudulent
                      misrepresentation.
                    </li>
                    <li>
                      If we fail to comply with these Terms and Conditions, we
                      will be responsible for loss or damage you suffer that is
                      a foreseeable result of our breach of these Terms and
                      Conditions, but we would not be responsible for any loss
                      or damage that were not foreseeable at the time you
                      started using the Site/Services.
                    </li>
                    <li>
                      Notwithstanding anything to the contrary contained in the
                      Disclaimer/Limitation of Liability section, our liability
                      to you for any cause whatsoever and regardless of the form
                      of the action, will at all times be limited to a total
                      aggregate amount equal to the greater of (a) the sum of $0
                      or (b) the amount paid, if any, by you to us for the
                      Services/Site during the six (6) month period prior to any
                      cause of action arising.
                    </li>
                  </ul>
                </div>
                <div className="policy-ul-wrap">
                  <h4>If you are a business user:</h4>
                  <ul>
                    <li>
                      We will not be liable to you for any loss or damage,
                      whether in contract, tort (including negligence), breach
                      of statutory duty, or otherwise, even if foreseeable,
                      arising under or in connection with:
                    </li>
                    <li>use of, or inability to use, our Site/Services; or</li>
                    <li>
                      use of or reliance on any content displayed on our Site.
                      In particular, we will not be liable for:
                    </li>
                    <li>
                      loss of profits, sales, business, or revenue; business
                      interruption; loss of anticipated savings; loss of
                      business opportunity, goodwill, or reputation; or any
                      indirect or consequential loss or damage.
                    </li>
                  </ul>
                </div>
                <div className="policy-ul-wrap">
                  <h4>If you are a consumer user:</h4>
                  <ul>
                    <li>
                      Please note that we only provide our Site for domestic and
                      private use. You agree not to use our Site for any
                      commercial or business purposes, and we have no liability
                      to you for any loss of profit, loss of business, business
                      interruption, or loss of business opportunity.
                    </li>
                    <li>
                      If defective digital content that we have supplied,
                      damages a device or digital content belonging to you and
                      this is caused by our failure to use reasonable care and
                      skill, we will either repair the damage or pay you
                      compensation.
                    </li>
                    <li>
                      You have legal rights in relation to goods that are faulty
                      or not as described. Advice about your legal rights is
                      available from your local Citizens' Advice Bureau or
                      Trading Standards office. Nothing in these Terms and
                      Conditions will affect these legal rights.
                    </li>
                  </ul>
                </div>
                <h3 className="tnc-ctn-inner-title">9. Term and Termination</h3>
                <p>
                  9.1 These Terms and Conditions shall remain in full force and
                  effect while you use the Site or Services or are otherwise a
                  user of the Site, as applicable. You may terminate your use or
                  participation at any time (following the instructions stated
                  for each package), for any reason, by following the
                  instructions for terminating user accounts in your account
                  settings, if available, or by contacting us at
                  info@eltaajir.com.
                </p>
                <p>
                  9.2 Without limiting any other provision of these Terms and
                  Conditions, we reserve the right to, in our sole discretion
                  and without notice or liability, deny access to and use of the
                  Site and the Services (including blocking certain IP
                  addresses), to any person for any reason including without
                  limitation for breach of any representation, warranty or
                  covenant contained in these Terms and Conditions or of any
                  applicable law or regulation. If we determine, in our sole
                  discretion, that your use of the Site/Services is in breach of
                  these Terms and Conditions or of any applicable law or
                  regulation, we may terminate your use or participation in the
                  Site and the Services or delete your profile and any content
                  or information that you posted at any time, without warning,
                  in our sole discretion.
                </p>
                <p>
                  9.3 If we terminate or suspend your account for any reason set
                  out in this Section 9, you are prohibited from registering and
                  creating a new account under your name, a fake or borrowed
                  name, or the name of any third party, even if you may be
                  acting on behalf of the third party. In addition to
                  terminating or suspending your account, we reserve the right
                  to take appropriate legal action, including without limitation
                  pursuing civil, criminal, and injunctive redress.
                </p>
                <h3 className="tnc-ctn-inner-title">10. General</h3>
                <p>
                  10.1 Visiting the Site, sending us emails, and completing
                  online forms constitute electronic communications. You consent
                  to receive electronic communications and you agree that all
                  agreements, notices, disclosures, and other communications we
                  provide to you electronically, via email and on the Site,
                  satisfy any legal requirement that such communication be in
                  writing.
                </p>
                <p>
                  <strong>
                    You hereby agree to the use of electronic signatures,
                    contracts, orders, and other records and to electronic
                    delivery of notices, policies and records of transactions
                    initiated or completed by us or via the Site.
                  </strong>{" "}
                  You hereby waive any rights or requirements under any
                  statutes, regulations, rules, ordinances, or other laws in any
                  jurisdiction which require an original signature or delivery
                  or retention of non-electronic records, or to payments or the
                  granting of credits by other than electronic means.
                </p>
                <p>
                  10.2 These Terms and Conditions and any policies or operating
                  rules posted by us on the Site or in respect to the Services
                  constitute the entire agreement and understanding between you
                  and us.
                </p>
                <p>
                  10.3 Our failure to exercise or enforce any right or provision
                  of these Terms and Conditions shall not operate as a waiver of
                  such right or provision.
                </p>
                <p>
                  10.4 We may assign any or all our rights and obligations to
                  others at any time.
                </p>
                <p>
                  10.5 We shall not be responsible or liable for any loss,
                  damage, delay, or failure to act caused by any cause beyond
                  our reasonable control.
                </p>
                <p>
                  10.6 If any provision or part of a provision of these Terms
                  and Conditions is unlawful, void, or unenforceable, that
                  provision or part of the provision is deemed severable from
                  these Terms and Conditions and does not affect the validity
                  and enforceability of any remaining provisions.
                </p>
                <p>
                  10.7 There is no joint venture, partnership, employment, or
                  agency relationship created between you and us because of
                  these Terms and Conditions or use of the Site or Services.
                </p>
                <p>
                  10.08 The following are trademarks of Eltaajir / The Merchant.
                  You are not permitted to use them without our approval, unless
                  they are part of material our Site explicitly states you are
                  permitted to use.
                </p>
                <p>
                  10.9 In order to resolve a complaint regarding the Services or
                  to receive further information regarding use of the Services,
                  please contact us by email at{" "}
                  <Link to="mailto:info@eltaajir.com">info@eltaajir.com</Link>
                </p>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
}

export default TermsAndCondition;
