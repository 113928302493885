import React, { useState } from "react";
import TopBanner from "../components/banner/TopBanner";
import {
  Button,
  CloseButton,
  Image,
  Col,
  Container,
  Modal,
  Row,
} from "react-bootstrap";
import Default_video_image from "../assets/images/default_video_image.png";
import Pricing from "../components/pricing/Pricing";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { planIdConfig } from "../config/constants";
import { Helmet } from "react-helmet";

function ProductAndServicePage() {
  const navigate = useNavigate();
  const [pnsDetailsModal, SetPnsDetailsModal] = useState(false);
  const [plan, SetPlan] = useState(null);
  const planPricing = useSelector((state) => state.merchant.planPricing) || [];

  const handleSelectPlan = (event, value) => {
    const token = sessionStorage.getItem("tm-token");
    event.preventDefault();
    if (token) {
      navigate("/payment", {
        state: {
          packageId: value.id,
        },
      });
    } else {
      navigate("/login");
    }
  };

  const handlePandS = (course) => {
    if (course.is_active == 0) return;
    SetPnsDetailsModal(true);
    SetPlan(course);
  };

  return (
    <>
      <Helmet>
        <title>The Merchant - Product And Service</title>
        <link rel="canonical" href="https://eltaajir.com/private-telegram" />
        <meta
          property="og:title"
          content="The Merchant - Product And Service"
        />
      </Helmet>
      <TopBanner title={"Products and Services"} />
      {planPricing.length > 0 && (
        <section className="prod-service-page">
          <Container>
            <div className="prod-service-page-wrap">
              <Row className="justify-content-center pns-card-row">
                {planPricing.map((course, index) => {
                  if (course.type !== 2) {
                    return (
                      <Col xs={12} md={6} lg={4} key={index}>
                        <div className="pns-card">
                          <div
                            className={`pns-card-wrap${
                              course.is_active === 0 ? " disabled" : ""
                            }`}
                          >
                            <div className="card-img ratio ratio-16x9">
                              <Image src={Default_video_image} />
                            </div>
                            <div className="card-body">
                              <div className="card-title">
                                <h3>{course.package_name}</h3>
                              </div>
                              <div
                                className="card-desc"
                                dangerouslySetInnerHTML={{
                                  __html: course.new_description,
                                }}
                              ></div>
                            </div>
                            <div className="card-footer">
                              <Button onClick={() => handlePandS(course)}>
                                Learn More
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Col>
                    );
                  }
                })}
              </Row>

              <Modal show={pnsDetailsModal} centered className="pns-modal">
                <Modal.Header>
                  <Modal.Title>
                    <div className="header-wrap d-flex align-items-center justify-content-between">
                      <div className="card-heading">
                        <h4>{plan?.package_name}</h4>
                      </div>
                    </div>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div
                    className="pns-modal-ctn-inner"
                    dangerouslySetInnerHTML={{
                      __html: plan?.description,
                    }}
                  ></div>
                </Modal.Body>
                <div className="pns-modal-footer">
                  {plan?.is_contact === 0 ? (
                    <>
                      <span className="price">
                        Price: {plan.amount}&euro; {plan?.tag_line}
                      </span>
                      {plan?.id == planIdConfig.coaching ? (
                        <Button className="pns-modal-buy-btn">
                          Contact Now
                        </Button>
                      ) : (
                        <Button
                          className="pns-modal-buy-btn"
                          onClick={(e) => handleSelectPlan(e, plan)}
                        >
                          Buy Now
                        </Button>
                      )}
                    </>
                  ) : (
                    <>
                      <Button className="pns-modal-buy-btn percent">
                        {plan?.tag_line}
                      </Button>
                      <Button className="pns-modal-buy-btn">Contact Now</Button>
                    </>
                  )}
                </div>
                <CloseButton
                  className="close-btn"
                  onClick={() => SetPnsDetailsModal(false)}
                ></CloseButton>
              </Modal>
            </div>
          </Container>
        </section>
      )}
      <Pricing />
    </>
  );
}

export default ProductAndServicePage;
