import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { useMutation } from "@tanstack/react-query";
import { getAllTestimonial } from "../../api/testimonialApi";
import { homeInfo } from "../../reducers/homeSlice";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import LogoSquare from "../../assets/images/logo-square.png";

export default function TestiMonials() {  
  const testimonials = useSelector((state) => state.merchant.testimonial) || [];
  const dispatch = useDispatch();
  const [currentSlide, setCurrentSlide] = useState(0);

  var settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    afterChange: (current) => setCurrentSlide(current),
    responsive: [],
  };

  useEffect(() => {
    if (testimonials.length === 0) {
      testiMonial.mutate();
    }
  }, []);

  const testiMonial = useMutation(getAllTestimonial, {
    onSuccess: (data) => {
      if (data?.data) {
        dispatch(homeInfo({ type: "testimonial", payload: data?.data?.data }));
      }
    },
    onError: (error) => {
      if (error?.response?.data?.errors) {        
        return;
      }
    },
  });

  const shortName = (text) => {
    if (text) {
      const max = 2;
      let new_string = "";
      text = text.split(" ");
      for (let i = 0; i < text.length; i++) {
        if (i + 1 <= max) {
          new_string = new_string + text[i][0];
        } else {
          break;
        }
      }
      new_string = new_string.toUpperCase();
      return new_string;
    }
    return "";
  };

  const randomColor = () => {
    return Math.floor(100000 + Math.random() * 900000);
  };

  return (
    <>
      {testimonials.length > 0 && (
        <section className="testimonial_main">
          <Container>
            <div className="testimonial-wrapper">
              <div className="section-header d-flex">
                <img src={LogoSquare} alt="logo" />
                <h2 className="tsm_header">What Our Client Says</h2>
              </div>
              <div className="slider-wrapper">
                <Slider {...settings} className="tsm_slider_container">
                  {testimonials.map((tsm, idx) => (
                    <div key={idx}>
                      <div className="testimonial">
                        <h3 className="tsm_title">
                          {tsm.title ? tsm.title : ""}
                        </h3>
                        <div
                          className="tsm_review"
                          dangerouslySetInnerHTML={{
                            __html: tsm.review ? tsm.review : "",
                          }}
                        ></div>
                        <div className="overview">
                          <div
                            className="user_nameco"
                            style={{ background: `#${randomColor()}` }}
                          >
                            {shortName(tsm.client_name ? tsm.client_name : "")}
                          </div>
                          <div className="media-body">
                            <h6 className="tsm_name">{tsm.client_name}</h6>
                            <span className="tsm_country">
                              {tsm.country ? `${tsm.country}` : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
                <div className="slide-count">
                  <span className="current-page">{currentSlide + 1} /</span>{" "}
                  <span className="all-page">{testimonials.length}</span>
                </div>
              </div>
            </div>
          </Container>
        </section>
      )}
    </>
  );
}
