import React from "react";

const ChevronRight = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.615 4.115C11.7311 3.99859 11.8691 3.90623 12.0209 3.84322C12.1728 3.7802 12.3356 3.74777 12.5 3.74777C12.6644 3.74777 12.8272 3.7802 12.9791 3.84322C13.131 3.90623 13.2689 3.99859 13.385 4.115L28.385 19.115C28.5014 19.2311 28.5938 19.3691 28.6568 19.5209C28.7198 19.6728 28.7522 19.8356 28.7522 20C28.7522 20.1644 28.7198 20.3272 28.6568 20.4791C28.5938 20.6309 28.5014 20.7689 28.385 20.885L13.385 35.885C13.1503 36.1197 12.8319 36.2516 12.5 36.2516C12.1681 36.2516 11.8497 36.1197 11.615 35.885C11.3803 35.6503 11.2484 35.3319 11.2484 35C11.2484 34.6681 11.3803 34.3497 11.615 34.115L25.7325 20L11.615 5.885C11.4986 5.76889 11.4062 5.63095 11.3432 5.47908C11.2802 5.32722 11.2478 5.16442 11.2478 5C11.2478 4.83558 11.2802 4.67278 11.3432 4.52092C11.4062 4.36905 11.4986 4.23111 11.615 4.115Z"
        fill="black"
      />
    </svg>
  );
};

export default ChevronRight;
